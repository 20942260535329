
import {Injectable, Injector, EventEmitter} from '@angular/core';
import {PreferencesModel} from "./preferences.model";
import {PalettesModel} from "./palettes.model";
import {ColorUtility} from "../utils/color.utility";
import {ColorPickerService} from "../components/color-picker/color-picker.service";
import {ColorHistoryModel} from "./colorhistory.model";
import * as $ from 'jquery';
import {SwatchModel} from "./swatch.model";

declare var SystemPath: any;
// declare var themeManager: any;
declare var jQuery: any;
enum theme {
  dark = 'dark',
  gray = 'gray',
  light = 'light',
}
enum orientation {
  portrait = 'portrait',
  landscape = 'landscape',
}
@Injectable()
export class GlobalModel {

  // public csInterface    = new CSInterface();
  public rb: any;
  // public extensionId    = this.csInterface.getExtensionID();
  // public hostEnvironment  = this.csInterface.getHostEnvironment();
  public preferencesModel   = new PreferencesModel();
  public palettesModel    = new PalettesModel(this, 'zcdp.palettes.json');
  public palettesFileModel    = new PalettesModel(this, 'zcdpfile.palettes.json');
  public zacPalletesModel = [new PalettesModel(this, 'zcdp.palettes0.json'),new PalettesModel(this, 'zcdp.palettes1.json')];
  public palettesBuilderModel    = new PalettesModel(this, 'zcdpPaletteBuilder.palettes.json');
  public colorHistoryModel  = new ColorHistoryModel(this);
  // public zccModel       = new ZccModel();
  public activeNavComponent: string;
  public swatchFileOutputPath: string;
  public appVersion: string = "2.1.1";
  public appName: string = "zenColorConnect";
  public currentTheme: string;
  public isAppJustLaunched: boolean = false
  //shape when saving
  public defaultShape: string;
  public defaultPosition: string;
  public defaultFileType: string;
  public currentOrientation: string;
  public userOrientation: string;
  public colorUtility:ColorUtility;
  // public resources: any   =this.csInterface.initResourceBundle();
  public deletedStack:any = [];
  public selectedSwatchPickerStack:any = [];
  public deletedAllStack:any = [];
  public displaySwatchName:boolean;
  public preserveEmptySwatch: boolean;
  public sampleZoomState: number = 0;
  public get undoDisabled(): boolean {
    return (this.deletedStack.length == 0) ? true : false;
  }
  public get selectedSwatchPickerundoDisabled(): boolean {
    return (this.selectedSwatchPickerStack.length == 0) ? true : false;
  }
  public paletteFileName:any = '';
  public imageFileName:any = '';
  constructor(private injector:Injector) {
    var self = this;
    self.isAppJustLaunched = true // patch just to manage the fliker issue with orientation
    var colorPickerService = injector.get(ColorPickerService);
    self.colorUtility = new ColorUtility(this,colorPickerService);
  }

  emptyPalettesModelSavedDetails(){
    this.palettesModel.selectedPalette.savedReferenceName = '';
    this.palettesModel.selectedPalette.swatchFileName = '';
    this.palettesModel.selectedPalette.techpack = '';
    this.palettesModel.selectedPalette.notes = '';
  }

  scrollHorizontal(){
    let index;
    let scrollleftpos = 465;
    for(var i = 0; i<=this.palettesModel.selectedPalette.swatches.length; i++){
      var swatch = this.palettesModel.selectedPalette.swatches[i];
      if(swatch.selected == true){
        index = i;
        break;
      }
    }
    let devide = index/8;
    jQuery('#selectedpal').scrollLeft(scrollleftpos*Math.floor(devide));
  }
}
