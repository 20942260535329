import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

declare var jQuery: any;

@Component({
  selector: 'menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})


export class MenusComponent implements OnInit {
  menus: any[];
  showBackButton: boolean = false;
  backUrl: string = '';
  title: string[];
  currentPath: string;
  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {
    //console.log('this.router.url', this.router.url)
    if (this.router.getCurrentNavigation().previousNavigation && (this.router.url == '/file-exchange' || this.router.url == '/sample' || this.router.url == '/palette-builder')) {
      this.showBackButton = true;
      this.backUrl = this.router.getCurrentNavigation().previousNavigation.extractedUrl.toString();
    }
    this.title = this.route.snapshot.data.title;

    this.currentPath = this.router.url;

    //   this.menus = [ 
    //     {path:'/swatch-picker', id:"swatch-picker", name: 'ZCC® Swatch Picker', isEnable: true},
    //     {path:'/file-exchange', id:"file-exchange", name: 'File Exchange', isEnable: true},
    //     {path:'/palette-builder', id:"palette-builder", name: 'Palette Builder', isEnable:true},
    //     {path:'/sample', id:"sample",  name: 'ZCC® Color Sampler', isEnable: true},
    //     {path:'/digital-image-manager', id:"digital-image-manager", name: 'Digital Image Manager', isEnable: true},
    //     {path:'hr'},
    //     {path:'/about', id:'about', name: 'About', isEnable: false},
    //     {path:'hr'},
    //     {path:'/shop', id:'shop', name: 'Shop', isEnable: false},
    //     {path:'hr'},
    //     {path:'/my-account', id:'my-account', name: 'My Account', isEnable: false},
    //     {path:'/help', id:'help', name: 'Help', isEnable: false},
    //  ]
    this.menus = [
      { path: '/zac-picker', id: "zac-picker", name: 'ZAC Bucket Picker', isEnable: true },
      { path: '/normalized-zcc-picker', id: "normalized-zcc-picker", name: 'Normalized ZCC® Picker', isEnable: true },
      { path: '#', id: "data-conversion", name: 'Workspace', isEnable: false },
      { path: '/data-conversion', id: "data-conversion", name: 'ZENCOLOR ANALYTICS | BULK DATA CONVERSION', isEnable: true },
      { path: 'hr' },
      { path: '/about', id: 'about', name: 'About', isEnable: false },
      { path: 'hr' },
      { path: '/shop', id: 'shop', name: 'Shop', isEnable: false },
      { path: 'hr' },
      { path: '/my-account', id: 'my-account', name: 'My Account', isEnable: false },
      { path: '/help', id: 'help', name: 'Help', isEnable: false },
      { path: '/logout', id: 'logout', name: 'Logout', isEnable: false },
    ]
  }

  ngOnInit() {
    jQuery('body').click(function (evt) {
      if (!jQuery(evt.target).closest('.nav-humburger img').length) {
        jQuery('#toggleMenu').slideUp();
      }
    });

    jQuery('.nav-humburger img').click(function () {
      jQuery(this).next('ul').slideToggle();
    });
  }
  goBack() {
    // this.location.back()
    window.location.href = this.backUrl
  }
  logout() {
    sessionStorage.removeItem('USER_DETAILS');
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
