import { Injectable } from '@angular/core';
import { User } from './user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private spinner: NgxSpinnerService, private router: Router, public apiService: ApiService) { }

  public login(userInfo: User) {
    //console.log('userInfo', userInfo)
    this.spinner.show();
    let logindone = false;
    if (userInfo.email === 'dangercorp' && userInfo.password === 'qaL32udAU6') {
      logindone = true;
    } else if (userInfo.email === 'dangermanagement' && userInfo.password === 'E4pTmc9VlW') {
      logindone = true;
    } else if (userInfo.email === 'zenColorDecGUEST' && userInfo.password === 'T6KI30ssI3') {
      logindone = true;
    }

    if (logindone === true) {
      let ssData = { "Value": "0", "CustomerId": "4", "CompanyName": "", "ContactName": "", "BusinessEmail": "rp@zencolor.com", "LicenseKey": "YJME-1Sv8-NkWk-DEjb-tbjb-yaJD-aM6V", "Token": "", "TransId": "61803135682", "PackageType": "BASE PACKAGE", "SortOrder": "1, 2, 3, 4", "PackageId": "1, 2, 3, 4", "PackageName": "COLOR PICKER, FILE EXCHANGE, COLOR SAMPLER, COLOR MANAGER" }
      ssData.Token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkRBTkdFUlpDIiwicGFzc3dvcmQiOiJUSDZFSjVNMkVaSFAiLCJpYXQiOjE2NDA2MjMzNzB9.QaLkPEPZHDPBUnCRsldzTIRYZy2hxiV9q8QhJNmg4NY';
      sessionStorage.setItem('USER_DETAILS', JSON.stringify(ssData));
      let sessionData: any;
      sessionData = sessionStorage.getItem('USER_DETAILS');
      if (sessionData) {
        sessionData = JSON.parse(sessionData);
        this.apiService.token = sessionData.Token;
        this.apiService.packageId = sessionData.PackageId;
        this.router.navigateByUrl('/zac-picker');
      }
    } else {
      alert("Invalid credentials.");
      return;
    }
  }

  public isLoggedIn() {
    return sessionStorage.getItem('USER_DETAILS') !== null;

  }

  public logout() {
    sessionStorage.removeItem('USER_DETAILS');
  }
}