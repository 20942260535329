import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import * as csvtojson from 'csvtojson'
import { Dictionary, filter } from 'underscore';
@Component({
	selector: 'batch-data-conversions',
	templateUrl: './batch-data-conversions.html',
	styleUrls: ['./batch-data-conversions.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BatchDataConversionsPage implements OnInit {
	exportFormats: any[]
	hexString: any;
	fileContent: any;
	exportedFileFormat: any = 'spreadsheet';
	selectedImportFormat: any = 'spreadsheet'
	selectedConversionFormat: any = 'zcc';
	file: any;
	rawJson: any;
	exportJson: any = null;
	fileName: any;
	downloadJsonHref: any;
	constructor(private spinner: NgxSpinnerService, public apiService: ApiService, public sanitizer: DomSanitizer) { }

	@ViewChild('fileInput') fileInput: ElementRef;
	fileBrowse: ElementRef;

	ngOnInit() {
		this.generateDownloadJsonUri();
	}

	clearList() {
		this.fileInput.nativeElement.value = ""
		this.fileName = ""
		this.rawJson = ""
		this.fileContent = ""
	}

	csvToJson(csvString) {
		return new Promise((resolve, reject) => {
		  csvtojson().fromString(csvString)
			.then(jsonData => {
			  resolve(jsonData);
			})
		});
	  }

	  removeEmptyRowsAndColumns(csvString) {
		// Split the CSV string into an array of rows
		var rows = csvString.split('\n');
		// Remove empty rows
		rows = rows.filter(function(row) {
			if (row.split(',').join('').length != 0){
				return row
			}
		});
		// Join the rows back into a CSV string
		var filteredCsvString = rows.join('\n')
	
		return filteredCsvString;
	}
	
	onPaste(event: ClipboardEvent) {
		let self = this
		let clipboardData = event.clipboardData;
		let pastedText = clipboardData.getData('text')
		self.csvToJson(pastedText)
				.then(jsonData => {
					self.rawJson = JSON.stringify(jsonData)
				})
				.catch(error => {
				  console.error('Error converting CSV to JSON:', error);
				});
	  }

	fileChanged(e) {
		this.exportJson = null
		if (e.target.files[0].type != 'text/csv' && e.target.files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
			alert('File format is invalid.  Please review the example provided and enter a valid format');
			this.fileBrowse.nativeElement.value = "";
			return;
		}
		this.spinner.show();
		this.fileName = e.target.files[0].name;
		let fileReader = new FileReader();
		let self = this
		fileReader.onload = (e) => {
			if (self.selectedImportFormat == 'csv') {
				self.fileContent = self.removeEmptyRowsAndColumns(fileReader.result);
				self.csvToJson(self.fileContent)
				.then(jsonData => {
					self.rawJson = JSON.stringify(jsonData)
				})
				.catch(error => {
				  console.error('Error converting CSV to JSON:', error);
				});
			} else {
				//const data = self.toArrayBuffer(fileReader.result);
				let result = fileReader.result as ArrayBufferLike
				var data = new Uint8Array(result);
				const workbook = XLSX.read(data, { type: 'array' });
    			const sheetName = workbook.SheetNames[0];
    			const sheet = workbook.Sheets[sheetName];
				const csvdata = XLSX.utils.sheet_to_csv(sheet);
				self.fileContent = self.removeEmptyRowsAndColumns(csvdata)
				self.csvToJson(self.fileContent)
				.then(jsonData => {
					self.rawJson = JSON.stringify(jsonData)
				 
				})
				.catch(error => {
				  console.error('Error converting CSV to JSON:', error);
				});
			}
			this.spinner.hide();
			
		}
		if (self.selectedImportFormat == 'csv') {
			fileReader.readAsText(e.target.files[0]);
		}
		else{
			fileReader.readAsArrayBuffer(e.target.files[0]);
		}
		fileReader.onerror = function(event) {
			 console.error('File could not be read! ' + event);
		  };

	}

	 toArrayBuffer(buffer) {
		const arrayBuffer = new ArrayBuffer(buffer.length);
		const view = new Uint8Array(arrayBuffer);
		for (let i = 0; i < buffer.length; ++i) {
		  view[i] = buffer[i];
		}
		return arrayBuffer;
	  }

	processFile() {
		let isvalidJson = this.isJsonString(this.rawJson)
		if (isvalidJson.isvalid) {
			alert('CSV/XLS is valid.')
			this.exportJson = isvalidJson.jsonString
		}
		else {
			alert('CSV/XLS format is invalid.  Please review the example provided and enter a valid format')
			this.exportJson = null

		}
	}
	public resJsonResponse = {
		"data": [
			{
				"hex": "#1a1b1c"
			},
			{
				"hex": "#2a2b2c"
			},
			{
				"hex": "#3a3b3c"
			}
		]
	}

	generateDownloadJsonUri() {
		var theJSON = JSON.stringify(this.resJsonResponse);
		var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
		this.downloadJsonHref = uri;
	}

	isRGBPresent(obj){
		let present = false
		if ((('r' in obj) || ('R' in obj)) 
			&& (('g' in obj) || ('G' in obj))
			&& (('b' in obj) || ('B' in obj))){
			present = true
		}
		return present

	}
	isLabPresesnt(obj){
		let present = false
		if ((('l' in obj) || ('l' in obj)) 
			&& (('a' in obj) || ('a' in obj))
			&& (('b' in obj) || ('B' in obj))){
			present = true
		}
		return present
	}
	isJsonString(str) {
		try {
			let json = JSON.parse(str);
			if (!('data' in json)){
				json = {'data' : json}
			}
			let lowercaseValidation = ['hex', 'r', 'g', 'b', 'l', 'a', 'b']
			if (('data' in json)) {
				if (Array.isArray(json['data']) && json['data'] !== null) {
					let isHex = true
					//check for hex or rgb or lab any one is present or not
					for (let i = 0; i < json['data'].length; i++) {
						let hexObj = json['data'][i]
						const hexkeyExists = Object.keys(hexObj).some(key => {
							if (lowercaseValidation.includes(key.toLowerCase())) {
								hexObj[key.toLowerCase()] = hexObj[key]
								delete hexObj[key];
							}
							return key.toLowerCase() === 'hex'
						}
						);
						if (!hexkeyExists 
						&& (!this.isRGBPresent(hexObj))
						&& (!this.isLabPresesnt(hexObj))
					) {
							isHex = false
							break
						}
					}
					return {isvalid: isHex, jsonString:JSON.stringify(json)}
				}
				else {
					return {isvalid: false, jsonString:JSON.stringify(json)}
				}
			}
			else {
				return {isvalid: false, jsonString:JSON.stringify(json)}
			}

		} catch (e) {
			return {isvalid: false, jsonString:null};
		}
	}

	selectImportFileFormat(format: any) {
		this.selectedImportFormat = format;
	}
	selectExportFileFormat(format: any) {
		this.exportedFileFormat = format;
	}
	selectConversionFormat(cformat: any) {
		this.selectedConversionFormat = cformat;
	}

	exportBatch() {
		if (this.rawJson == undefined) {
			alert('Enter data in json format');
			return;
		}
		if (this.exportedFileFormat == '') {
			alert('Select output file format');
			return;
		}
		let isJsonStr = this.isJsonString(this.rawJson)
		if (isJsonStr.isvalid) {
			this.exportJson = isJsonStr.jsonString
		}
		else {
			alert('CSV/XLS format is invalid.  Please review the example provided and enter a valid format')
			this.exportJson = null
			return
		}
		this.spinner.show();
		this.apiService.exportBatch({ batchData: this.exportJson, fileType: this.exportedFileFormat, conversionType: this.selectedConversionFormat }).subscribe(
			(res) => {
				this.spinner.hide();
				var fileExt, filePrefix;
				if (this.exportedFileFormat == 'basic' || this.exportedFileFormat == 'extended') {
					fileExt = '.json';
					filePrefix = 'json_export_'
				} else if (this.exportedFileFormat == 'ASE') {
					fileExt = '.ase';
					filePrefix = 'ase_export_'
				} else if (this.exportedFileFormat == 'csv' || this.exportedFileFormat == 'CSVALL') {
					fileExt = '.csv';
					filePrefix = 'csv_export_'
				}
				else if (this.exportedFileFormat == 'spreadsheet') {
					fileExt = '.xlsx';
					filePrefix = 'excel_export_'
				}

				var today = new Date();
				var day = today.getDate() + "";
				var month = (today.getMonth() + 1) + "";
				var year = today.getFullYear() + "";
				var hour = today.getHours() + "";
				var minutes = today.getMinutes() + "";
				var seconds = today.getSeconds() + "";

				day = this.checkZero(day);
				month = this.checkZero(month);
				year = this.checkZero(year);
				hour = this.checkZero(hour);
				minutes = this.checkZero(minutes);
				seconds = this.checkZero(seconds);
				var todayDateTime = month + '-' + day + '-' + year + ' at ' + hour + '.' + minutes + '.' + seconds;

				var outputFile = filePrefix + todayDateTime + fileExt;
				const element = document.createElement('a');
				element.href = URL.createObjectURL(res.image);
				element.download = outputFile;
				document.body.appendChild(element);
				element.click();
			},
			(error) => {
				this.spinner.hide();
				alert(error.error.message)
			});
	}

	checkZero(data) {
		if (data.length == 1) {
			data = "0" + data;
		}
		return data;
	}
	setDefault() {
		this.exportedFileFormat = 'spreadsheet';
	}
}