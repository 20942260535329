
import { Component, OnInit, ViewEncapsulation, Injector, NgZone, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component'
import {ColorPickerService} from '../../components/color-picker/color-picker.service';
import {SwatchModel} from "../../models/swatch.model";
import { Router } from  '@angular/router';

import '../../components/ColorThief';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { errorComponent } from '../../components/error.component';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwatchChangedEvent } from "../../events/swatch.changed.event";

declare var jQuery: any;
declare var ColorThief: any;


interface PreviewArea {
	src: any;
	width: number;
	height: number;
}
interface SwatchColor {
	hex: string;
	hashHex: string;
	zcc: string;
}
interface ColorCounter {
	value: string;
	count: number;
}
interface ColorData {
val: string;
hex: string;
red: number;
green: number;
blue: number;
hue: number;
saturation: number;
lightness: number;
pos: any;
zccIndex: number;
zcc: string;
}
@Component({
		selector: 'page-sample',
	templateUrl: './sample.page.html',
	styleUrls: ['./sample.page.scss'],
	encapsulation: ViewEncapsulation.None
})
export class Sample extends BaseComponent implements OnInit {
	hasImage: boolean
	samplingFormats: any[]
	exportFormats: any[]
	public previewArea: PreviewArea = {src: '', width: 100, height: 100};
	public shrinkMultiplier:number = 1	
	public paletteColors: SwatchColor[] = []
	public zoomImageDefaultWidth:any;
	public zoomImageDefaultHeight:any;
	public imageData: any
	public relevanceImageData: any
	public isImageLoaded: boolean = false
	public initLoad: boolean = false
	public zoomImg: string = '0';
	public zoomValue: string = '1x'
	public dynamicSwatchLength: number = 1;
	public sortMode: string  = 'commonDesc'
	public processedPixels: ColorData[] = []
	public selectionTool: string = "window-box";
	public currDoc: string = ''
	public fileName: string = ''
	public samplingValue: number =1;
	public selectedSampling: any = 'dominant';
	public defaultChecked: boolean = true;
	public cf : any = 1
	public l : any = 1
	public c : any = 1
	showWorkSpace= false

	public compareFile:any = 'SAMPLER_FILE'
	public compareFileName:any = 'SAMPLER_FILE_Name'

	@ViewChild('samplingDefault') samplingDefault; 
	@ViewChild('fileInput') fileInput; 

	public zoomModel: any = 6;
	public sortFuncs = {
		"commonDesc": (a:any,b:any) => { return b.pos.length - a.pos.length } ,
		"saturationDesc": (a:any,b:any) => { return b.saturation - a.saturation },
		"lightnessDesc": (a:any,b:any) => { return b.lightness - a.lightness }
	}
	constructor(private spinner: NgxSpinnerService, public injector: Injector, public _zone: NgZone, public dialogService: DialogService, public apiService: ApiService, private router: Router) {
		super(injector)
	}
	ngOnInit() {
		jQuery(document).on("click","#workspace",function(e:any) {
			e.preventDefault();
				jQuery('#show-workspace').click();
		});
		this.hasImage = false
		this.exportFormats = [
			{
				'formatID': 'fullImage',
				'formatText': 'Full Image',
				'selected':true
			},
			{
				'formatID': 'marquee',
				'formatText': 'Marquee',
				'selected':false
			},
			{
				'formatID': 'sampler',
				'formatText': 'Eyedropper',
				'selected':false
			}
		]
		this.samplingFormats = [
			{
				'formatID': 'dominant',
				'formatText': 'Dominant',
				'selected':true,
				'enabled':true
			},
			{
				'formatID': 'average',
				'formatText': 'Average',
				'selected':false,
				'enabled':false
			},
			{
				'formatID': 'relevance',
				'formatText': 'Relevance',
				'selected':false,
				'enabled':true
			}
		]

		let imageAsDataURL = localStorage.getItem(this.compareFile)
		if (imageAsDataURL != null || imageAsDataURL != undefined) {
			//load image
			this.fileName = localStorage.getItem(this.compareFileName)
			let self = this
			self.previewArea.src = imageAsDataURL
					self.paletteColors.length = 0   // empty the array
				
					
					var img = new Image;
				
					img.onload = function() {
						var response:any; 
						response = self.prepareImage(604,400, img);
						response = JSON.parse(response)
						self.currDoc = response;						
						self.zoomImageDefaultWidth = response.fullWidth;
						self.zoomImageDefaultHeight = response.fullHeight;
						setTimeout(function(){
							self.cb( null, response.shrink, 0, 0)
							}, 100)
					};
				
					img.src = imageAsDataURL;
		}
		this.toolSelect('marquee')
		this.currentZoom = this.gm.sampleZoomState
		let zoomObj = this.zooms[this.currentZoom]
		this.zoomImage(zoomObj.value, zoomObj.display);
	}

	saveCompareImageDataToLocalStorage(){
		let self = this
		localStorage.setItem(self.compareFile, self.previewArea.src)
		localStorage.setItem(self.compareFileName, self.fileName)
}

	clearImage(){
		this.previewArea = {src: '', width: 100, height: 100};
		localStorage.removeItem(this.compareFile)
		localStorage.removeItem(this.compareFileName)
		jQuery('#img').removeAttr("style");
		jQuery('#marqueeImg').removeAttr("style");
		jQuery('#marqueeSelection').removeAttr("style");
		this.currDoc = '';
		this.fileName = '';
		this.fileInput.nativeElement.value = ""
		this.imageData = null

	}

	increment(){
		//case to fix the number of swatches to 1 in case of average and marquee
		if (this.selectedSampling == 'average' && this.selectionTool == 'marquee'){
			return
		}
		if(this.selectionTool != 'sampler') {
			if(this.dynamicSwatchLength == 12){
				return;
			}
			this.dynamicSwatchLength +=1;
		} else {
			if(this.samplingValue==7){
				return;
			}
			this.samplingValue +=2;
			jQuery('#french-hens').val(this.samplingValue+'x'+this.samplingValue)
		}
		
	}
	decrement(){
		//case to fix the number of swatches to 1 in case of average and marquee
		if (this.selectedSampling == 'average' && this.selectionTool == 'marquee'){
			return
		}
		if(this.selectionTool != 'sampler') {
				if(this.dynamicSwatchLength == 1){
					return;
				}
				this.dynamicSwatchLength -=1;
		} else {
			if(this.samplingValue==1){
				return;
			}
			this.samplingValue -=2;
			jQuery('#french-hens').val(this.samplingValue+'x'+this.samplingValue)
			
		}
	}

	onPaste(event){
		var self = this;
		const items = (event.clipboardData || event.originalEvent.clipboardData).items;
		let blob = null;
		for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
      }
		}

    // load image if there is a pasted image
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
				self.previewArea.src = reader.result
				self.paletteColors.length = 0   // empty the array
				self.saveCompareImageDataToLocalStorage()
			
				
				var img = new Image;
			
				img.onload = function() {
					var response:any; 
					response = self.prepareImage(604,400, img);
					response = JSON.parse(response)
					self.currDoc = response;
					
					self.zoomImageDefaultWidth = response.fullWidth;
					self.zoomImageDefaultHeight = response.fullHeight;
					setTimeout(function(){
						self.cb( null, response.shrink, 0, 0)
						}, 100)
				};
			
				img.src = reader.result.toString()	;
      };
      reader.readAsDataURL(blob);
    } else {
			alert('No image found');
		}
	}

		openComparisonFile(filetype){
			jQuery('#'+filetype).click();
	}

		fileChangedEvent(fileInput){
		if (fileInput.target.files.length == 0) {
			return
		}
		this.zoomImage('0', '1x');
		let file,type: any;
		
		file = fileInput.target.files[0];
		type = file.type;
		if((type != "image/png") && (type != "image/jpeg")){
			return;
		}
		// if (this.imageData != undefined) {
		// 	// this.gm.paletteFileName = undefined
		// 	// this.gm.palettesModel.removePalette(this.gm.palettesModel.selectedPalette)
		// }
		// // this.compareFilesToUpload = <Array<File>>fileInput.target.files;
		// const formData: any = new FormData();
		// const files: Array<File> = this.compareFilesToUpload;

		

    // 	for(let i =0; i < files.length; i++){
    //     	formData.append("uploads[]", files[i], files[i]['name']);
		// }
		// this.compareFormData = formData;

		this.fileName = file.name;
		var self = this;
		var reader = new FileReader;
		
		reader.onload = function() { // file is loaded
			self.previewArea.src = reader.result
			self.paletteColors.length = 0   // empty the array
		self.saveCompareImageDataToLocalStorage()
			
			var img = new Image;
		
			img.onload = function() {
				var response:any; 
				//response = self.prepareImage(364,274, img);
				var maxWidth = jQuery('#imageContainer').width();
				var maxHeight = jQuery('#imageContainer').height();
				response = self.prepareImage(maxWidth, maxHeight, img);
				response = JSON.parse(response)
				self.currDoc = response;
				
				self.zoomImageDefaultWidth = response.fullWidth;
				self.zoomImageDefaultHeight = response.fullHeight;
				setTimeout(function(){
					self.cb( null, response.shrink, 0, 0)
				  }, 100)
			};
		
			img.src = reader.result.toString()	; // is the data URL because called with readAsDataURL
		};
		
		reader.readAsDataURL(fileInput.target.files[0]);
	}
	public width: any;
	public height: any;
	cb ( imgPath:String, shrink:number, fullWidth:any, fullHeight:any ): void {
		let self = this;
		self._zone.run(() => {
		  var startTime = Date.now();
	
		  var orig = jQuery('#img')[0],
		  refCanvas = jQuery('.reference')[0]
	
		  var _h = orig.naturalHeight,
			  _w = orig.naturalWidth,
			  x = 0, y = 0, w = _w, h = _h,
			  maxWidth = jQuery('#imageContainer').width(),
	
			  k = w > maxWidth ? maxWidth/w : 1;    // width is the determining factor
			  w = Math.round(w * shrink)
			  h = Math.round(h * shrink)
			  refCanvas.width = w ;
			  refCanvas.height = h;
			self.width = w;
			self.height = h;
		  var mainHeight = jQuery('main#palleteView').innerHeight() + 105;
		  jQuery('.main-palette-container').css('height', 'calc(100% - '+mainHeight +'px)');
		  var context = refCanvas.getContext('2d');
		  context.drawImage(orig, 0, 0, w, h);
	
		  var input = context.getImageData(x, y, w, h);
		  self.imageData = input;
		  // if(self.isImageLoaded == false && self.initLoad == false){
			// self.colorsToPalette( self.processPixels( input.data, h, w ), h, w );
		  // }
		  self.initLoad = false
		  self.zoomImage(self.zoomImg, self.zoomValue)
			self.isImageLoaded = false
		  });
	}
		
	colorsToPalette(colorData: ColorData[], h, w):void {
			let self = this
			self._zone.run(() => {
				let hexString = ""
				for(var i = 0; i<colorData.length; i++)
				{
				var clr =  colorData[i] ? colorData[i] : {hex: '#808080'}; //  use 128 gray for 'empty' spots
					var rgbHex = clr.hex || '#808080';   //  use 128 gray for 'empty' spots
					rgbHex = rgbHex.split('#').join('')
					hexString += rgbHex + ','
				}	
				var table : ColorData[] = []
				var res: ColorData[] = []
				hexString = hexString.substr(0, hexString.length - 1)
				this.spinner.show();
				this.apiService.batchhextoZcc(hexString, self.cf, self.l, self.c).subscribe((data:any)=>{
					this.spinner.hide();
					self.gm.deletedAllStack = []
					let dataObj = data["data"]
					// if (this.dynamicSwatchLength == 1) {
					// 	dataObj.sort(function(a,b){
					// 		return a.deltaE - b.deltaE
					// 	})
					// }
					for(var j = 0 ; j < dataObj.length ; j++){
						let zccObj = dataObj[j]
						var hex = "#" + zccObj.hex
						let id = zccObj.rgb.r+','+zccObj.rgb.g+','+zccObj.rgb.b
						if( !table[id] ){
							table[id] = {val:id, hex:hex, red:zccObj.rgb.r, green:zccObj.rgb.g, blue:zccObj.rgb.b, hue:zccObj.hsl.h, saturation:zccObj.hsl.s, lightness:zccObj.hsl.l, pos:[{x:0,y:0}], zccIndex: parseInt(zccObj.zcc.substring(4)), zcc: zccObj.zcc };
						}
					}
					var res: ColorData[] = []
				
					var k
					for(k in table)
					res.push( table[k] ) 
					var clrs: ColorData[] = self.getColorsForPalette( res );
					self.paletteColors.length = 0
						

					for(var i = 0; i<this.dynamicSwatchLength; i++)
					{
					let clr:any
					clr =  clrs[i] ? clrs[i] : {hex: '#808080'}; //  use 128 gray for 'empty' spots
						let rgbHex = clr.hex.split('#').join('')
						self.paletteColors[i] = { hex: rgbHex, hashHex: '#'+rgbHex, zcc: clr.zcc}
					}
					self.addPaletts();
				},(error) =>{
					this.spinner.hide();
					alert(error.error.message)
				}) 

			})
		   // end zone run
	}

	processNow(){
		if (this.imageData == null || this.imageData == undefined) {
			//show alert message and return
			alert('No image found');
			return
		}
		//avg color
				let self = this
				let val = jQuery('#img')
				var mh = 0
				var mw = 0
				 var input

			if (this.selectionTool == "marquee") {
				var h = self.imageData.height;
	      var w = self.imageData.width;
				mh = jQuery('#marqueeSelection').height();
	     	mw = jQuery('#marqueeSelection').width();
	      if(mh * mw > 0)
	      {
	        var orig = jQuery('#img')[0];
	        var initialX = orig.offsetLeft;
	        //change in mx because of scrollIndicator size has been changed so subtract that offset
	        var mx = parseInt( jQuery('#marqueeSelection').css('left').match(/[0-9]+/)) - initialX;

	        var my = parseInt( jQuery('#marqueeSelection').css('top').match(/[0-9]+/));
	        input = self.getImagePortion(mx, my, mh, mw, h, w); // image section
	     		 }
			}

			switch (this.selectedSampling) {
				case 'dominant':
					if (this.selectionTool == "marquee") {
			      if(mh * mw > 0)
			      {
			        self.colorsToPalette( self.processPixels(input, mh, mw), mh, mw);
	     		 }
					}
					else{
						//case full image
						self.colorsToPalette( self.processPixels( self.imageData.data, self.height, self.width ), self.height, self.width );	
					}
					break;
				case 'average':
				//case marquee
					if (this.selectionTool == "marquee") {
					      if(mh * mw > 0)
					      {
						        self.getAverageColor(input)
			    	 		}
					}
				
				break;
				case 'relevance':
				if (self.selectionTool == 'marquee') {
					if (mh * mw > 0) {
						this.getRelevanceColor(input, mw, mh)
					}
				}
				else{
					
				let	imgEl = val[0];
				var canvas = document.createElement('canvas'),
				context = canvas.getContext && canvas.getContext('2d'),
				data, width, height
				height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
				width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
					context.drawImage(imgEl, 0, 0);
				data = context.getImageData(0, 0, width, height);
				this.getRelevanceColor(data.data, width, height)
				}
				break;
			
				default:
					break;
			}

	}

	getAverageColor(val){
		let self = this
		let rgb = self.getAverageRGB(val)
		var hex = self.rgbToHex(rgb.r, rgb.g, rgb.b).toUpperCase();
		this.spinner.show();
		this.apiService.batchhextoZcc(hex.split('#').join(''), self.cf, self.l, self.c).subscribe((data:any)=>{
			this.spinner.hide();
			self.gm.deletedAllStack = []
			let dataObj = data["data"]
			self.paletteColors.length = 0
			let zccObj = dataObj[0]
			self.paletteColors[0] = { hex: zccObj.hex, hashHex: '#'+zccObj.hex, zcc: zccObj.zcc }
			self.addPalettsWithoutRemovingExistingPalette()
		},(error) =>{
			this.spinner.hide();
			alert(error.error.message)
		})
	}

	getIntColor(x, y, inputImageData, width){
		let self = this
		var start = ((y*width)+x)*4;
		var rgb = {r:inputImageData[start], g:inputImageData[start+1], b:inputImageData[start+2]}
		return rgb
	}

	rgbaToHex(r, g, b) {
		if (r > 255 || g > 255 || b > 255)
				throw "Invalid color component";
		return ((r << 16) | (g << 8) | b).toString(16);
	}

	getRelevanceColor(data, width, height){
		var alpha = 0
		var rgb = {r:247, g:247, b:247}//zcc-4096 lower limit
		var defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
		i = -4,
		length,
		count = 0;
			for(var y=0; y<height; y++){
				for(var x=0; x<width; x++){
					let whiteColor =  this.getIntColor(x,y, data, width)
					if(whiteColor.r >= rgb.r && whiteColor.g >= rgb.g && whiteColor.b >= rgb.b){
						this.setAlphaComponent(x,y,alpha, data, width);
					}
				}
			}

			let procceeseData = this.processRelevanceDataPixels( data, height, width)
			if (procceeseData.length > 0) {
				this.colorsToPalette(procceeseData , height, width);		
			} else{
				this.removeAllPallets();
				this.gm.palettesModel.writeDefaultFile();
			}
		
	}

processRelevanceDataPixels( pixelArray:Array<number>, h: any, w: any ): ColorData[] {
	let self = this
	var table: ColorData[] = [] , i, i0, i1, i2, id, r,g,b,x,y;
	//comment below if you want to go through each pixel
		var colorThief    = new ColorThief();
		var palettes = colorThief.getPalettes(h, w, pixelArray, self.dynamicSwatchLength);
		if (palettes == null){
			palettes = []
		}
		for (var pal = 0; pal < palettes.length; pal++) {
		var element = palettes[pal];
		r=element[0], g=element[1], b=element[2];
		var hex = self.rgbToHex(r, g, b).toUpperCase();
		var hslArray = this.gm.colorUtility.service.rgbToHsl(r,g,b);
		id = r+','+g+','+b
		if( !table[id] ){
			table[id] = {val:id, hex:hex, red:r, green:g, blue:b, hue:hslArray[0], saturation:hslArray[1], lightness:hslArray[2], pos:[{x:x,y:y}], zccIndex: 0, zcc: '' };
		}
		}
	

	var res: ColorData[] = []
	for(i in table)
		res.push( table[i] )          // ??? why copy?
	return res;
	}

setAlphaComponent(x,y, alpha, inputImageData, width){
	var start = ((y*width)+x)*4; 
	inputImageData[start+3] = alpha;


}
	getAverageRGB(imageData) {
		var blockSize = 5, // only visit every 5 pixels
				i = -4,
				length,
				rgb = {r:0,g:0,b:0},
				count = 0;
		
		length = imageData.length;
		
		while ( (i += blockSize * 4) < length ) {
				++count;
				rgb.r += imageData[i];
				rgb.g += imageData[i+1];
				rgb.b += imageData[i+2];
		}
		
		// ~~ used to floor values
		rgb.r = ~~(rgb.r/count);
		rgb.g = ~~(rgb.g/count);
		rgb.b = ~~(rgb.b/count);
		
		return rgb;
	
	}

	getMarqueeData(input:Array<number>){
		let palettes: ColorCounter[] = []
			let data = input
			for (let index = 0; index < data.length; ) {
				var hex:string = this.rgb2hex([data[index], data[index + 1], data[index + 2]]);
				var finalHex = hex
				let isItemExist = false
				for(let i = 0; i < palettes.length; i++){
					let item = palettes[i]
					if (item.value == finalHex) {
						item.count += 1 
						isItemExist = true
						break
					}
				}
				if (isItemExist == false) {
					let item :ColorCounter = {value: finalHex, count: 1}
					palettes.push(item)
				}
				index += 4
			}	

			palettes.sort(function(a,b){
			   return b.count - a.count
			})
			return palettes
	}

	  processPixels ( pixelArray:Array<number>, h: any, w: any ): ColorData[] {
		let self = this
		var table: ColorData[] = [] , i, i0, i1, i2, id, r,g,b,x,y;
		if (self.sortMode == 'commonDesc') {
			//comment colorthief if we go through processing each pixel
			var colorThief    = new ColorThief();
			 var palettes = colorThief.getPalettes(h, w, pixelArray, self.dynamicSwatchLength);

			//uncomment below if you want to go through each pixel processing
			//let palettes = self.getMarqueeData(pixelArray)
			if (palettes == null){
				palettes = []
			}
		  for (var pal = 0; pal < palettes.length; pal++) {
			//comment below if we go through processing each pixel
			 var element = palettes[pal];
			 r=element[0], g=element[1], b=element[2];
			var hex = self.rgbToHex(r, g, b).toUpperCase();
			//uncomment below if we go through processing each pixel
			//var hex = palettes[pal].value;
			//let rgb = ColorPickerService.hexToRgb(hex)
			//r=rgb.r, g=rgb.g, b=rgb.b
			var hslArray = this.gm.colorUtility.service.rgbToHsl(r,g,b);
			id = r+','+g+','+b
			if( !table[id] ){
			  table[id] = {val:id, hex:hex, red:r, green:g, blue:b, hue:hslArray[0], saturation:hslArray[1], lightness:hslArray[2], pos:[{x:x,y:y}], zccIndex: 0, zcc: '' };
			}
		  }
	
		} else{
		  for( y = 1; y < h-1; y++) {
			for ( x = 1; x < w-1; x++){
			  i = (y * w + x) * 4;
			   r=pixelArray[i], g=pixelArray[i+1], b=pixelArray[i+2];
			  var hex = self.rgb2hex( [r,g,b].join(',') ).toUpperCase();
			  r = ColorPickerService.roundChannelValue(r)
			  g = ColorPickerService.roundChannelValue(g)
			  b = ColorPickerService.roundChannelValue(b)
			  var hslArray = this.gm.colorUtility.service.rgbToHsl(r,g,b);
	
			  id = r+','+g+','+b
			  if( !table[id] ){
				table[id] = {val:id, hex:hex, red:r, green:g, blue:b, hue:hslArray[0], saturation:hslArray[1], lightness:hslArray[2], pos:[{x:x,y:y}], zccIndex: 0, zcc: '' };
			  }
			}
		  }
	  }
		// update pixel info
		self.processedPixels.length = 0
		var res: ColorData[] = []
		for(i in table)
		  res.push( table[i] )          // ??? why copy?
		self.processedPixels = res;
		self.processedPixels.sort(self.sortFuncs['commonDesc'])   // baseline sort of most common
		return res;
	
	  }

	  rgbToHex(R:any,G:any,B:any) {
		var hex = this.toHex(R)+this.toHex(G)+this.toHex(B)
		hex = '#' + hex;
		return hex;
	  }
	  toHex(n:any) {
		n = parseInt(n,10);
		if (isNaN(n)) return "00";
		n = Math.max(0,Math.min(n,255));
		return "0123456789ABCDEF".charAt((n-n%16)/16)
			 + "0123456789ABCDEF".charAt(n%16);
	   }

	  rgb2hex( clr:any ): string {
		var clrs = (clr.length==3) ? clr : clr.split(',')
		  , hex = clrs.map( function( clr:any ){
			  var val = Number( clr ).toString(16);
			  return (val.length===1 ? '0'+val : val);
			})
		hex = '#' + hex.join('');
		hex.toUpperCase()
		return hex;
	  }
	
	zoomImage(zm:any, value?) {
	this.currentZoom = parseInt(zm)
	this.zoomImg = zm
	this.zoomValue = value
		if(!this.currDoc){
			return;
		}
		
		let zoomvalue = zm;
		zm = zm * 50;
		this.marqueeDeselect();
		this.zoomModel = value;
		let zoom = Number(zm);
		this.zoomImg = zm;
			// if(zoomvalue == 0) zoom = 0;
		let imageWid = Number(jQuery('#img').width());
		let imageHgt = Number(jQuery('#img').height());
		if(zoom === 0){
			imageWid = this.zoomImageDefaultWidth;
			imageHgt = this.zoomImageDefaultHeight;
		} else {
			let mWidth =  this.zoomImageDefaultWidth + zoom;
			let mHeight =  this.zoomImageDefaultHeight + zoom;
			let  xs = mWidth / this.zoomImageDefaultWidth;
			let ys = mHeight / this.zoomImageDefaultHeight;
			let shrink;
			if (xs > ys) {
			imageWid = mWidth;
			imageHgt = this.zoomImageDefaultHeight*xs;
			} else {
			imageHgt = mHeight;
			imageWid = this.zoomImageDefaultWidth*ys;
			}
		}
		this.width = imageWid;
		this.height = imageHgt;
	
		jQuery('#img').width(imageWid+'px');
		jQuery('#img').height(imageHgt+'px');
	
		jQuery('#canvaszoom').attr('width', imageWid);
		jQuery('#canvaszoom').attr('height', imageHgt);
		var orig = jQuery('#img')[0],
			refCanvas = jQuery('.reference')[0]
		var context = refCanvas.getContext('2d');
		context.clearRect(0, 0, imageWid, imageHgt);
		context.drawImage(orig, 0, 0, imageWid, imageHgt);
		var input = context.getImageData(0, 0, imageWid, imageHgt);
		this.imageData = input;
		jQuery('#marqueeImg').width(imageWid+'px');
		jQuery('#marqueeImg').height(imageHgt+'px');
}

	  getColorsForPalette( colorData:any ): ColorData[] {
			let self = this
		
				// returned colors should have this much zcc distance between them
			let paletteColors: ColorData[] = []   // assumed to be six colors for now
			var returnColorCount = this.dynamicSwatchLength; // colorData.length < 6 ? colorData.length : 6    // can't return more than we that with which we started
			let sampleSet = colorData.slice(0, colorData.length)
			var spread = 10;
			switch(self.sortMode) {
				case 'commonDesc':
				// we already sorted by common in processedPixels
				break;
				case 'saturationDesc':
				case 'lightnessDesc':
				sampleSet.sort(self.sortFuncs[self.sortMode]);
				break;
				case 'random':
				case 'random2':
				// we're taking our sample from the top 50% most common if we have enough.
				var n = sampleSet.length > 6 * spread ? Math.round(sampleSet.length/2) : sampleSet.length
				sampleSet.length = n
				var indices = [];
				sampleSet.forEach( elem => elem.order =  Math.random() )
				sampleSet.sort( (a,b) => b.order - a.order )     // new random order
				break;
			}
		
			//  populate the result set of colors trying to avoid similar colors by checking the spread
			let sampleIndex = 0;
		
			for(var i = 0; i<returnColorCount; i++){
				// check if we beat the spread
				// If we run out of colors, ignore the spread.
				// If we fail to beat the spread, try the next item in the sample set array.
				if ( sampleSet.length - sampleIndex > returnColorCount - i ) {   //  Do we have more samples than we need?  If not, skip the spread check.
				/* *** TODO: if we're going skip the check at some point, should we skip at the beginning?
					Sliding spread based on color count?   */
				let redo = false
				paletteColors.forEach( elem => {
					if ( Math.abs(sampleSet[sampleIndex].zccIndex - elem.zccIndex) < spread ) {
					redo = true;
					}
				})
				if (redo ) {
					i--
					sampleIndex++
					continue         // try again looking at the next entry in the sample set
				}
				} else {
				}
				if (sampleSet[sampleIndex] == undefined) {
				paletteColors.push(paletteColors[paletteColors.length - 1])
				}
				else{
				paletteColors.push(sampleSet[sampleIndex])
				}
				sampleIndex++
			}
			return paletteColors;
	  }

	  addPalettsWithoutRemovingExistingPalette (){
		var self = this;
		if(this.isAddingGoesOverTheLimitSwatches()){
			return;
		}
		//self.removeAllPallets();
		let hashHex;
		if(this.paletteColors.length > 0){
			hashHex = this.paletteColors[0].hashHex;
			let swatch = this.gm.palettesModel.selectedSwatch
			self.gm.colorUtility.generateZCCColors(hashHex,this.paletteColors[0].zcc, swatch);
			swatch.empty = false;
			this.gm.palettesModel.writeDefaultFile();
		}
  }

  tagandSave(){
	this.router.navigateByUrl('/file-exchange');
}
	  addPaletts (){
			var self = this;
			//if dynamivSwatchLength is 1 only add color to the selected swatch
			if (this.dynamicSwatchLength == 1) {
				self.addPalettsWithoutRemovingExistingPalette()
				return;
			}
			if(this.isAddingGoesOverTheLimitSwatches()){
				return;
			}
			// self.removeAllPallets();
			let hashHex;
			for(let i=0; i<this.paletteColors.length; i++){
				if(this.paletteColors[i] && this.paletteColors[i].hashHex){
				hashHex = this.paletteColors[i].hashHex;
				} else {
				hashHex = '#CCCCCC';
				}
			for(let j=0; j<this.gm.palettesModel.selectedPalette.swatches.length;j++){
				let swatch = this.gm.palettesModel.selectedPalette.swatches[j];
				if(swatch.empty === true){
				//self.gm.colorUtility.generateColors(hashHex, swatch);
				self.gm.colorUtility.generateZCCColors(hashHex,this.paletteColors[i].zcc, swatch);
				swatch.empty = false;
				break;
				}
			}
			}
			this.gm.palettesModel.writeDefaultFile();
	  }
	//   removeAllPallets():void {
	// 	this.gm.emptyPalettesModelSavedDetails();
	
	// 	this.gm.palettesModel.removePalette( this.gm.palettesModel.selectedPalette);
	//   }
	  isAddingGoesOverTheLimitSwatches() {
			let self = this;
				var nonEmptySwatched = [];
				for(var item of self.gm.palettesModel.selectedPalette.swatches){
				if (item.empty === false) {
					nonEmptySwatched.push(item);
				}
				}
			if ((nonEmptySwatched.length + self.paletteColors.length) > 64) {
				this.dialogService.addDialog(errorComponent, {
					message:"Swatch Preview is Full. (64 swatch maximum)", question:"OK"})
					.subscribe((message)=>{
					let resp = Number(message)
					if (resp === 0) {
						for(var i=0; i<this.gm.palettesModel.selectedPalette.swatches.length; i++){
						this.gm.palettesModel.selectedPalette.swatches[i] = new SwatchModel();
						}
						this.gm.palettesModel.writeDefaultFile();
					}
					});
				return true;
			}
			return false;
	  }
	
	
	marqueeDeselect():void {
		this.marqueeHide();
		// reset palette to full image?
    }

    marqueeHide():void {
        jQuery("#marqueeSelection").hide();
        // jQuery('#marqueeImg').css( "webkitClipPath", "none");
        jQuery('#marqueeImg').css( "clip-path", "none");
    }
	prepareImage (maxWidth, maxHeight, image) {    
		//     maxWidth = 402,  based on the locked width of the panel currently
		// let tempPath = electron.remote.app.getPath('temp')
		// let imagePath = path.join(tempPath, 'zencolor.scrapfile.jpg')
		// let image = electron.nativeImage.createreaderomPath(curDocName)
		
		let s = {height: image.height, width: image.width};
		let xs = 1
		let ys = 1
		let shrink = 1
		if (maxWidth < s.width) {
		  xs = maxWidth / s.width
		}
		if (maxHeight < s.height) {
		  ys = maxHeight / s.height
		}
		if (xs < ys) {
		  shrink = xs
		  image.width = maxWidth;
		  image.height = s.height*xs;
		  //image = image.resize({'width': maxWidth})
		} else if (ys < 1) {
		  shrink = ys
		  image.height = maxHeight;
		  image.width = s.width*ys;
		  //image = image.resize({'height': maxHeight})
		}
		else{
		  image.width = s.width;
		  image.height = s.height;
		}
	  
		return JSON.stringify(
		  {
			// 'path': imagePath,
			'shrink': shrink,
			time: 1.0,
			fullWidth: image.width,
			fullHeight: image.height
		  })
	  }
	unselectSampler() : void {
		this.selectionTool = "window-box"
		jQuery('#img').unbind('click');
		jQuery('#img').unbind('mousedown' ).css('cursor','default');
		jQuery('#marquee').attr("src", "../../../assets/marquee_icon.svg");
		jQuery('#sampler').attr("src", "../../../assets/Eyedropper-icon-white.png");
	}

	selectSampling(value){
		this.selectedSampling = value;
		if (this.selectedSampling == 'average' && this.selectionTool == 'marquee') {
				this.dynamicSwatchLength = 1
		}
	}
 	public tolerancePack : any = {cf:1, l:2, c:1}

	 toolSelect(mode:string) {
		// this.samplingDefault.nativeElement.checked = true;
		this.selectedSampling = 'dominant';
	var self = this;
	if ( this.selectionTool == "sampler" && mode == "sampler") {
	  this.selectionTool = "window-box"
	  mode = "window-box"
	}
	jQuery('.tool-selected').removeClass('tool-selected');
	jQuery('#'+mode).addClass('tool-selected');
	jQuery('#img').not('.select-mode').addClass('select-mode');
	jQuery('#img').unbind('click');

	switch(mode) {
		case "sampler":
			jQuery('#sampler').attr("src", "../../../assets/Eyedropper-icon-selected.png");
					this.selectionTool = "sampler"
			self.marqueeHide();
			jQuery('#img').unbind('mousedown' );
		   // Eyedropper function
			jQuery('#img').css('cursor','url("/assets/cross_hair.png") 25 25,auto').mousemove(function(e:any) {
				var data = jQuery('.reference')[0].getContext('2d').getImageData(e.offsetX, e.offsetY, 1, 1).data;
				var hex:string = self.rgb2hex([data[0], data[1], data[2]]);
				let hashHex = '#'+hex;
				jQuery('#tooltip-span').css({'background-color':hashHex})
			} );
			jQuery('#img').css('cursor','url("/assets/cross_hair.png") 25 25,auto').click(function(e:any) {
			  var selectedSwatch = self.gm.palettesModel.selectedSwatch
			  if ( !selectedSwatch || selectedSwatch === undefined )
				  return;

				  //check if the selected swatch is selected if not then choose the next empty swatch
				  if (!selectedSwatch.selected){
					selectedSwatch = self.gm.palettesModel.selectedPalette.swatches.find(swatch => {
							return swatch.empty == true
						  })
				  }

				  //take the center value
			  let centerX = e.offsetX
			  let centerY = e.offsetY
				//create the square
				//square height, weigth
				let squareHeight = self.samplingValue
				let squareWidth = self.samplingValue
				//square origin
				let squareX = centerX - (self.samplingValue -1)/2
				let squareY = centerY - (self.samplingValue -1)/2

					var imgdata = jQuery('.reference')[0].getContext('2d').getImageData(squareX, squareY, squareHeight, squareWidth);
					
					if(self.selectedSampling == 'dominant' || self.samplingValue == 1){
						let colorCounter : ColorCounter[] = []
						let data = imgdata.data
						for (let index = 0; index < data.length; ) {
							var hex:string = self.rgb2hex([data[index], data[index + 1], data[index + 2]]);
							var finalHex = hex
							let isItemExist = false
							for(let i = 0; i < colorCounter.length; i++){
								let item = colorCounter[i]
								if (item.value == finalHex) {
										item.count += 1 
										isItemExist = true
										break
								}
							}
							if (isItemExist == false) {
								let item :ColorCounter = {value: finalHex, count: 1}
								colorCounter.push(item)
							}
							index += 4
						}
						colorCounter.sort((a,b) => (a.count > b.count) ? -1 : ((b.count > a.count) ? 1 : 0)); 
						let simpleHex = colorCounter[0].value
						
							//api call

						let hexString = simpleHex.split('#').join('')
						self.spinner.show();
						self.apiService.batchhextoZcc(hexString, self.cf, self.l, self.c).subscribe((data:any)=>{
							self.spinner.hide();
							self.gm.deletedAllStack = []
					       	 let dataObj = data["data"]
					         let zccObj = dataObj[0]
					         var hashHex = "#" + zccObj.hex
							self.gm.colorUtility.generateZCCColors(hashHex,zccObj.zcc, selectedSwatch);
							selectedSwatch.empty = false;
							selectedSwatch.defaultZcc = false;
							selectedSwatch.missingRefTag = true;
							selectedSwatch.refname = '';
							selectedSwatch.refnumber = '';
							self.gm.palettesModel.writeDefaultFile();
						 },(error) =>{
							self.spinner.hide();
							alert(error.error.message)
						})
					} else {
						self.getAverageColor(imgdata.data)
					}
			  
		  } );

			break;
		case "marquee":
			jQuery('#marquee').attr("src", "../../../assets/marquee_icon_selected.png");
					this.selectionTool = "marquee";
		  jQuery('#img').css('cursor','crosshair').mousedown(function (e:any) {
			  var marqueeDrag = false;
			  var orig = jQuery('#img')[0];
			  var initialX = e.offsetX + orig.offsetLeft;
			  var initialW = e.offsetX;
			  var initialH = e.offsetY;
			  var fullWidth = jQuery('#marqueeImg').width();
			  var fullHeight = jQuery('#marqueeImg').height();

			  jQuery('#img').bind("mousemove", function(moveEvent:any) {
				  var w = Math.abs(initialW - moveEvent.offsetX);
				  var h = Math.abs(initialH - moveEvent.offsetY);

				  if(!marqueeDrag && (h+w > 3)) {
					  jQuery("#marqueeSelection")
					  .show()
					  .css({
						  'left': initialX,
						  'top': e.offsetY
					  });
					  marqueeDrag = true;
				  }
				  var topVal = initialH, rightVal, bottomVal, leftVal = initialW;
				  var leftMarqueeValue = initialX;

				  if (moveEvent.offsetX <= initialW && moveEvent.offsetY >= initialH) {
					leftVal = moveEvent.offsetX;
					leftMarqueeValue = moveEvent.offsetX + orig.offsetLeft;
				  } else if (moveEvent.offsetY <= initialH && moveEvent.offsetX >= initialW) {
					  topVal = moveEvent.offsetY;
				  } else if (moveEvent.offsetY < initialH && moveEvent.offsetX < initialW) {
					leftVal = moveEvent.offsetX;
					leftMarqueeValue = moveEvent.offsetX + orig.offsetLeft;
					  topVal = moveEvent.offsetY;
				  }
				  jQuery("#marqueeSelection").css({ 'width': w, 'height': h, 'left': leftMarqueeValue, 'top': topVal });

				  rightVal = fullWidth - leftVal - w;
				  bottomVal = fullHeight - topVal - h;

				  jQuery('#marqueeImg').css( "webkitClipPath", "inset("+ topVal +"px "+rightVal+"px "+ bottomVal +"px "+ leftVal +"px)");
				  jQuery('#marqueeImg').css( "clip-path", "inset("+ topVal +"px "+rightVal+"px "+ bottomVal +"px "+ leftVal +"px)");
			  });
			  jQuery('#img').bind("mouseup", function(upEvent:any) {
				  jQuery('#img').unbind("mousemove mouseup");
				  if(marqueeDrag){
				  //self.getMarqueePalette();
				  }
				  else {
					self.marqueeHide();
				  }
				  marqueeDrag = false;
			  });
		  });
		//   self.gm.scrollHorizontal();
		break;
					case "window-box":
			self.marqueeHide();
			jQuery('#img').unbind('mousedown' ).css('cursor','default');
			this.selectionTool = 'window-box';
		break;
		default:
			self.marqueeHide();
	}
}

	getMarqueePalette():void {
        var self = this;
        var h = self.imageData.height;
        var w = self.imageData.width;
        var mh = jQuery('#marqueeSelection').height();
        var mw = jQuery('#marqueeSelection').width();
        if(mh * mw > 0)
        {

          var orig = jQuery('#img')[0];
          var initialX = orig.offsetLeft;
          //change in mx because of scrollIndicator size has been changed so subtract that offset
          var mx = parseInt( jQuery('#marqueeSelection').css('left').match(/[0-9]+/)) - initialX;

          var my = parseInt( jQuery('#marqueeSelection').css('top').match(/[0-9]+/));
          var input = self.getImagePortion(mx, my, mh, mw, h, w); // image section
          self.colorsToPalette( self.processPixels(input, mh, mw), mh, mw);
        } else {
          self.marqueeDeselect();
        }
	}
	getImagePortion(mx:any, my:any, mh:any, mw:any, h:any, w:AnalyserNode) {
		let self = this
		  var section = [];
		  var arrayLength = self.imageData.data.length;
		  for(var rowIndex = my; rowIndex<(my+mh); rowIndex++)    // [r, g, b, a, ...]
		  {
			  for(var colIndex = mx; colIndex<(mx+mw); colIndex++)
			  {
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4]);
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4 +1]);
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4 +2]);
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4 +3]);
			  }
		  }
		  return section;
	  }

	  showWorkspace(text:any) {
		jQuery('#page-title').html(text);
		this.createScrollScript()
		this.showWorkSpace = !this.showWorkSpace;
	}
	createScrollScript () {
		var s = document.createElement( 'script' );
		s.setAttribute( 'src', '../../../assets/scrollbar.js' );
		s.onload=() =>{
			jQuery('body').find("#zcc-color-examples").customScrollbar();
		};
		document.body.appendChild( s );
	}

	public zooms = [
		{value: '0', display: '1x'},
		{value: '1', display: '2x'},
		{value: '2', display: '3x'},
		{value: '3', display: '4x'},
		{value: '4', display: '5x'},
		{value: '5', display: '6x'},
		{value: '6', display: '7x'},
		{value: '7', display: '8x'},
		{value: '8', display: '9x'},
		{value: '9', display: '10x'},
		{value: '10', display: '11x'},
		{value: '11', display: '12x'},
		{value: '12', display: '13x'},
		{value: '13', display: '14x'},
		{value: '14', display: '15x'},
		{value: '15', display: '16x'}
	]

	public currentZoom = 0

	zoomInImage(){
		if (this.currentZoom < this.zooms.length-1) {
			this.currentZoom += 1
			let zoomObj = this.zooms[this.currentZoom]
			this.zoomImage(zoomObj.value, zoomObj.display);
		}
		this.gm.sampleZoomState = this.currentZoom
		

	}
	zoomOutImage(){

		if (this.currentZoom > 0) {
			this.currentZoom -= 1
			let zoomObj = this.zooms[this.currentZoom]
			this.zoomImage(zoomObj.value, zoomObj.display);
		}
		this.gm.sampleZoomState = this.currentZoom
	}	

	undoDelete() {
		var pallette = this.gm.palettesModel.selectedPalette
		if (this.gm.deletedAllStack.length > 0) {
			//fill the stacks on clear all
			let selectedSwatchIndex = null
			while (this.gm.deletedAllStack.length > 0) {
				let lastDeleted = this.gm.deletedAllStack.pop();
				this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
				if (lastDeleted.data.selected) {
					selectedSwatchIndex = lastDeleted.index
				}
				this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index]);
				this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index];
			  
				var o = new SwatchChangedEvent({
					"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
					"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
					"newSwatch": lastDeleted.data,
					"newPalette": pallette
				});
				this.gm.palettesModel.selectedPalette = pallette;
				this.gm.palettesModel.data.activePaletteId = pallette.id;
			}
			this.gm.palettesModel.writeDefaultFile();
			this.gm.palettesModel.selectedPalette.deselectAllSwatches();
			if (selectedSwatchIndex != null) {
				this.gm.palettesModel.selectedSwatch = this.gm.palettesModel.selectedPalette.swatches[selectedSwatchIndex]
				this.gm.palettesModel.selectedSwatch.selected = true
			}
			
			this.gm.palettesModel.swatchChanged.emit(o);
			this.gm.deletedAllStack = [];
			return
		}
		let lastDeleted = this.gm.deletedStack.pop();
		if(lastDeleted) {
			this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
			this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedSwatch);
			this.gm.palettesModel.writeDefaultFile();
			this.gm.palettesModel.selectedPalette.deselectAllSwatches();
			this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index].selected = true;
			var o = new SwatchChangedEvent({
				"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
				"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
				"newSwatch": lastDeleted.data,
				"newPalette": pallette
			});
			this.gm.palettesModel.selectedSwatch = lastDeleted.data ;
			this.gm.palettesModel.selectedPalette = pallette;
			this.gm.palettesModel.data.activePaletteId = pallette.id;
			this.gm.palettesModel.swatchChanged.emit(o);
		}
	}
	
	removeAllPallets() {
		this.gm.deletedAllStack = [];
		for (let i = 0; i < this.gm.palettesModel.selectedPalette.swatches.length; i++) {
			let swatch = this.gm.palettesModel.selectedPalette.swatches[i];
			if (swatch.empty == false) {
				let deletedSwatch = Object.assign({}, swatch);	
				let deletedArr = {index:i, data:deletedSwatch};
				this.gm.deletedAllStack.push(deletedArr);
			}
		}
		let palette = this.gm.palettesModel.selectedPalette
		this.gm.palettesModel.removePalette( palette );
		this.gm.palettesModel.writeDefaultFile();
		this.gm.deletedStack = [];
	}
	
	deleteSelectedSwatch() {
		let swatch  = this.gm.palettesModel.selectedSwatch
		let palette = this.gm.palettesModel.selectedPalette
		if(swatch.empty) {
			alert("The swatch is already empty.");
			return;
		} else {
			for ( var i = 0; i < palette.swatches.length; i ++ ) {
				if (palette.swatches[i].id === swatch.id) {
					let deletedSwatch = Object.assign({}, palette.swatches[i]);
					let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(x=>x.id==palette.swatches[i].id);
					let deletedArr = {index:index, data:deletedSwatch};
					this.gm.deletedStack.push(deletedArr);
					palette.swatches[i].empty = true;
					palette.swatches[i].zcc = ""
					palette.swatches[i].contrastingFontColor = "white";
					// this.gm.palettesModel.selectedSwatch = new SwatchModel({});
					this.gm.palettesModel.updatePaletteWithoutSelectingSwatch(palette);
					this.gm.palettesModel.selectedSwatch.selected = false
					this.gm.palettesModel.writeDefaultFile();
				}
			}
		}
	}
	
}