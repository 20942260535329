import {OnInit, Injector} from '@angular/core';
import {GlobalModel} from "./models/global.model";

declare var _: any;

export abstract class BaseComponent implements OnInit {
    $el: any;
    gm: GlobalModel;

    constructor(public injector: Injector) {
        this.gm = injector.get(GlobalModel);
    }

    ngOnInit() {
    }
}
