import { Component, Output, EventEmitter, Input, Injector } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../base.component';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent extends BaseComponent {
  @Input()id: number;
  myForm: FormGroup;
  constructor(
    public injector: Injector,
   public activeModal: NgbActiveModal,
   private formBuilder: FormBuilder,
   public confirmationDialogService: ConfirmationDialogService
  ) {
    super(injector);
    this.createForm();
  }
  private createForm() {
    this.myForm = this.formBuilder.group({
      swatchFileName: this.gm.paletteFileName.length > 0 ? 'report_' + this.gm.paletteFileName.substring(0, this.gm.paletteFileName.lastIndexOf('.')) : "",
      techpack: this.gm.palettesFileModel.selectedPalette.techpack,
      notes: this.gm.palettesFileModel.selectedPalette.notes
    });
  }
  public submitForm() {
    if (this.myForm.value.swatchFileName.length == 0 || this.myForm.value.swatchFileName == undefined || this.myForm.value.swatchFileName == null) {
      this.confirmationDialogService.confirm('ALERT!', 'Please enter file name', ' ', 'OK')
			.then((confirmed) =>{
				if(confirmed){
				}
			})	
			.catch(() =>  {
				
      });
      return
    }

    this.activeModal.close(this.myForm.value);
  }
}