import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'common-tabs',
	templateUrl: './tabs.html',
	styleUrls: ['./tabs.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TabsCommon implements OnInit {
	links: any[]
	constructor(
		private router: Router
	) {}
	ngOnInit() {
		this.links = [
			{
				'img':'/assets/color-picker-icon.png',
				'href':'/color-picker',
				'text':'COLOR PICKER'
			},
			{
				'img':'/assets/file-exchange-icon.png',
				'href':'/file-exchange',
				'text':'FILE EXCHANGE'
			},
			{
				'img':'/assets/color-sampler-icon.png',
				'href':'/color-sampler',
				'text':'COLOR SAMPLER'
			},
			// {
			// 	'img':'/assets/color-manager-icon.png',
			// 	'href':'/color-manager',
			// 	'text':'COLOR MANAGER'
			// },
			{
				'img':'/assets/color-manager-icon.png',
				'href':'/digital-image-manager',
				'text':'DIGITAL IMAGE MANAGER'
			},
			{
				'img':'/assets/data-box.png',
				'href':'/data-conversion',
				'text':'DATA CONVERSIONS'
			}
		]
	}
}