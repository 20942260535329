import {Injectable, Component, OnInit, ViewEncapsulation, Injector, Input } from '@angular/core';
import { BaseComponent } from '../../base.component'
import {SwatchChangedEvent} from "../../events/swatch.changed.event";
import {SwatchModel} from "../../models/swatch.model";
import {PaletteModel} from "../../models/palette.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
declare var jQuery: any;


@Injectable({
	providedIn: 'root'
  })
@Component({
	selector: 'common-colors-box',
	templateUrl: './colors-box.html',
	styleUrls: ['./colors-box.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ColorsBoxCommon extends BaseComponent implements OnInit {
	swatchesList: any[] = []
	screenWidth: any
	@Input() palette: PaletteModel;
	@Input() swatch: SwatchModel;
	
	constructor(public injector: Injector) {
		super(injector);
	 }

	ngOnInit() {
		this.screenWidth = window.innerWidth
		this.createScrollScript()
	}

	contrastingFontColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
  }


	missingTagBlack(swatch): any {
		if(swatch.empty || this.gm.palettesModel.selectedPalette.shouldShowMissingTags == false){
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}

	dropped(event: any, swatchIndex: number) : void {
		if (typeof event.dragData.dragIndex != 'undefined') {
		  // move swatch from dragIndex to swatchIndex
		  var element = this.palette.swatches[event.dragData.dragIndex];
		  this.palette.swatches.splice(event.dragData.dragIndex, 1);
		  this.palette.swatches.splice(swatchIndex, 0, element);
		} else {
		  let indexSwatch = this.palette.swatches[swatchIndex];
		  indexSwatch.copy(event.dragData.swatch);
		  if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
			indexSwatch.missingRefTag = true;
		  }
		  else{
			indexSwatch.missingRefTag = false;
		  }
		  this.gm.palettesFileModel.selectedSwatch.selected = false;
		  this.gm.palettesFileModel.selectedSwatch = indexSwatch;
	
		if (this.gm.palettesFileModel.selectedPalette.savedReferenceName.length > 0) {
		  this.gm.palettesFileModel.selectedPalette.enableMerge = true;
	  }
		}
		this.gm.palettesFileModel.selectedPalette.palletSaved = false;
		this.gm.palettesFileModel.writeDefaultFile();
	}
	selectSwatch(event):void {
		if(event.selected == true){
			event.selected = false;
		} else {
			this.gm.palettesModel.selectedPalette.deselectAllSwatches();

			var o = new SwatchChangedEvent({
				"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
				"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
				"newSwatch": event,
				"newPalette": this.palette
			});
			event.selected = true;
			this.gm.palettesModel.selectedSwatch = event;
		// this.gm.colorUtility.generateColors(this.gm.palettesModel.selectedSwatch.rgb, this.gm.palettesModel.selectedSwatch);
			this.gm.palettesModel.selectedPalette = this.palette;
			this.gm.palettesModel.data.activePaletteId = this.palette.id;

			this.gm.palettesModel.swatchChanged.emit(o);
		}
        
	}
	

	deleteSwatch(swatch){
		if(swatch.empty){
			alert("The swatch is already empty.");
			return;
		} else {
		  for ( var i = 0; i < this.palette.swatches.length; i ++ ) {
			if (this.palette.swatches[i].id === swatch.id) {
				let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
				let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(x=>x.id==this.palette.swatches[i].id);
				let deletedArr = {index:index, data:deletedSwatch};
				this.gm.deletedStack.push(deletedArr);
			  this.palette.swatches[i].empty = true;
			  this.palette.swatches[i].zcc = ""
			  this.palette.swatches[i].contrastingFontColor = "white";
			  this.gm.palettesModel.selectedSwatch = new SwatchModel({});
			  this.gm.palettesModel.updatePalette(this.palette);
			  this.gm.palettesModel.writeDefaultFile();
			}
		  }
		}
	}

	  deleteSelectedPalette(){
		if (this.gm.palettesModel.selectedSwatch.empty == false) {
		  let deletedSwatch = Object.assign({}, this.gm.palettesModel.selectedSwatch);
		  let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(x=>x.id==this.gm.palettesModel.selectedSwatch.id);
		  let deletedArr = {index:index, data:deletedSwatch};
		  this.gm.deletedStack.push(deletedArr);
		  this.gm.palettesModel.selectedSwatch.empty = true
		  this.gm.palettesModel.selectedSwatch.refname = ""
		  this.gm.palettesModel.selectedSwatch.refnumber = ""
		  this.gm.palettesModel.selectedSwatch.contrastingFontColor = "white";
		  this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedSwatch);
		  this.gm.palettesModel.writeDefaultFile();
		}
	  }

	  removeAllPallets() {
			this.gm.deletedAllStack = [];
			for (let i = 0; i < this.gm.palettesModel.selectedPalette.swatches.length; i++) {
				let swatch = this.gm.palettesModel.selectedPalette.swatches[i];
				if (swatch.empty == false) {
					let deletedSwatch = Object.assign({}, swatch);	
					let deletedArr = {index:i, data:deletedSwatch};
					this.gm.deletedAllStack.push(deletedArr);
				}
			}
			this.gm.palettesModel.removePalette( this.palette );
			this.gm.palettesModel.writeDefaultFile();
			this.gm.deletedStack = [];
		}
		undoDelete(){

			if (this.gm.deletedAllStack.length > 0) {
				//fill the stacks on clear all
				while (this.gm.deletedAllStack.length > 0) {
					let lastDeleted = this.gm.deletedAllStack.pop();
					this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
					this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index]);
					this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index];
					var o = new SwatchChangedEvent({
						"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
						"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
						"newSwatch": lastDeleted.data,
						"newPalette": this.palette
				});
				this.gm.palettesModel.selectedPalette = this.palette;
				this.gm.palettesModel.data.activePaletteId = this.palette.id;
				}
				this.gm.palettesModel.writeDefaultFile();
				this.gm.palettesModel.selectedPalette.deselectAllSwatches();
				this.gm.palettesModel.swatchChanged.emit(o);
				this.gm.deletedAllStack = [];
				return
			}
			let lastDeleted = this.gm.deletedStack.pop();
			if(lastDeleted){
				this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
				this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedSwatch);
				this.gm.palettesModel.writeDefaultFile();
				this.gm.palettesModel.selectedPalette.deselectAllSwatches();
				this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index].selected = true;
				var o = new SwatchChangedEvent({
					"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
					"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
					"newSwatch": lastDeleted.data,
					"newPalette": this.palette
			});
			this.gm.palettesModel.selectedSwatch = lastDeleted.data ;
			this.gm.palettesModel.selectedPalette = this.palette;
			this.gm.palettesModel.data.activePaletteId = this.palette.id;
	
			this.gm.palettesModel.swatchChanged.emit(o);
			}
		}
		createScrollScript () {
			var s = document.createElement( 'script' );
			s.setAttribute( 'src', '../../../assets/scrollbar.js' );
			s.onload=() =>{
				jQuery('body').find("#common-box2").customScrollbar({fixedThumbHeight: 50, fixedThumbWidth: 60});
			};
			document.body.appendChild( s );
		}
}