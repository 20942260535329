/**
 * Component class to manage functions like:
 * save, share, clear and delete
 */
import {ElementRef, Component, OnInit, Injector,  Input, NgZone, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {BaseComponent} from "../base.component";
import {PaletteModel} from "../models/palette.model";
import {SelectableSwatchComponent} from "./selectable-swatch.component";
import {LocalizationService} from '../services/localization.service';
import { ConfirmComponent } from './confirm.component';
import { PromptComponent } from './prompt.component';
import { ClearPromptComponent } from './clear-prompt.component';
import { DialogService } from "ng2-bootstrap-modal";
import { SaveEvent } from '../events/save.event';
import {Router, ActivatedRoute}      from '@angular/router';
import { alertComponent } from '../components/alert.component';
import { FileExistComponent } from '../components/fileexist.component';
import {SwatchModel} from "../models/swatch.model";
import {SwatchChangedEvent} from "../events/swatch.changed.event";
import * as $ from 'jquery'
declare var _: any;
declare var jQuery: any;


@Component({
    selector: 'selectable-palette-file',
    styles: ['./selectable-palette-file.scss'],
    template: './selectable-palette-file.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectablePaletteFileComponent extends BaseComponent implements OnInit {
  @Input() palette: PaletteModel;
  @Input() isEditable: boolean;
  public router: Router;
  private savedReferenceName: string;
  public localizationService: LocalizationService;
  public get customFieldValue1(): string {
    return this.palette.customFieldValue1;
  }
  public set customFieldValue1(newValue: string) {
    this.palette.customFieldValue1 = newValue;
    this.gm.palettesFileModel.writeDefaultFile();
  }

  public get customFieldValue2(): string {
    return this.palette.customFieldValue2;
  }
  public set customFieldValue2(newValue: string) {
    this.palette.customFieldValue2 = newValue;
    this.gm.palettesFileModel.writeDefaultFile();
  }

  public get customFieldValue3(): string {
    return this.palette.customFieldValue3;
  }
  public set customFieldValue3(newValue: string) {
    this.palette.customFieldValue3 = newValue;
    this.gm.palettesFileModel.writeDefaultFile();
  }
constructor(private cd: ChangeDetectorRef, private dialogService:DialogService, public injector: Injector, el: ElementRef, private _zone: NgZone) {
    super(injector);
    this.localizationService = injector.get(LocalizationService);
    this.$el = jQuery(el.nativeElement);
    this.router = injector.get(Router);
}

refresh(){
  this.cd.detectChanges();
}

themeSelect(value:any):void {
  this.gm.currentTheme = value;
  jQuery('body').attr('theme', this.gm.currentTheme);
  var hostCmd = "setTheme('" + value + "')";
  // this.gm.csInterface.evalScript(hostCmd, function(res) {
  // });
}
orientationSelect(value:any):void {
  jQuery('.theme-box').removeClass('open');
  if(this.gm.activeNavComponent == 'aboutTab'){
    return;
  }
  this.gm.currentOrientation = value;
  this.gm.userOrientation = value;
  jQuery('body').attr('orientation', this.gm.currentOrientation);
  var hostCmd = "setOrientation('" + value + "')";
  // this.gm.csInterface.evalScript(hostCmd, function(res) {
  // });
}
isMergeDisabled(){
  var hostCmd = "isMergeEnabled('" + this.gm.palettesFileModel.selectedPalette.enableMerge + "')";
  // this.gm.csInterface.evalScript(hostCmd, function(res) {
  // });
  return !this.gm.palettesFileModel.selectedPalette.enableMerge
}
  setCustomFieldLabels( one, two, three ):void {
    this.palette.customFieldValue1 = one;
    this.palette.customFieldValue2 = two;
    this.palette.customFieldValue3 = three;

    this.gm.palettesFileModel.writeDefaultFile();
  }
savePaletteRefs(savedMsg, one, two, three ):void {
  // Yeah, it's clunky animation.
  jQuery( savedMsg ).show();
  window.setTimeout(function() {
    jQuery(savedMsg).addClass('fadeOut');
    }, 1000);
  window.setTimeout(function() {
    jQuery(savedMsg).hide();
    jQuery(savedMsg).removeClass('fadeOut');
}, 2000);
  this.setCustomFieldLabels( one, two, three );
}
  promptDelete( selectedpal, removepal ):void {
    // $( selectedpal ).addClass( 'hidden' );
    // $( removepal ).removeClass( 'hidden' );
    // this.gm.palettesFileModel.removePalette(  );
  }

  cancelDelete( selectedpal, removepal ):void {
    $( removepal ).addClass( 'hidden' );
    $( selectedpal ).removeClass( 'hidden' );
  }

  checkForNonSavedPalette(){
     let loadModal=true;
  let swatches = this.gm.palettesFileModel.selectedPalette.swatches;
  for(let i = 0; i<swatches.length; i++){
    if(!swatches[i].empty){
      loadModal = false;
      break;
    }
  }
  if(!loadModal){
    if(!this.gm.palettesFileModel.selectedPalette.palletSaved){
      if (this.gm.palettesFileModel.selectedPalette.savedReferenceName.length > 0 && this.gm.palettesFileModel.selectedPalette.enableMerge == false) {
         return true
      }
      return false;
    }
    else if(this.gm.palettesFileModel.selectedPalette.savedReferenceName.length == 0){
      return false;
    }
  }
  return true;
  }

  deletePalette( pal, selectedpal, removepal ):void {
    let self = this;
    //if(!self.checkForNonSavedPalette()){
      if(1){
      this.dialogService.addDialog(ClearPromptComponent, {
        title:"CLEAR", message:"Do you want to delete this palette permanently?" + "<br/>" + "Select OK to Clear.", question: "OK"})
        .subscribe((message)=>{
          let resp = Number(message);
            if (resp === 0) {
              self._zone.run( () => {
                self.removeAllPallets(pal, selectedpal, removepal );
              })
            }
        });
    }
    else{
      self.removeAllPallets(pal, selectedpal, removepal );
    }
  }

removeAllPallets(pal, selectedpal, removepal ):void {
  this.gm.palettesFileModel.removePalette( this.palette );
  this.gm.palettesFileModel.writeDefaultFile();
  this.gm.palettesFileModel.selectedPalette.palletSaved = true;
  this.gm.palettesFileModel.selectedPalette.enableMerge = false;
  this.gm.palettesFileModel.selectedPalette.savedReferenceName = '';
  this.gm.palettesFileModel.selectedPalette.swatchFileName = '';
  this.gm.palettesFileModel.selectedPalette.techpack = '';
  this.gm.palettesFileModel.selectedPalette.notes = '';
  this.gm.deletedStack = [];
}

  handleTrashDrop($event, swatch): void {
    for ( var i = 0; i < this.palette.swatches.length; i ++ ) {
      if (this.palette.swatches[i].id === $event.dragData.swatch.id) {
        let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
        let deletedArr = {index:i, data:deletedSwatch};
        this.gm.deletedStack.push(deletedArr);
        this.palette.swatches[i].empty = true;
        this.palette.swatches[i].zcc = '';
        this.palette.swatches[i].contrastingFontColor = "white";
        this.palette.swatches[i].refname = ""
        this.palette.swatches[i].refnumber = ""
      }
    }
    this.gm.palettesFileModel.updatePalette(this.palette);
    this.gm.palettesFileModel.writeDefaultFile();
  }
  deleteSelectedPalette(){
    if (this.gm.palettesFileModel.selectedSwatch.empty == false) {
      let deletedSwatch = Object.assign({}, this.gm.palettesFileModel.selectedSwatch);
      let index = this.gm.palettesFileModel.selectedPalette.swatches.findIndex(x=>x.id==this.gm.palettesFileModel.selectedSwatch.id);
      let deletedArr = {index:index, data:deletedSwatch};
      this.gm.deletedStack.push(deletedArr);
      this.gm.palettesFileModel.selectedSwatch.empty = true
      this.gm.palettesFileModel.selectedSwatch.refname = ""
      this.gm.palettesFileModel.selectedSwatch.refnumber = ""
      this.gm.palettesFileModel.selectedSwatch.contrastingFontColor = "white";
      this.gm.palettesFileModel.updatePalette(this.gm.palettesFileModel.selectedSwatch);
      this.gm.palettesFileModel.writeDefaultFile();
    }
  }
  undoDelete(){
    let lastDeleted = this.gm.deletedStack.pop();
    if(lastDeleted){
      this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
      this.gm.palettesFileModel.updatePalette(this.gm.palettesFileModel.selectedSwatch);
      this.gm.palettesFileModel.writeDefaultFile();
      this.gm.palettesFileModel.selectedPalette.deselectAllSwatches();
      this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index].selected = true;
      var o = new SwatchChangedEvent({
        "oldSwatchId": this.gm.palettesFileModel.selectedSwatch.id,
        "oldPaletteId": this.gm.palettesFileModel.selectedPalette.id,
        "newSwatch": lastDeleted.data,
        "newPalette": this.palette
    });
    this.gm.palettesFileModel.selectedSwatch = lastDeleted.data ;
    this.gm.palettesFileModel.selectedPalette = this.palette;
    this.gm.palettesFileModel.data.activePaletteId = this.palette.id;

    this.gm.palettesFileModel.swatchChanged.emit(o);
    }
  }

  replaceSwatch(event: any, swatchIndex: number) : void {
    if (typeof event.dragData.dragIndex != 'undefined') {
      // move swatch from dragIndex to swatchIndex
      var element = this.palette.swatches[event.dragData.dragIndex];
      var swapelement = this.palette.swatches[swatchIndex];
          if (swapelement.empty == true) {
            this.palette.swatches[swatchIndex] = element;
            this.palette.swatches[event.dragData.dragIndex] = swapelement;
          }
          else{
          this.palette.swatches.splice(event.dragData.dragIndex, 1);
          this.palette.swatches.splice(swatchIndex, 0, element);
          }
    }
     else {
      let indexSwatch = this.palette.swatches[swatchIndex];
      indexSwatch.copy(event.dragData.swatch);
      if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
        indexSwatch.missingRefTag = true;
      }
      else{
        indexSwatch.missingRefTag = false;
      }
      this.gm.palettesFileModel.selectedSwatch.selected = false;
      this.gm.palettesFileModel.selectedSwatch = indexSwatch;

      if (this.gm.palettesFileModel.selectedPalette.savedReferenceName.length > 0) {
        this.gm.palettesFileModel.selectedPalette.enableMerge = true;
      }
    }
    this.gm.palettesFileModel.selectedPalette.palletSaved = false;
    this.gm.palettesFileModel.writeDefaultFile();
    jQuery("selectable-palette-file").find('selectable-swatch-file').find('.swatch, .selected').click();
  }

  mergeFile(){
    var self = this
    self.dialogService.addDialog(ClearPromptComponent, {
      title:"MERGE", message:"Combine existing swatch files with new color swatches. Select OK to Merge.", question: "OK"})
      .subscribe((message)=>{
        let resp = Number(message);
          if (resp === 0) {
            self.gm.palettesFileModel.selectedPalette.swatchFileName = '';
            self.gm.palettesFileModel.selectedPalette.savedReferenceName = '';
            self.gm.palettesModel.selectedPalette.savedReferenceName = '';
            self.gm.palettesFileModel.selectedPalette.techpack = ''
            self.gm.palettesFileModel.selectedPalette.notes = ''
            self.router.navigate(['/app/reviewSwatch']);
          }
      });
  }

  shareFile() {
    let self = this;
    if(!self.checkForNonSavedPalette()){
      let currentTabID = self.gm.activeNavComponent;
      let message = '';
      if(this.gm.palettesFileModel.selectedPalette.swatchFileName.length == 0){
        if(currentTabID && currentTabID != 'reviewSwatchTab'){
          message = "Create a Swatch File name to save your work." + "<br/>" + "Select OK to go to Reference Tags."
        }
        else{
          message = "Create a Swatch File name to save your work."
        }
      }
      else{
        message = "You must save your file before you can share it."  + "<br/>" + "You may save it or go back."
      }
      this.dialogService.addDialog(ClearPromptComponent, {
        title:"SHARE", message:message, question: "OK"})
        .subscribe((message)=>{
          let resp = Number(message);
            if (resp === 0) {
              self._zone.run( () => {
                let selectedPalette = this.gm.palettesFileModel.selectedPalette;
                if(currentTabID && currentTabID != 'reviewSwatchTab'){
                  let selectedPalette = this.gm.palettesFileModel.selectedPalette;

                  if(selectedPalette.swatchFileName==''){
                    this.router.navigate(['/app/reviewSwatch']);
                  }
                  else{
                    this.router.navigate(['/app/reviewSwatch']);
                    self.saveFile();
                  }

                }
                else{
                  self.saveFile();
                }
              })
            }
        });
    } else{
      var re = /(?:\.([^.]+))?$/;
      let fileName = self.gm.palettesFileModel.selectedPalette.savedReferenceName;
      let components = re.exec(fileName);
      let extention = components[1];
      if(extention === 'jpg' || extention === 'png'){
        let filePath = this.gm.swatchFileOutputPath+fileName;
        filePath = btoa(filePath)
        var hostCmd = "opentFileForShare('" + filePath + "')";
        // this.gm.csInterface.evalScript(hostCmd, function(res) {
        //   });
      }
    }
  }

  saveFile() {
    var self = this;
    let format, filetype:any, orient:any, fileName:any, techPack:any, notes: any;
    let refValue = [];
    let selectedPalette = this.gm.palettesFileModel.selectedPalette;
    if(selectedPalette.swatchFileName == ''){
      this.dialogService.addDialog(ClearPromptComponent, {
        title:"SAVE", message:"Create a Swatch File name to save your work." + "<br/>" + "Select OK to go to Reference Tags.", question: "OK"})
        .subscribe((message)=>{
          let resp = Number(message);
            if (resp === 0) {
              this.router.navigate(['/app/reviewSwatch']);
            }
        });
      return;
    }
    fileName = selectedPalette.swatchFileName;
    techPack = selectedPalette.techpack;
    notes = selectedPalette.notes;
    let firstSwatch = null;

    for(let j=0; j<60;j++){
      let swatch = this.gm.palettesFileModel.selectedPalette.swatches[j];
      if(swatch.empty === false){
        firstSwatch = swatch;
        break;
      }
    }
    if (firstSwatch === null) {
      this.dialogService.addDialog(alertComponent, {
        title:"Please add some colors in swatch file."})
        .subscribe((message)=>{
        });
      return;
    }
    this.dialogService.addDialog(PromptComponent, {
      title:'Select Format', zccValue:firstSwatch.zcc, zccColor:firstSwatch.rgb, contrastingFontColor:firstSwatch.contrastingFontColor,referenceName:firstSwatch.refname.toUpperCase(), referenceNumber:firstSwatch.defaultZcc == true?"":firstSwatch.refnumber.toUpperCase(), defaultShape: this.gm.defaultShape, defaultPosition: this.gm.defaultPosition, defaultFileType: this.gm.defaultFileType})
      .subscribe((message)=>{
        if(message){
          let resp = [];
          resp = message.split(':');
          let imageType = resp[0];
          let imageFormat = resp[1];
          let zccPosition = resp[2];
            switch(imageType.toUpperCase()) {
              case 'JPG':
                  format = 'graphic';
                  filetype = 'JPG';
                  orient = imageFormat;
                  this.exportFile(format, filetype, fileName, orient, zccPosition, techPack, notes);
                  break;
              case 'PNG':
                  format = 'graphic';
                  filetype = 'PNG';
                  orient = imageFormat;
                  this.exportFile(format, filetype, fileName, orient, zccPosition, techPack, notes);
                  break;

              case 'ACO':
                  format = 'data';
                  filetype = 'ACO';
                  this.exportFile(format, filetype, fileName);
                  break;

              case 'ACT':
                  format = 'data';
                  filetype = 'ACT';
                  this.exportFile(format, filetype, fileName);
                  break;
              case 'ASE':
              format = 'data';
              filetype = 'ASE';
              this.exportFile(format, filetype, fileName);
              break;
              default:
                  //alert('No format found');
                  this.dialogService.addDialog(alertComponent, {
                    title:"No format found."})
                    .subscribe((message)=>{
                      let resp = Number(message);
                    });
            }
          }
      });
  }

  exportFile( format: any, filetype: any, fileName?: any, orient?: any, zccPosition?:any, techPack?:any, notes?:any):void {
    var self = this;
    var sp = self.gm.palettesFileModel.selectedPalette;
    let swatches:any = [];
    var paletteInfo = {
      swatches
    };
    var k = 0;
    for(var i = 0; i<sp.swatches.length; i++)
    {
      if(!sp.swatches[i].empty) {
      var rgbArray = sp.swatches[i].rgbfull.split(',');
      paletteInfo.swatches[k] = {
        zcc: sp.swatches[i].zcc, 
        hex: sp.swatches[i].rgb,
        red: rgbArray[0],
        green: rgbArray[1],
        blue: rgbArray[2],
        empty: sp.swatches[i].empty,
        referenceName: sp.swatches[i].refname.toUpperCase(),
        referenceNumber:  sp.swatches[i].refnumber.toUpperCase(),
        showRefNumber: sp.swatches[i].defaultZcc
      };
      k++
    }
    }
    var currDate = new Date().toLocaleString(undefined, {'year': 'numeric', 'month': 'short', 'day': 'numeric'});
    var time = new Date();
    var currTime = time.getHours()+":"+time.getMinutes()+":"+time.getSeconds()
    var evalArgs = {
      // "extPath": self.gm.csInterface.getSystemPath(SystemPath.EXTENSION),
      "fileType": filetype,
      "notes": notes,
      "techPack": techPack,
      "zccPosition": zccPosition,
      "fileFormat": format,
      "orient":orient,
      "fileName":fileName,
      // "userName": this.authService.licenseeName,
      // "company": this.authService.companyName,
      "colorInfo": paletteInfo,
      "currDate": currDate,
      "currTime": currTime
    }
    var json = JSON.stringify(evalArgs);
    var escapedArgs = json.replace(/\\/g, "\\\\")
    escapedArgs = escapedArgs.replace(/"/g, "\\\"")
    var hostCmd = "exportPalette('" + escapedArgs + "')";
    // self.gm.csInterface.evalScript(hostCmd, function(res:any) {
    //   if(res != 'canceled'){
    //   var infoObj = JSON.parse(res);
    //   let filePath = infoObj.filePath
    //   let pathSeperator = self.pSeparator();
    //    let lastIndex = filePath.lastIndexOf(pathSeperator);
    //   let fileName = filePath.substring(lastIndex + 1, filePath.length);
    //   self.gm.palettesFileModel.selectedPalette.savedReferenceName = fileName;
    //   self.gm.palettesFileModel.selectedPalette.swatchFileName = self.gm.palettesFileModel.selectedPalette.savedReferenceName.split(".")[0];
    //   self.gm.palettesModel.selectedPalette.savedReferenceName = '';
    //   self.gm.palettesFileModel.selectedPalette.techpack = techPack
    //   self.gm.palettesFileModel.selectedPalette.notes = notes
    //   self.gm.palettesFileModel.selectedPalette.palletSaved = true;
    //   self.gm.palettesFileModel.selectedPalette.savePalleteModel();
    //   let o = new SaveEvent({
    //   });
    //   self.gm.palettesFileModel.saveEvent.emit(o);
    // }
    // });
    this.gm.deletedStack = [];
  }
  pSeparator(){
    // var osInfo: string = this.gm.csInterface.getOSInformation();
    // if (osInfo.indexOf("Mac OS X") > -1 || osInfo.indexOf("Linux") > -1) {
    //   return '/';
    // }
    // else{
    //   return '\\';
    // }
  }

}
