import {SwatchModel} from "./swatch.model";
import { UUID } from 'angular2-uuid';
// declare var _: any;
import * as _ from 'underscore';

/**
//  * NOTE: we are using _.deepExtend, so if we add new properties to our models, for later versions, when the older version file loads,
 * properties will be merged with the new ones, and it should make backward compatibility a non issue. Just add new properties to the models
 */
export class PaletteModel {
    public id: string = "";
    //need to remove paletteRef1 to 4
    public paletteRef1: string = "";
    public paletteRef2: string = "";
    public paletteRef3: string = "";
    public paletteRef4: string = "";
    //end
    public swatchFileName: string = "";
    public techpack: string = "";
    public notes: string = "";
    public customFieldValue1: string = "";    // Vendor
    public customFieldValue2: string = "";    // Style
    public customFieldValue3: string = "";    // Item
    public activeSwatchId: string = "";
    public swatches: SwatchModel[] = [];
    public palletSaved = true;
    public isNewPalleteAdded = false;
    public shouldShowMissingTags = false;
    public enableMerge = false;
    public swatchCustomFieldLabel1: string = "";
    public swatchCustomFieldLabel2: string = "";
    public swatchCustomFieldLabel3: string = "";
    public swatchCustomFieldLabel4: string = "";
    public swatchCustomFieldLabel5: string = "";
    public savedReferenceName: string = "";
    public defaultZcc: boolean = true;

    get filledSwatch(){
        let filledSwatch = 0;
        let swatches = this.swatches;
        for(let i =0; i<swatches.length; i++){
            if(!swatches[i].empty){
                filledSwatch++;
            }
        }
        return filledSwatch;
    }

    get refName(){
        let refName = [];
        refName.push(this.paletteRef1)
        if(this.paletteRef2) {
            refName.push(this.paletteRef2)
        }
        if(this.paletteRef3) {
            refName.push(this.paletteRef3)
        }
        if(this.paletteRef4) {
            refName.push(this.paletteRef4)
        }
        if(refName[1]){
            // return refName.join('_');
        } else {
            return refName;
        }
    }

    deselectAllSwatches() {
        for( var i = 0; i < this.swatches.length; i++ ) {
            var swatch = this.swatches[i];

            swatch.selected = false;
        }
    }
    savePalleteModel(){
      this.enableMerge = false;
    }

    constructor(o:any=null) {
        if( o ) {
            // _.extend(this, o);
            if( this.id === "" ) {
                this.id = UUID.UUID();
            }

            for( var i = 0; i < 64; i++ ){
                if( this.swatches[i] !== undefined ) {
                    this.swatches[i] = new SwatchModel(this.swatches[i]);
                } else {
                    this.swatches.push(new SwatchModel({}));
                }
            }
        }
    }
}
