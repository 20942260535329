import {Injectable, Component, OnInit, ViewEncapsulation, Injector, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { BaseComponent } from '../../base.component'
import {SwatchChangedEvent} from "../../events/swatch.changed.event";
import {SwatchModel} from "../../models/swatch.model";
import {PalettesModel} from "../../models/palettes.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
declare var jQuery: any;

@Injectable({
	providedIn: 'root'
  })
@Component({
	selector: 'common-semi-workspace',
	templateUrl: './semi-workspace.html',
	styleUrls: ['./semi-workspace.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SemiWorkspace extends BaseComponent implements OnInit {
	swatchesList: any[] = []
	@Input() palettes: PalettesModel;
	@Input() swatch: SwatchModel;
	numbers: any = []
	constructor(public injector: Injector) {
		super(injector);
		this.numbers = Array(24).fill(0).map((x,i)=>i);
	 }

	ngOnInit() {
		// var widthUL = jQuery('ul.workspacecolors').outerWidth();
		// var boxH = jQuery(widthUL / 16);
		// jQuery('ul.workspacecolors').css('height',boxH[0]+'px');
	}
	@HostListener('window:resize', ['$event'])
	onResize(event){
		// var widthUL = jQuery('ul.workspacecolors').outerWidth();
		// var boxH = jQuery(widthUL / 16);
		// jQuery('ul.workspacecolors').css('height',boxH[0]+'px');
	}

	contrastingFontColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
  }
  dropped(event: any, swatchIndex: number) : void {
	if (typeof event.dragData.dragIndex != 'undefined') {
	  // move swatch from dragIndex to swatchIndex
	  var element = this.palettes.selectedPalette.swatches[event.dragData.dragIndex];
	  this.palettes.selectedPalette.swatches.splice(event.dragData.dragIndex, 1);
	  this.palettes.selectedPalette.swatches.splice(swatchIndex, 0, element);
	} else {
	  let indexSwatch = this.palettes.selectedPalette.swatches[swatchIndex];
	  indexSwatch.copy(event.dragData.swatch);
	  if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
		indexSwatch.missingRefTag = true;
	  }
	  else{
		indexSwatch.missingRefTag = false;
	  }
	  this.gm.palettesBuilderModel.selectedSwatch.selected = false;
	  this.gm.palettesBuilderModel.selectedSwatch = indexSwatch;

	if (this.gm.palettesBuilderModel.selectedPalette.savedReferenceName.length > 0) {
	  this.gm.palettesBuilderModel.selectedPalette.enableMerge = true;
  }
	}
	this.gm.palettesBuilderModel.selectedPalette.palletSaved = false;
	this.gm.palettesBuilderModel.writeDefaultFile();
}

	missingTagBlack(swatch): any {
		if(swatch.empty || this.gm.palettesBuilderModel.selectedPalette.shouldShowMissingTags == false){
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}

	selectSwatch(event):void {
		if(event.selected == true){
			event.selected = false;
		} else {
			this.gm.palettesBuilderModel.selectedPalette.deselectAllSwatches();

			var o = new SwatchChangedEvent({
				"oldSwatchId": this.gm.palettesBuilderModel.selectedSwatch.id,
				"oldPaletteId": this.gm.palettesBuilderModel.selectedPalette.id,
				"newSwatch": event,
				"newPalette": this.palettes.selectedPalette
			});
			event.selected = true;
			this.gm.palettesBuilderModel.selectedSwatch = event;
		// this.gm.colorUtility.generateColors(this.gm.palettesModel.selectedSwatch.rgb, this.gm.palettesModel.selectedSwatch);
			this.gm.palettesBuilderModel.selectedPalette = this.palettes.selectedPalette;
			this.gm.palettesBuilderModel.data.activePaletteId = this.palettes.selectedPalette.id;
			this.gm.palettesBuilderModel.swatchChanged.emit(o);
		}
        
	}
	

	deleteSwatch(swatch){
		if(swatch.empty){
			alert("The swatch is already empty.");
			return;
		} else {
		  for ( var i = 0; i < this.palettes.selectedPalette.swatches.length; i ++ ) {
			if (this.palettes.selectedPalette.swatches[i].id === swatch.id) {
				let deletedSwatch = Object.assign({}, this.palettes.selectedPalette.swatches[i]);
				let index = this.gm.palettesBuilderModel.selectedPalette.swatches.findIndex(x=>x.id==this.palettes.selectedPalette.swatches[i].id);
				let deletedArr = {index:index, data:deletedSwatch};
				// this.gm.deletedStack.push(deletedArr);
			  this.palettes.selectedPalette.swatches[i].empty = true;
			  this.palettes.selectedPalette.swatches[i].zcc = ""
			  this.palettes.selectedPalette.swatches[i].contrastingFontColor = "white";
			  this.gm.palettesBuilderModel.selectedSwatch = new SwatchModel({});
			  this.gm.palettesBuilderModel.updatePalette(this.palettes.selectedPalette);
			  this.gm.palettesBuilderModel.writeDefaultFile();
			}
		  }
		}
	}

	  deleteSelectedPalette(){
		if (this.gm.palettesBuilderModel.selectedSwatch.empty == false) {
		  let deletedSwatch = Object.assign({}, this.gm.palettesBuilderModel.selectedSwatch);
		  let index = this.gm.palettesBuilderModel.selectedPalette.swatches.findIndex(x=>x.id==this.gm.palettesModel.selectedSwatch.id);
		  let deletedArr = {index:index, data:deletedSwatch};
		//   this.gm.deletedStack.push(deletedArr);
		  this.gm.palettesBuilderModel.selectedSwatch.empty = true
		  this.gm.palettesBuilderModel.selectedSwatch.refname = ""
		  this.gm.palettesBuilderModel.selectedSwatch.refnumber = ""
		  this.gm.palettesBuilderModel.selectedSwatch.contrastingFontColor = "white";
		  this.gm.palettesBuilderModel.updatePalette(this.gm.palettesBuilderModel.selectedSwatch);
		  this.gm.palettesBuilderModel.writeDefaultFile();
		}
	  }

	  removeAllPallets() {
			// this.gm.deletedAllStack = [];
			// for (let i = 0; i < this.gm.palettesBuilderModel.selectedPalette.swatches.length; i++) {
			// 	let swatch = this.gm.palettesBuilderModel.selectedPalette.swatches[i];
			// 	if (swatch.empty == false) {
			// 		let deletedSwatch = Object.assign({}, swatch);	
			// 		let deletedArr = {index:i, data:deletedSwatch};
			// 		this.gm.deletedAllStack.push(deletedArr);
			// 	}
			// }
			this.gm.palettesBuilderModel.removePalette( this.palettes.selectedPalette );
			this.gm.palettesBuilderModel.writeDefaultFile();
			this.gm.deletedStack = [];
		}
		// undoDelete(){

		// 	if (this.gm.deletedAllStack.length > 0) {
		// 		//fill the stacks on clear all
		// 		while (this.gm.deletedAllStack.length > 0) {
		// 			let lastDeleted = this.gm.deletedAllStack.pop();
		// 			this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
		// 			this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index]);
		// 			this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index];
		// 			var o = new SwatchChangedEvent({
		// 				"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
		// 				"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
		// 				"newSwatch": lastDeleted.data,
		// 				"newPalette": this.palette
		// 		});
				
		// 		this.gm.palettesModel.selectedPalette = this.palette;
		// 		this.gm.palettesModel.data.activePaletteId = this.palette.id;
		// 		}
		// 		this.gm.palettesModel.writeDefaultFile();
		// 		this.gm.palettesModel.selectedPalette.deselectAllSwatches();
		// 		this.gm.palettesModel.swatchChanged.emit(o);
		// 		this.gm.deletedAllStack = [];
		// 		return
		// 	}
		// 	let lastDeleted = this.gm.deletedStack.pop();
		// 	if(lastDeleted){
		// 		this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
		// 		this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedSwatch);
		// 		this.gm.palettesModel.writeDefaultFile();
		// 		this.gm.palettesModel.selectedPalette.deselectAllSwatches();
		// 		this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index].selected = true;
		// 		var o = new SwatchChangedEvent({
		// 			"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
		// 			"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
		// 			"newSwatch": lastDeleted.data,
		// 			"newPalette": this.palette
		// 	});
		// 	this.gm.palettesModel.selectedSwatch = lastDeleted.data ;
		// 	this.gm.palettesModel.selectedPalette = this.palette;
		// 	this.gm.palettesModel.data.activePaletteId = this.palette.id;
	
		// 	this.gm.palettesModel.swatchChanged.emit(o);
		// 	}
		// }

}