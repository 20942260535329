import { EventEmitter, ChangeDetectionStrategy, ElementRef,
    Component, OnChanges, OnInit, Output, Injector, ViewEncapsulation,
    ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { colorGradient } from './colorPicker2.utils';

let H_INSET = 11;
let V_INSET = 15;

@Component({
  selector: 'colorPicker2-hue-picker',
  styles: ['./huePickerCanvas.component.scss'],
  templateUrl: './huePickerCanvas.component.html'
})
export class HuePickerCanvas extends BaseComponent implements OnChanges, OnInit {
// export default class HuePickerCanvas implements OnChanges, OnInit {
  @ViewChild('huePickerCanvas') huePickerCanvasRef: ElementRef;
  @Output() hueUpdated = new EventEmitter();

  @Input()
  set hue(hue: number) {
    if (Math.abs(hue - this._hue) < 0.001) {
      return;
    }
    if (hue >= 0 && hue <= 1) {
      this._hue = hue;
    } else {
      this._hue = 0;
    }
    this.hueUpdated.emit(hue);
  }
  get hue(): number { return this._hue; }

  public _hue: number;
  public _isTracking = false;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.renderHueCanvas();
  }

  ngOnChanges(changes: any) {
    this.renderHueCanvas();
  }

  public renderHueCanvas() {
    let element = this.huePickerCanvasRef.nativeElement;
    let ctx = element.getContext('2d');
    let width = element.width;
    let height = element.height;
    let gradient = ctx.createLinearGradient(0, 0, 0, height);

    // Create color gradient
    // colorGradient.forEach((color: number[]) => {
    //   gradient.addColorStop(color[0], `rgb(${color[1]}, ${color[2]}, ${color[3]})`);
    // });


    gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');
    gradient.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    gradient.addColorStop(0.34, 'rgba(0, 255, 0, 1)');
    gradient.addColorStop(0.51, 'rgba(0, 255, 255, 1)');
    gradient.addColorStop(0.68, 'rgba(0, 0, 255, 1)');
    gradient.addColorStop(0.85, 'rgba(255, 0, 255, 1)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');
    ctx.fillStyle = gradient;
    ctx.fillRect(H_INSET, V_INSET, width - H_INSET, height - V_INSET);
    
    ctx.fillRect(0, 0, width, V_INSET);
    ctx.fillRect(0, height - V_INSET, width, V_INSET);
    ctx.fillRect(0, 0, H_INSET, height);
    ctx.fillRect(width - H_INSET, 0, H_INSET, height);

    ctx.fillStyle = '#ffffff';
    let y = Math.floor((height + 10 - V_INSET - V_INSET ) * this.hue);// + V_INSET;
    
    if(y>250)
      y=y+20
    
    ctx.beginPath();
      ctx.moveTo(0, y - 5);
      ctx.lineTo(H_INSET, y);
      ctx.lineTo(0, y + 5);
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
      ctx.moveTo(width, y - 5);
      ctx.lineTo(width - H_INSET, y);
      ctx.lineTo(width, y + 5);
    ctx.closePath();
    ctx.fill();
  }

  public onHueMouseDown(event: MouseEvent): void {
    this._isTracking = true;
    this.onHueMouseMove(event);
  }

  public onHueMouseUp(event: MouseEvent): void {
    this._isTracking = false;
    this.onHueMouseMove(event);
  }

  public onHueMouseMove(event: MouseEvent): void {
    if ((event.buttons === 0) || (this._isTracking === false)) {
      this._isTracking = false;
      return;
    }
    let y = event.clientY;
    let rect = this.huePickerCanvasRef.nativeElement.getBoundingClientRect();
    y -= rect.top + V_INSET;
    let height = rect.height - V_INSET - V_INSET;
    if (y < 0) {
      y = 0;
    } else if (y > height) {
      y = height;
    }
    let newHue = y / height;
    this.hue = newHue;
    this.renderHueCanvas();
  }
}
