import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';  // Import it up here
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURl = '';
  hextozcc = 'hextozcc/'
  zcctohex = 'zcctohex/'
  batchhextozcc = 'batchhextozcc'
  hueHarmonyVariation = 'generateHueVariation'
  splitTonalVariation = 'generateSplitTonalVariation'
  saturationVariation = 'generateSaturationVariations'
  batchhextocomparezcc = 'batchhextocomparezcc'
  loginUrl = 'login'
  zccsatchesforLlyer = 'zccSwatchesForLayer'
  zacsatchesforLlyer = 'zacSwatchesForLayer'
  zacBucket = 'bucketsForZAC'
  deltaEBetweenHex = 'findDeltaEBetweenHex'
  deltaEBetweenZac = 'findDeltaEBetweenTwoZac'
  fileExportUrl = 'fileexport'
  batchExportUrl = 'batchconversions'
  stringExportUrl = 'stringconversions'
  batchFileExportUrl = 'batchfileconversions'
  uploadFileUrl = 'uploadimage'
  uploadCompareFileUrl = 'uploadcompareimage'
  uploadCompareFileUrlBase = 'uploadcompareimagebase'
  createManagerReportURL = 'createmanagerreport'
  rgbtodeltaeURL = 'rgbtodeltae'
  rgbtohexURL = 'rgbtohex'
  token: any;
  packageId: any;
  constructor(private http: HttpClient) {
    this.apiURl = environment.apiURl;
    let sessionData: any;
    sessionData = sessionStorage.getItem('USER_DETAILS');
    if (sessionData) {
      sessionData = JSON.parse(sessionData);
      this.token = sessionData.Token;
      this.packageId = sessionData.PackageId;
    }

  }

  login(username, password) {
    const httpBody = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.http.post(this.apiURl + this.loginUrl, httpBody);
  }
  hexToZcc(hex) {
    let getUrl = this.apiURl + this.hextozcc + hex;
    return this.http.get(getUrl, this.getTokenHeader())
  }

  hexToZccWithCF(hex, cf, l, c) {
    const httpBody = new HttpParams()
      .set('hexString', hex)
      .set('cf', cf)
      .set('l', l)
      .set('c', c);
    let getUrl = this.apiURl + this.hextozcc;
    return this.http.post(getUrl, httpBody, this.getTokenHeader());

  }
  batchhextoZcc(hex, cf, l, c) {
    const httpBody = new HttpParams()
      .set('hexString', hex)
      .set('cf', cf)
      .set('l', l)
      .set('c', c);
    return this.http.post(this.apiURl + this.batchhextozcc, httpBody, this.getTokenHeader());
  }


  generateSaturationVariations(length, changeHue, low, initalHex) {
    const httpBody = new HttpParams()
      .set('length', length)
      .set('changeHue', changeHue)
      .set('low', low)
      .set('initialHex', initalHex)

    return this.http.post(this.apiURl + this.saturationVariation, httpBody, this.getTokenHeader());
  }
  generateSplitTonalVariation(length, initalHex) {
    const httpBody = new HttpParams()
      .set('length', length)
      .set('initialHex', initalHex)

    return this.http.post(this.apiURl + this.splitTonalVariation, httpBody, this.getTokenHeader());
  }

  generateHueVariation(hueAngle, initialHex) {
    const httpBody = new HttpParams()
      .set('hueAngles', hueAngle)
      .set('initialHex', initialHex)

    return this.http.post(this.apiURl + this.hueHarmonyVariation, httpBody, this.getTokenHeader());
  }

  batchhextoCompareZcc(hex, ssHex, cf, l, c) {
    const httpBody = new HttpParams()
      .set('hexString', hex)
      .set('selectedSwatchHex', ssHex)
      .set('cf', cf)
      .set('l', l)
      .set('c', c);
    return this.http.post(this.apiURl + this.batchhextocomparezcc, httpBody, this.getTokenHeader());
  }
  zccSwatchesForLayer(layer) {
    const httpBody = new HttpParams()
      .set('layerName', layer);
    return this.http.post(this.apiURl + this.zccsatchesforLlyer, httpBody, this.getTokenHeader());
  }

  zacSwatchesForLayer(layer, zacType = '17') {
    const httpBody = new HttpParams()
      .set('layerName', layer)
      .set('zacType', zacType);
    return this.http.post(this.apiURl + this.zacsatchesforLlyer, httpBody, this.getTokenHeader());
  }

  bucketsForZAC(zac, isDeltaE, zacType = '17', { zcc, hex, rgb }) {
    let bodyParam = { zac, isDeltaE, zacType, zcc, hex, rgb };
    return this.http.post(this.apiURl + this.zacBucket, bodyParam, this.getTokenHeader());
  }

  zccToHex(zcc) {
    let getUrl = this.apiURl + this.zcctohex + zcc;
    return this.http.get(getUrl, this.getTokenHeader())
  }

  findDeltaEBetweenHex(hex1, hex2) {
    let bodyParam = { hex1, hex2 };
    return this.http.post(this.apiURl + this.deltaEBetweenHex, bodyParam, this.getTokenHeader());
  }

  findDeltaEBetweenTwoZac(zac1, zac2) {
    let bodyParam = { zac1, zac2 };
    return this.http.post(this.apiURl + this.deltaEBetweenZac, bodyParam, this.getTokenHeader());
  }


  downloadFile(fileExchange, outputSwatches) {
    let headers = new HttpHeaders().set('token', this.token);

    const httpBody = new HttpParams()
      .set('fileFormat', fileExchange.fileFormat)
      .set('notes', fileExchange.notes)
      .set('swatchFileName', fileExchange.swatchFileName)
      .set('userName', fileExchange.userName)
      .set('company', fileExchange.company)
      .set('date', fileExchange.date)
      .set('techPack', fileExchange.techPack)
      .set('swatches', JSON.stringify(outputSwatches));
    return this.http.post(this.apiURl + this.fileExportUrl, httpBody, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') }),
          filename: res.headers.get('File-Name')
        }
        return data;
      }))
  }

  exportBatch({ batchData, fileType, conversionType = 'zcc' }) {
    let headers = new HttpHeaders().set('token', this.token);
    const httpBody = new HttpParams()
      .set('rawJson', JSON.stringify(batchData))
      .set('conversionType', conversionType)
      .set('fileType', fileType);

    return this.http.post(this.apiURl + this.batchExportUrl, httpBody, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        var filename = res.headers.get('Content-Disposition');
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') }),
          filename: filename.split('filename=').pop()
        }
        return data;
      }))
  }

  exportString(string, fileType) {
    let headers = new HttpHeaders().set('token', this.token);
    const httpBody = new HttpParams()
      .set('string', string)
      .set('fileType', fileType);

    return this.http.post(this.apiURl + this.stringExportUrl, httpBody, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        var filename = res.headers.get('Content-Disposition');
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') }),
          filename: filename.split('filename=').pop()
        }
        return data;
      }))
  }

  rgbtodeltae(csv) {
    let headers = new HttpHeaders().set('token', this.token);
    const httpBody = new HttpParams()
      .set('csv', csv);

    return this.http.post(this.apiURl + this.rgbtodeltaeURL, httpBody, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        var filename = res.headers.get('Content-Disposition');
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') }),
          filename: filename.split('filename=').pop()
        }
        return data;
      }))
  }


  rgbtohex(csv) {
    let headers = new HttpHeaders().set('token', this.token);
    const httpBody = new HttpParams()
      .set('csv', csv);

    return this.http.post(this.apiURl + this.rgbtohexURL, httpBody, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        var filename = res.headers.get('Content-Disposition');
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') }),
          filename: filename.split('filename=').pop()
        }
        return data;
      }))
  }



  exportBatchFile(batchData) {
    let headers = new HttpHeaders().set('token', this.token);
    return this.http.post(this.apiURl + this.batchFileExportUrl, batchData, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        var filename = res.headers.get('Content-Disposition');
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') }),
          filename: filename.split('filename=').pop()
        }
        return data;
      }))
  }

  uploadFile(batchData) {
    return this.http.post(this.apiURl + this.uploadFileUrl, batchData, this.getTokenHeader());
  }

  private getTokenHeader() {
    let headers = new HttpHeaders({
      'token': this.token
    });
    return { headers: headers };
  }




  uploadCompareFile(batchData) {
    return this.http.post(this.apiURl + this.uploadCompareFileUrl, batchData, this.getTokenHeader());
  }
  uploadCompareFileAsBase64(imageBase) {
    const httpBody = new HttpParams()
      .set('imagebase', imageBase)
    return this.http.post(this.apiURl + this.uploadCompareFileUrlBase, httpBody, this.getTokenHeader());
  }

  createManagerReport(filepath, filename, techpack, notes, userName, company, outputdate, swatches) {
    let headers = new HttpHeaders().set('token', this.token);

    const httpBody = new HttpParams()
      .set('filepath', filepath)
      .set('swatches', JSON.stringify(swatches))
      .set('techpack', techpack)
      .set('notes', notes)
      .set('filename', filename)
      .set('userName', userName)
      .set('company', company)
      .set('date', outputdate);

    return this.http.post(this.apiURl + this.createManagerReportURL, httpBody, { headers, observe: 'response', responseType: 'blob' })
      .pipe(map((res) => {
        var filename = res.headers.get('Content-Disposition');
        let data = {
          image: new Blob([res.body], { type: res.headers.get('Content-Type') })
        }
        return data;
      }))
  }





}