import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, public router: Router) { }
  canActivate(): boolean {

    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/zac-picker'])
      return false
    } else {
      return true
    }
  }
}