import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
// import { ColorPickerPage } from './pages/color-picker/color-picker'
import { Error404Page } from './pages/error-404/error-404'
// import { DyeFormulasPage } from './pages/dye-formulas/dye-formulas'
// import { ColorManagerPage } from './pages/color-manager/color-manager'
import { DigitalImageManager } from './pages/digital-image-manager/digital-image-manager.page'
import { Sample } from './pages/sample/sample.page'
import { HomePage } from './pages/home/home.page'
import { FileExchange } from './pages/fileexchange/file-exchange.page'
import { HsbPicker } from './pages/hsb-picker/hsb-picker.page'
import { PaletteBuilderComponent } from './pages/palette-builder/palette-builder.component'
import { SwatchPicker } from './pages/swatch-picker/swatch-picker.page'
import { ZACPicker } from './pages/zac-picker/zac-picker.page'
import { ZACPickerFilter } from './pages/zac-picker-filter/zac-picker-filter.page'
import { DataconversionComponent } from './pages/dataconversion/dataconversion.component'
// import { ColorSamplerPage } from './pages/color-sampler/color-sampler'
// import { FileExchangePage } from './pages/file-exchange/file-exchange'
// import { GetdeltaeComponent } from './pages/getdeltae/getdeltae.component'
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { LoginGuard } from './login.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'swatch-picker',
		component: SwatchPicker,
		data: {
			title: 'ZCC® SWATCH PICKER'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'zac-picker',
		component: ZACPicker,
		data: {
			title: 'ZENCOLOR® ANALYTICS | ZAC FILTER'
		},
		canActivate: [AuthGuard]
	},
	// {
	// 	path: 'filter',
	// 	component: ZACPickerFilter,
	// 	data: {
	// 		title: 'ZAC/ZCC PICKER'
	// 	},
	// 	canActivate: [AuthGuard]
	// },
	{
		path: 'bulk-data-conversion',
		component: DataconversionComponent,
		data: {
			title: 'ZENCOLOR® COMPLETE | BULK DATA CONVERSION'
		},
		canActivate: [AuthGuard]
	},

	{
		path: 'file-exchange',
		component: FileExchange,
		data: {
			title: 'FILE EXCHANGE'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'normalized-zcc-picker',
		component: HsbPicker,
		data: {
			title: 'ZENCOLOR | NORMALIZED nRGB PICKER'
		},
		canActivate: [AuthGuard]
	},

	{
		path: 'palette-builder',
		component: PaletteBuilderComponent,
		data: {
			title: 'PALETTE BUILDER'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'home',
		component: HomePage,
		data: {
			title: 'Home'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'digital-image-manager',
		component: DigitalImageManager,
		data: {
			title: 'DIGITAL IMAGE MANAGER'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'sample',
		component: Sample,
		data: {
			title: 'ZCC® COLOR SAMPLER'
		},
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: '/zac-picker'

		// component: Error404Page,
		// data: {
		// 	title: 'Error - 404 | Page Not Found'
		// }
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }