import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Injector, ViewChild, ElementRef, NgZone } from '@angular/core';
import { BaseComponent } from '../../base.component'
import { ApiService } from '../../api.service';
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
import { SwatchColorChangedEvent } from "../../events/swatch.color.changed.event";
import { SwatchModel } from "../../models/swatch.model";
import { SwatchChangedEvent } from "../../events/swatch.changed.event";

import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as _ from 'underscore';
declare var jQuery: any;
interface SwatchColor {
	hex: string;
	hashHex: string;
	zcc: string;
	empty: boolean;
	refname: string;
	refnumber: string;
}
@Component({
	selector: 'file-exchange',
	templateUrl: './file-exchange.html',
	styleUrls: ['./file-exchange.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileExchangePage extends BaseComponent implements OnInit {
	exportFormats: any[]
	paletteOptions: any[]
	paletteCheckOptions: any[]
	fileExchange = { fileFormat: 'PNG', swatchFileName: '', techPack: '', notes: '', userName: '', company: '', date: '' }
	@ViewChild('swatchFile') private swatchFile: ElementRef;

	constructor(private spinner: NgxSpinnerService, public confirmationDialogService: ConfirmationDialogService, public injector: Injector, public apiService: ApiService, public _zone: NgZone, public ref: ChangeDetectorRef) {
		super(injector)
	}

	ngOnInit() {
		this.exportFormats = [
			{
				'formatID': 'PNG',
				'formatText': 'PNG'
			},
			{
				'formatID': 'JPG',
				'formatText': 'JPG'
			},
			{
				'formatID': 'ASE',
				'formatText': 'ASE'
			},
			{
				'formatID': 'ACO',
				'formatText': 'ACO'
			},
			{
				'formatID': 'CSV',
				'formatText': 'CSV'
			},
			{
				'formatID': 'JSON',
				'formatText': 'JSON'
			}
		]
		this.paletteOptions = [
			{
				'formatID': 'sortByZCC',
				'formatText': 'Sort Numerically'
			},
			{
				'formatID': 'sortByHue',
				'formatText': 'Sort by Hue'
			},
			{
				'formatID': 'sortBySaturation',
				'formatText': 'Sort by Saturation'
			},
			{
				'formatID': 'sortByLightness',
				'formatText': 'Sort by Lightness'
			}
		]

		this.paletteCheckOptions = [
			{
				'formatID': 'indentifyMissingTags',
				'formatText': 'Identify Missing Tags',
				'checked': this.gm.palettesModel.selectedPalette.shouldShowMissingTags
			},
			{
				'formatID': 'removeDuplicates',
				'formatText': 'Remove Duplicates',
				'checked': false
			},
		]

	}
	public filesToUpload;
	public uploadformdata;
	public importFileName = '';
	fileChangeEvent(fileInput) {
		if (fileInput.target.files.length == 0) {
			return
		}
		var file = fileInput.target.files[0];
		var str = file.name;
		this.importFileName = str;
		var ase = str.indexOf(".ase");
		var aco = str.indexOf(".aco");
		if (ase > -1 || aco > -1) {
			this.filesToUpload = <Array<File>>fileInput.target.files;
			const formData: any = new FormData();
			const files: Array<File> = this.filesToUpload;

			for (let i = 0; i < files.length; i++) {
				formData.append("uploads[]", files[i], files[i]['name']);
			}
			this.uploadformdata = formData;
		} else {
			if (file.type != 'image/jpeg' && file.type != 'image/png') {
				alert('Please select PNG or JPG file'); return;
			}
			this.filesToUpload = <Array<File>>fileInput.target.files;
			const formData: any = new FormData();
			const files: Array<File> = this.filesToUpload;

			for (let i = 0; i < files.length; i++) {
				formData.append("uploads[]", files[i], files[i]['name']);
			}
			this.uploadformdata = formData;
		}
		this.importFileData();
		this.swatchFile.nativeElement.value = ""
	}
	importFileData() {
		if (!this.uploadformdata) {
			alert('Select a PNG or JPEG file.')
			return;
		}
		var emptycolorbox = true;
		for (var sw = 0; sw < this.gm.palettesModel.selectedPalette.swatches.length; sw++) {
			var color = this.gm.palettesModel.selectedPalette.swatches[sw];
			if (color.empty == false) {
				emptycolorbox = false;
			}
		}
		this.spinner.show();
		this.apiService.uploadFile(this.uploadformdata).subscribe((data: any) => {
			this.spinner.hide();
			data = JSON.parse(data);
			if (!data) {
				alert('No data found');
				return;
			}
			if (emptycolorbox) {
				this.replaceSwatches(data);
			} else {
				this.confirmationDialogService.fileConfirm('SWATCH FILE LOAD OPTIONS', 'We\'ve noted a swatch file currently in use. Do you wish to CANCEL this process, MERGE the new file with the existing swatch file, or REPLACE the existing swatch file with the file being loaded?')
					.then((confirmed) => {
						if (confirmed == 1) {
							// let previewFilledSwatches = [];
							// for(var i=0; i<64;i++){
							// 	if(!this.gm.palettesModel.selectedPalette.swatches[i].empty){
							// 		previewFilledSwatches.push(this.gm.palettesModel.selectedPalette.swatches[i]);
							// 	}
							// }
							data.swatches = data.swatches.reverse()
							for (var j = 0; j < this.gm.palettesModel.selectedPalette.swatches.length; j++) {
								var swatch = this.gm.palettesModel.selectedPalette.swatches[j];
								if (swatch.empty == true && data.swatches.length > 0) {
									var respdata = data.swatches.pop();
									swatch.empty = false;
									swatch.rgb = respdata.hex;
									swatch.rgbfull = respdata.red + ',' + respdata.green + ',' + respdata.blue;
									swatch.zcc = respdata.zcc;
									swatch.refname = respdata.referenceName;
									swatch.refnumber = respdata.referenceNumber;
								}
							}
							this.gm.palettesModel.writeDefaultFile();
						} else if (confirmed == 2) {
							this.replaceSwatches(data);
						}
					})
					.catch(() => jQuery('#removeDuplicates').prop('checked', false));
			}
		}, (error) => {
			this.spinner.hide();
			// this.swatchFIleTag.nativeElement.value = "";
			//alert(error.error.message);
			alert(error.error.message);
		})


	}

	replaceSwatches(data) {
		for (var i = 0; i < 64; i++) {
			this.gm.palettesModel.selectedPalette.swatches[i].empty = true
		}
		this.gm.palettesModel.writeDefaultFile();
		for (var i = 0; i < data.swatches.length; i++) {
			var color = data.swatches[i];
			this.gm.palettesModel.selectedPalette.swatches[i].empty = false;
			this.gm.palettesModel.selectedPalette.swatches[i].rgb = color.hex;
			this.gm.palettesModel.selectedPalette.swatches[i].rgbfull = color.red + ',' + color.green + ',' + color.blue;
			this.gm.palettesModel.selectedPalette.swatches[i].zcc = color.zcc;
			this.gm.palettesModel.selectedPalette.swatches[i].refname = color.referenceName;
			this.gm.palettesModel.selectedPalette.swatches[i].refnumber = color.referenceNumber;
			// this.gm.palettesModel.selectedPalette.swatches[i].hslFull = this.zccHsl;
			// this.gm.palettesModel.selectedPalette.swatches[i].hue = this.zccHsl.h;
			// this.gm.palettesModel.selectedPalette.swatches[i].saturation = this.zccHsl.s;
			// this.gm.palettesModel.selectedPalette.swatches[i].lightness = this.zccHsl.l;
		}
		this.gm.palettesModel.writeDefaultFile();
	}


	selectFileFormat(format: any) {
		this.fileExchange.fileFormat = format;
	}
	contrastingFontColor(swatch): string {
		return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
	}

	handleDuplicate(event: any, selectType: any) {
		if (selectType == 'removeDuplicates') {

			let b = this.gm.palettesModel.selectedPalette.swatches;
			let uniqueArray: any = [];
			let self = this;

			let isDuplicateExist = false
			let propertyName = "zcc"
			self._zone.run(() => {
				let testObject = {};
				b.map(function (item: any, index: any) {
					var itemPropertyName = item[propertyName];
					if (itemPropertyName in testObject) {
						if (item.empty == false) {
							isDuplicateExist = true
						}

					}
					else {
						let newSwatch = new SwatchModel()
						newSwatch.copy(item);
						testObject[itemPropertyName] = item
						if (item.empty == false) {
							uniqueArray.push(newSwatch);
						}
					}
				});
				if (isDuplicateExist == true) {
					this.confirmationDialogService.confirm('Duplicate Swatch', 'Duplicate Swatches will be permanently removed.')
						.then((confirmed) => {
							if (confirmed) {
								self.loadFileColors(uniqueArray);

							}
							jQuery('#removeDuplicates').prop('checked', false);
						})
						.catch(() => jQuery('#removeDuplicates').prop('checked', false));

				}
				else {
					this.confirmationDialogService.alert('Duplicate Swatch', 'No duplicate swatches found.')
						.then((confirmed) => {
							if (confirmed) {
							}
							jQuery('#removeDuplicates').prop('checked', false);
						})
						.catch(() =>
							jQuery('#removeDuplicates').prop('checked', false)
						);

				}

			});

		} else {
			this.fillMissingTags(event)
		}

	}
	fillMissingTags(event: any) {
		if (event.target.checked) {
			this.gm.palettesModel.selectedPalette.shouldShowMissingTags = true;
			this.gm.palettesModel.selectedPalette.swatches.map(function (swatch) {
				if (swatch.refnumber == '' || swatch.refname == '') {
					swatch.missingRefTag = true;
				}
				else {
					swatch.missingRefTag = false;
				}

			});
		}
		else {
			this.gm.palettesModel.selectedPalette.shouldShowMissingTags = false;
			this.gm.palettesModel.selectedPalette.swatches.map(function (swatch) {
				swatch.missingRefTag = false;
			});
		}
		this.gm.palettesModel.writeDefaultFile();
	}

	sortPaletteSwatchesBy(value: any) {
		let swatches = [];

		for (var i = 0; i < this.gm.palettesModel.selectedPalette.swatches.length; i++) {
			var swatch = this.gm.palettesModel.selectedPalette.swatches[i];
			if (swatch.empty != true) {
				swatches.push(swatch);
			}
		}
		let sortedobj: any;
		switch (value) {
			case "sortByZCC":
				sortedobj = _.sortBy(swatches, 'zcc');
				break;
			case "sortByHue":
				sortedobj = _.sortBy(swatches, 'hue');
				break;
			case "sortBySaturation":
				sortedobj = _.sortBy(swatches, 'saturation');
				break;
			case "sortByLightness":
				sortedobj = _.sortBy(swatches, 'lightness');
				break;
			default:
			//console.log('No format found');
		}
		var sortobj = JSON.parse(JSON.stringify(sortedobj));
		this.loadFileColors(sortobj);


	}
	private importedData: any;
	private paletteColors: SwatchColor[] = []

	loadFileColors(colorData: any) {
		var self = this;
		let swatches = this.gm.palettesModel.selectedPalette.swatches;
		for (let s = 0; s < swatches.length; s++) {
			swatches[s].empty = true;
			swatches[s].refname = '';
			swatches[s].refnumber = '';
			swatches[s].zcc = '';
			swatches[s].contrastingFontColor = "white";
		}
		self._zone.run(() => {
			self.importedData = colorData;
			self.paletteColors = colorData.map((elem: any) => {
				return { 'hashHex': elem.rgb, 'hex': elem.rgbNoHash, 'refname': elem.refname, 'refnumber': elem.refnumber, 'zcc': elem.zcc, 'empty': false }
			});
		});
		for (var i = 0; i < self.paletteColors.length; i++) {
			let swatchColor = self.paletteColors[i];
			let value = swatchColor.hashHex;

			let o = new SwatchColorChangedEvent({
				'oldSwatch': new SwatchModel(JSON.parse(JSON.stringify(this.gm.palettesModel.selectedSwatch)))
			});
			let target = this.gm.palettesModel.selectedPalette.swatches[i];
			if (swatchColor.empty) {
				target.empty = true;
			} else {
				target.empty = false;
			}
			let c = this.gm.colorUtility.generateZCCColors(value, swatchColor.zcc, target);

			// makes sure the new swatch is not empty now, in case it was empty before applying swatch
			o.newSwatch = new SwatchModel(JSON.parse(JSON.stringify(target)));
			this.gm.palettesModel.swatchColorChanged.emit(o);

			self.gm.palettesModel.selectedPalette.swatches[i].refname = swatchColor.refname;
			self.gm.palettesModel.selectedPalette.swatches[i].refnumber = swatchColor.refnumber;
			if (swatchColor.refnumber == self.gm.palettesModel.selectedPalette.swatches[i].zcc) {
				self.gm.palettesModel.selectedPalette.swatches[i].defaultZcc = true;
			}
			else {
				self.gm.palettesModel.selectedPalette.swatches[i].defaultZcc = false;
			}
			if (swatchColor.refnumber == '' || swatchColor.refname == '') {
				self.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = true;
			}
			else {
				self.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = false;
			}
		}
		this.gm.palettesModel.selectedPalette.deselectAllSwatches();
		this.gm.palettesModel.selectedPalette.swatches[0].selected = true;
		var o = new SwatchChangedEvent({
			"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
			"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
			"newSwatch": this.gm.palettesModel.selectedPalette.swatches[0],
			"newPalette": this.gm.palettesModel.selectedPalette
		});
		this.gm.palettesModel.selectedSwatch = this.gm.palettesModel.selectedPalette.swatches[0];
		this.gm.palettesModel.swatchChanged.emit(o);
		this.gm.palettesModel.writeDefaultFile();
	}

	exportFile() {
		if (this.fileExchange.fileFormat == '') {
			alert('Select a File Format to export.');
			return;
		}

		if (this.fileExchange.swatchFileName == '') {
			alert('Select a File Name to export.');
			return;
		}
		let userDetails = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
		this.fileExchange.userName = userDetails.ContactName
		this.fileExchange.company = userDetails.CompanyName

		var event = new Date();
		var options = { year: 'numeric', month: 'short', day: 'numeric' };
		let date = event.toLocaleDateString("en-US", options)
		let time = new Date();
		var outputdate = 'Date: ' + date + '    ' + time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds() + ' ' + new Date().toTimeString().match(new RegExp("[A-Z](?!.*[\(])", "g")).join('')
		this.fileExchange.date = outputdate;
		let swatch = this.gm.palettesModel.selectedPalette.swatches;
		var swatches: any = [];
		var paletteInfo = {
			swatches
		};
		var k = 0;
		var sp = this.gm.palettesModel.selectedPalette;
		for (var i = 0; i < swatch.length; i++) {
			if (swatch[i].empty == false) {
				var rgbArray = swatch[i].rgbfull.split(',');
				paletteInfo.swatches[k] = {
					zcc: swatch[i].zcc,     
					hex: swatch[i].rgb,
					red: rgbArray[0],
					green: rgbArray[1],
					blue: rgbArray[2],
					empty: swatch[i].empty,
					referenceName: swatch[i].refname.toUpperCase(),
					referenceNumber: swatch[i].refnumber.toUpperCase(),
					showRefNumber: swatch[i].defaultZcc,
				};
				k++
			}

		}
		this.spinner.show();
		this.apiService.downloadFile(this.fileExchange, paletteInfo).subscribe(
			(res) => {
				this.spinner.hide();
				const element = document.createElement('a');
				element.href = URL.createObjectURL(res.image);
				element.download = this.fileExchange.swatchFileName + '.' + this.fileExchange.fileFormat.toLowerCase();
				document.body.appendChild(element);
				element.click();
			},
			(error) => {
				this.spinner.hide();
				alert(error.error.message);
			});
	}

}