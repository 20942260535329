import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
  Injector,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  NgZone,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "../../base.component";
import { ApiService } from "../../api.service";
import { ColorPickerService } from "../../components/color-picker/color-picker.service";
import { SwatchModel } from "../../models/swatch.model";
import { SwatchChangedEvent } from "../../events/swatch.changed.event";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Options } from "@angular-slider/ngx-slider";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as cloneDeep from "lodash/cloneDeep";
import { delay } from "underscore";
declare var jQuery: any;

enum InputFields {
  NONE = "none",
  HEX = "hex",
  ZCC = "zcc",
  ZAC = "zac",
}

enum ZoomLevel {
  default = 0, //all swatches of single face
  level1 = 1, //five by five
  level2 = 2, // three by three
  level3 = 3, // selected swatch
}

@Component({
  selector: "zac-picker",
  templateUrl: "./zac-picker.page.html",
  styleUrls: ["./zac-picker.page.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZACPicker extends BaseComponent implements OnInit {
  value: number = 4;
  selectedEvent: string = "";
  selectedZCC = "";
  selectedZAC = "";
  selectedHex = "";
  primaryColors_15 = [
    {
      src: "/assets/redside.png",
      sideInitials: "R",
      colorName: "red",
      hex: "#FF0000",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#FF0000",
        "#E61A1A",
        "#D52B2B",
        "#C43C3C",
        "#B34D4D",
        "#A25E5E",
        "#916F6F",
        "#808080",
      ],
    },
    {
      src: "/assets/yellowside.png",
      sideInitials: "Y",
      colorName: "yellow",
      hex: "#FFFF00",
      swatchesColor: [],
      textColor: "#333333",
      layerColors: [
        "#FFFF00",
        "#E6E61A",
        "#D5D52B",
        "#C4C43C",
        "#B3B34D",
        "#A2A25E",
        "#91916F",
        "#808080",
      ],
    },
    {
      src: "/assets/greenside.png",
      sideInitials: "G",
      colorName: "green",
      hex: "#00FF00",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#00FF00",
        "#1AE61A",
        "#2BD52B",
        "#3CC43C",
        "#4DB34D",
        "#5EA25E",
        "#6F916F",
        "#808080",
      ],
    },
    {
      src: "/assets/cyanside.png",
      sideInitials: "C",
      colorName: "cyan",
      hex: "#00FFFF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#00FFFF",
        "#1AE6E6",
        "#2BD5D5",
        "#3CC4C4",
        "#4DB3B3",
        "#5EA2A2",
        "#6F9191",
        "#808080",
      ],
    },
    {
      src: "/assets/blueside.png",
      sideInitials: "B",
      colorName: "blue",
      hex: "#0000FF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#0000FF",
        "#1A1AE6",
        "#2B2BD5",
        "#3C3CC4",
        "#4D4DB3",
        "#5E5EA2",
        "#6F6F91",
        "#808080",
      ],
    },
    {
      src: "/assets/magentoside.png",
      sideInitials: "M",
      colorName: "magento",
      hex: "#FF00FF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#FF00FF",
        "#E61AE6",
        "#D52BD5",
        "#C43CC4",
        "#B34DB3",
        "#A25EA2",
        "#916F91",
        "#808080",
      ],
    },
  ];
  primaryColors_17 = [
    {
      src: "/assets/redside.png",
      sideInitials: "R",
      colorName: "red",
      hex: "#FF0000",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#FF0000",
        "#EE1111",
        "#DD2222",
        "#CC3333",
        "#BB4444",
        "#AA5555",
        "#996666",
        "#887777",
        "#808080",
      ],
    },
    {
      src: "/assets/yellowside.png",
      sideInitials: "Y",
      colorName: "yellow",
      hex: "#FFFF00",
      swatchesColor: [],
      textColor: "#333333",
      layerColors: [
        "#FFFF00",
        "#EEEE11",
        "#DDDD22",
        "#CCCC33",
        "#BBBB44",
        "#AAAA55",
        "#999966",
        "#888877",
        "#808080",
      ],
    },
    {
      src: "/assets/greenside.png",
      sideInitials: "G",
      colorName: "green",
      hex: "#00FF00",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#00FF00",
        "#11EE11",
        "#22DD22",
        "#33CC33",
        "#44BB44",
        "#55AA55",
        "#669966",
        "#778877",
        "#808080",
      ],
    },
    {
      src: "/assets/cyanside.png",
      sideInitials: "C",
      colorName: "cyan",
      hex: "#00FFFF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#00FFFF",
        "#11EEEE",
        "#22DDDD",
        "#33CCCC",
        "#44BBBB",
        "#55AAAA",
        "#669999",
        "#778888",
        "#808080",
      ],
    },
    {
      src: "/assets/blueside.png",
      sideInitials: "B",
      colorName: "blue",
      hex: "#0000FF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#0000FF",
        "#1111EE",
        "#2222DD",
        "#3333CC",
        "#4444BB",
        "#5555AA",
        "#666699",
        "#777788",
        "#808080",
      ],
    },
    {
      src: "/assets/magentoside.png",
      sideInitials: "M",
      colorName: "magento",
      hex: "#FF00FF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#FF00FF",
        "#EE11EE",
        "#DD22DD",
        "#CC33CC",
        "#BB44BB",
        "#AA55AA",
        "#996699",
        "#887788",
        "#808080",
      ],
    },
  ];
  primaryColors_33 = [
    {
      src: "/assets/redside.png",
      sideInitials: "R",
      colorName: "red",
      hex: "#FF0000",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#FF0000",
        "#f70808",
        "#ef1010",
        "#e71818",
        "#df2020",
        "#d72828",
        "#cf3030",
        "#c73838",
        "#bf4040",
        "#b74848",
        "#af5050",
        "#a75858",
        "#9f6060",
        "#976868",
        "#8f7070",
        "#877878",
        "#808080",
      ],
    },
    {
      src: "/assets/yellowside.png",
      sideInitials: "Y",
      colorName: "yellow",
      hex: "#FFFF00",
      swatchesColor: [],
      textColor: "#333333",
      layerColors: [
        "#ffff00",
        "#f7f708",
        "#efef10",
        "#e7e718",
        "#dfdf20",
        "#d7d728",
        "#cfcf30",
        "#c7c738",
        "#bfbf40",
        "#b7b748",
        "#afaf50",
        "#a7a758",
        "#9f9f60",
        "#979768",
        "#8f8f70",
        "#878778",
        "#808080",
      ],
    },
    {
      src: "/assets/greenside.png",
      sideInitials: "G",
      colorName: "green",
      hex: "#00FF00",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#00ff00",
        "#08f708",
        "#10ef10",
        "#18e718",
        "#20df20",
        "#28d728",
        "#30cf30",
        "#38c738",
        "#40bf40",
        "#48b748",
        "#50af50",
        "#58a758",
        "#609f60",
        "#689768",
        "#708f70",
        "#788778",
        "#808080",
      ],
    },
    {
      src: "/assets/cyanside.png",
      sideInitials: "C",
      colorName: "cyan",
      hex: "#00FFFF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#00ffff",
        "#08f7f7",
        "#10efef",
        "#18e7e7",
        "#20dfdf",
        "#28d7d7",
        "#30cfcf",
        "#38c7c7",
        "#40bfbf",
        "#48b7b7",
        "#50afaf",
        "#58a7a7",
        "#609f9f",
        "#689797",
        "#708f8f",
        "#788787",
        "#808080",
      ],
    },
    {
      src: "/assets/blueside.png",
      sideInitials: "B",
      colorName: "blue",
      hex: "#0000FF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#0000ff",
        "#0808f7",
        "#1010ef",
        "#1818e7",
        "#2020df",
        "#2828d7",
        "#3030cf",
        "#3838c7",
        "#4040bf",
        "#4848b7",
        "#5050af",
        "#5858a7",
        "#60609f",
        "#686897",
        "#70708f",
        "#787887",
        "#808080",
      ],
    },
    {
      src: "/assets/magentoside.png",
      sideInitials: "M",
      colorName: "magento",
      hex: "#FF00FF",
      swatchesColor: [],
      textColor: "#FFFFFF",
      layerColors: [
        "#ff00ff",
        "#f708f7",
        "#ef10ef",
        "#e718e7",
        "#df20df",
        "#d728d7",
        "#cf30cf",
        "#c738c7",
        "#bf40bf",
        "#b748b7",
        "#af50af",
        "#a758a7",
        "#9f609f",
        "#976897",
        "#8f708f",
        "#877887",
        "#808080",
      ],
    },
  ];

  primaryColors: any;
  changedInputField = InputFields.NONE;
  selectedCubeFace: any;
  selectedCubeFaceSwatches = [];
  selectedLayer: any;
  screenWidth = window.innerWidth - 60;
  setWidth = window.innerHeight - 115;
  showWorkSpace = false;
  activeColorsCount = 9;
  activeColor = "#FF0000";
  previewModal = false;
  showGrid = true;
  idDeltaEFilter = false;
  zccdeltaE = "0.0";
  zoomLevel: ZoomLevel = ZoomLevel.default;
  selectedSwatch = {
    zac: "",
    zcc: "",
    hex: "",
    hexString: "",
    cmyk: { c: "", m: "", y: "", k: "" },
    rgb: { r: "", g: "", b: "" },
    hue: "",
    saturation: "",
    lightness: "",
    cubeFace: "",
    layer: "",
  };
  inputSwatch = {
    zac: "",
    zcc: "",
    hex: "",
    hexString: "",
    rgb: { r: "", g: "", b: "" },
    cmyk: { c: "", m: "", y: "", k: "" },
    hue: "",
    saturation: "",
    lightness: "",
  };

  compareZAC = {
    zac1: null,
    zac2: null,
  };
  selectedBrightnessIndex: any;
  Arr = Array;
  zacType = "17";
  layerColors_15 = [
    {
      label: "A",
      color: "#FF0000",
      count: 15,
      gridCount: 15,
    },
    {
      label: "B",
      color: "#F70909",
      count: 13,
      gridCount: 13,
    },
    {
      label: "C",
      color: "#EE1111",
      count: 11,
      gridCount: 11,
    },
    {
      label: "D",
      color: "#E61A1A",
      count: 9,
      gridCount: 9,
    },
    {
      label: "E",
      color: "#E61A1A",
      count: 7,
      gridCount: 7,
    },
    {
      label: "F",
      color: "#E61A1A",
      count: 5,
      gridCount: 5,
    },
    {
      label: "G",
      color: "#E61A1A",
      count: 3,
      gridCount: 3,
    },
    {
      label: "X",
      color: "#808080",
      count: 1,
      gridCount: 1,
    },
  ];
  layerColors_17 = [
    {
      label: "A",
      color: "#FF0000",
      count: 17,
      gridCount: 17,
    },
    {
      label: "B",
      color: "#EE1111",
      count: 15,
      gridCount: 15,
    },
    {
      label: "C",
      color: "#DD2222",
      count: 13,
      gridCount: 13,
    },
    {
      label: "D",
      color: "#CC3333",
      count: 11,
      gridCount: 11,
    },
    {
      label: "E",
      color: "#BB4444",
      count: 9,
      gridCount: 9,
    },
    {
      label: "F",
      color: "#AA5555",
      count: 7,
      gridCount: 7,
    },
    {
      label: "G",
      color: "#996666",
      count: 5,
      gridCount: 5,
    },
    {
      label: "H",
      color: "#887777",
      count: 3,
      gridCount: 3,
    },
    {
      label: "X",
      color: "#808080",
      count: 1,
      gridCount: 1,
    },
  ];

  layerColors_33 = [
    {
      label: "A",
      color: "#FF0000",
      count: 33,
      gridCount: 33,
    },
    {
      label: "B",
      color: "#EE1111",
      count: 31,
      gridCount: 31,
    },
    {
      label: "C",
      color: "#DD2222",
      count: 29,
      gridCount: 29,
    },
    {
      label: "D",
      color: "#CC3333",
      count: 27,
      gridCount: 27,
    },
    {
      label: "E",
      color: "#BB4444",
      count: 25,
      gridCount: 25,
    },
    {
      label: "F",
      color: "#AA5555",
      count: 23,
      gridCount: 23,
    },
    {
      label: "G",
      color: "#996666",
      count: 21,
      gridCount: 21,
    },
    {
      label: "H",
      color: "#887777",
      count: 19,
      gridCount: 19,
    },
    {
      label: "J",
      color: "#887777",
      count: 17,
      gridCount: 17,
    },
    {
      label: "K",
      color: "#887777",
      count: 15,
      gridCount: 15,
    },
    {
      label: "L",
      color: "#887777",
      count: 13,
      gridCount: 13,
    },
    {
      label: "M",
      color: "#887777",
      count: 11,
      gridCount: 11,
    },
    {
      label: "N",
      color: "#887777",
      count: 9,
      gridCount: 9,
    },
    {
      label: "P",
      color: "#887777",
      count: 7,
      gridCount: 7,
    },
    {
      label: "Q",
      color: "#887777",
      count: 5,
      gridCount: 5,
    },
    {
      label: "R",
      color: "#887777",
      count: 3,
      gridCount: 3,
    },
    {
      label: "X",
      color: "#808080",
      count: 1,
      gridCount: 1,
    },
  ];

  layerColors = null;

  brightnessIndex = [
    "#000000",
    "#110000",
    "#220000",
    "#330000",
    "#440000",
    "#550000",
    "#660000",
    "#770000",
    "#880000",
    "#990000",
    "#AA0000",
    "#BB0000",
    "#BB1100",
    "#CC1100",
    "#DD1100",
    "#EE0000",
    "#FF0000",
    "#FF1111",
    "#FF2222",
    "#FF3333",
    "#FF4444",
    "#FF5555",
    "#FF6666",
    "#FF7777",
    "#FF8888",
    "#FF9999",
    "#FFAAAA",
    "#FFBBBB",
    "#FFD0D0",
    "#FFE1E1",
    "#FFF2F2",
    "#FFFFFF",
  ];

  grayScaleSlider = [
    "#000000",
    "#080808",
    "#101010",
    "#191919",
    "#212121",
    "#292929",
    "#313131",
    "#3a3a3a",
    "#424242",
    "#4a4a4a",
    "#525252",
    "#5a5a5a",
    "#636363",
    "#6b6b6b",
    "#737373",
    "#7b7b7b",
    "#848484",
    "#8c8c8c",
    "#949494",
    "#9c9c9c",
    "#a5a5a5",
    "#adadad",
    "#b5b5b5",
    "#bdbdbd",
    "#c5c5c5",
    "#cecece",
    "#d6d6d6",
    "#dedede",
    "#e6e6e6",
    "#efefef",
    "#f7f7f7",
    "#ffffff",
  ];
  options: Options = {
    floor: 0,
    ceil: 16,
    step: 2,
  };
  constructor(
    private spinner: NgxSpinnerService,
    public ref: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public confirmationDialogService: ConfirmationDialogService,
    public injector: Injector,
    public _zone: NgZone,
    public apiService: ApiService,
    public service: ColorPickerService,
    private router: Router
  ) {
    super(injector);
    this.layerColors = this.layerColors_17;
    this.zacType = "17";
    this.primaryColors = this.primaryColors_17;
    //console.log("gm", this.gm);
  }

  private _swatchChangedSubscription: any;

  ngOnDestroy() {
    this.gm.selectedSwatchPickerStack = [];
    if (!this._swatchChangedSubscription.closed) {
      this._swatchChangedSubscription.unsubscribe();
    }
    this.createScrollScript();
  }

  setZoomLevel(zoomLevel) {
    // this.zoomLevel = zoomLevel
    // if (this.zoomLevel == ZoomLevel.default) {
    // 	this.brightnessIndex = cloneDeep(this.grayScaleSlider)
    // 	this.selectedBrightnessIndex = -1
    // }
    // else{
    // 	this.createBrightnessSliderColor()
    // }
  }

  public isMouseDown = false;
  ngAfterViewInit(): void {
    let self = this;
    jQuery("#bbox").mousedown(function (e: any) {
      self.isMouseDown = true;
    });
    jQuery("body").mouseup(function (e: any) {
      self.isMouseDown = false;
    });
    this.ref.markForCheck();
  }
  isClicked(index) {
    this.setBrightnessIndex(index);
  }
  brightnessSliderIndexChanged(index) {
    if (this.isMouseDown == false) {
      return;
    }
    this.setBrightnessIndex(index);
  }

  setBrightnessIndex(index) {
    this.selectedBrightnessIndex = index;
    let hex = this.brightnessIndex[index];
    this.getColorFromHex(hex);
  }

  selectBrightness(self) {
    var bg = jQuery(self).attr("style");
  }

  ngOnInit(): void {
    jQuery(document).on("click", "#workspace", function (e: any) {
      e.preventDefault();
      jQuery("#show-workspace").click();
    });
    this.setZoomLevel(ZoomLevel.default);
    let palettesModel = this.gm.palettesModel;
    this._swatchChangedSubscription = palettesModel.swatchChanged
    	.subscribe(id => this.swatchChanged(id));

    this.screenWidth = window.innerWidth - 60;
    this.setWidth = window.innerHeight - 115;
    var initialLayer = this.layerColors[0];
    let initialPrimaryColor = this.primaryColors[0];

    this.setActivelayer(initialLayer);
    this.setLayerFace(this.primaryColors[0]);
    this.ref.markForCheck();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth - 60;
    this.setWidth = window.innerHeight - 115;
  }

  swatchChanged(o: SwatchChangedEvent): void {
    //case when new swatch is selected from workspace only
    //check is applied so it works only when swatch is selected from workspace area only
    if (o.oldSwatchId != undefined) {
      let self = this
      let swatch = o.newSwatch
      self.inputSwatch.hex = swatch.rgb;
      self.inputSwatch.hexString = swatch.rgb.substring(1, 7);
      self.inputSwatch.rgb = {r: swatch.r, g: swatch.g, b: swatch.b};
      self.inputSwatch.cmyk = self.rgb2cmyk(swatch.r, swatch.g, swatch.b);
      self.inputSwatch.hue = swatch.hue;
      self.inputSwatch.zcc = swatch.zcc;
      self.inputSwatch.saturation = swatch.saturation;
      self.inputSwatch.lightness = swatch.lightness;
      self.getDeltaEBetweenHex(self.selectedSwatch.hex, self.inputSwatch.hex).then(function (results) {
        self.zccdeltaE = parseFloat(results as string).toFixed(2)
        self.ref.markForCheck();
      })
    }
   
    this.ref.markForCheck();
  }

  setSelectedSwatch(item: any) {
    if (this.selectedSwatch.zcc.length > 0) {
      let lastItem =
        this.gm.selectedSwatchPickerStack[
          this.gm.selectedSwatchPickerStack.length - 1
        ];
      if (lastItem == undefined || lastItem.zcc != this.selectedSwatch.zcc) {
        this.gm.selectedSwatchPickerStack.push(this.selectedSwatch);
      }
    }

    this.selectedSwatch = item;
    let hexString = { hexString: this.selectedSwatch.hex.substring(1, 7) };
    Object.assign(this.selectedSwatch, hexString);
    this.createBrightnessSliderColor();
    // this.indexOfSelectedSwatchAndCurrentLayer()
    //this.createBrightnessSliderColor()
    // let cmyk = this.rgb2cmyk(this.selectedSwatch.rgb.r, this.selectedSwatch.rgb.g, this.selectedSwatch.rgb.b)
    // let cmykObj = {cmyk: cmyk}
    // Object.assign(this.selectedSwatch, cmykObj)
    this.fillBucketFor(item.zac);
    let self = this
    if(self.selectedSwatch != undefined 
      && self.selectedSwatch.hex != undefined
      && self.selectedSwatch.hex.length > 0
      && self.inputSwatch != undefined 
      && self.inputSwatch.hex != undefined
      && self.inputSwatch.hex.length > 0
      ){
        self.getDeltaEBetweenHex(self.selectedSwatch.hex, self.inputSwatch.hex).then(function (results) {
          self.zccdeltaE = parseFloat(results as string).toFixed(2)
          self.ref.markForCheck();
        })
      }
    this.ref.markForCheck();
  }

  updateSelectedSwatch(item: any) {
    if (this.selectedSwatch.zcc.length > 0) {
      let lastItem =
        this.gm.selectedSwatchPickerStack[
          this.gm.selectedSwatchPickerStack.length - 1
        ];
      if (lastItem == undefined || lastItem.zcc != this.selectedSwatch.zcc) {
        this.gm.selectedSwatchPickerStack.push(this.selectedSwatch);
      }
    }
    this.selectedSwatch = item;
    let hexString = { hexString: this.selectedSwatch.hex.substring(1, 7) };
    Object.assign(this.selectedSwatch, hexString);
    this.createBrightnessSliderColor();
    this.removeAllPallets();
    this.ref.markForCheck();
  }

  createBrightnessSliderColor() {
    //get slider colors
    let hue = parseFloat(this.selectedSwatch.hue);
    let saturation = parseFloat(this.selectedSwatch.saturation);
    let lightness = parseFloat(this.selectedSwatch.lightness);
    let index = 31;
    let selectedSwatchLightnessIndex = 100 / 31;
    let newLightness = 0;
    for (let i = 0; i < this.brightnessIndex.length; i++) {
      newLightness = i * selectedSwatchLightnessIndex;
      let hex = this.hslToHex(hue, saturation, newLightness);
      if (i != this.brightnessIndex.length - 1) {
        let nextLightness = (i + 1) * selectedSwatchLightnessIndex;
        if (lightness < nextLightness && lightness >= newLightness) {
          hex = this.hslToHex(hue, saturation, lightness);
          index = i;
        }
      }
      this.brightnessIndex[i] = hex;
    }

    this.selectedBrightnessIndex = index;
    // this.setBrightnessIndex(index)
    this.ref.markForCheck();
  }
  showWorkspace(text: any) {
    jQuery("#page-title").html(text);
    this.createScrollScript();
    this.showWorkSpace = !this.showWorkSpace;
    this.ref.markForCheck();
  }
  gridItemIsSelectedSwatch(item: any) {
    if (this.selectedSwatch.zcc == item.zcc /*&& this.showGrid == true*/) {
      return true;
    } else {
      return false;
    }
    this.ref.markForCheck();
  }

  //validation methods
  isValidHue($event) {
    var charCode = $event.which ? $event.which : $event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    let val = $event.srcElement.value;
    let valR = Number(val + $event.key);
    if (valR > 360) {
      return false;
    }
    return true;
    this.ref.markForCheck();
  }
  isValidSaturationOrLightness($event) {
    var charCode = $event.which ? $event.which : $event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    let val = $event.srcElement.value;
    let valR = Number(val + $event.key);
    if (valR > 100) {
      return false;
    }
    return true;
    this.ref.markForCheck();
  }

  isValidZAC($event) {
    this.changedInputField = InputFields.ZAC;
    this.ref.markForCheck();
  }

  isValidZCC($event) {
    this.changedInputField = InputFields.ZCC;
    this.ref.markForCheck();
    // var zccValue = $event.key

    // let previousValue = $event.srcElement.value;
    // let length = previousValue.length

    // if (length == 0) {
    // 	if(zccValue.match(/[abcdefghjklmnpqrABCDEFGHJKLMNPQR]/)) {
    // 		return true
    // 	}
    // 	return false
    // }

    // if (length > 0 && length < 5) {
    // 	if(zccValue.match(/[0-9]/)) {
    // 		return true
    // 	}
    // 	return false
    // }

    // if (length == 5) {
    // 	if(zccValue.match(/[rgbcmkRGBCMK]/)) {
    // 		return true
    // 	}
    // 	return false
    // }
  }

  isHexNumber($event) {
    this.changedInputField = InputFields.HEX;
    var valueHex = $event.key;
    if (valueHex.match(/[0-9a-fA-F]/)) {
      return true;
    }
    return false;
  }
  isValidHex(hex) {
    if (hex.length == 0) {
      return false;
    }
    hex = hex.charAt(0) === "#" ? hex.substring(1, 7) : hex;

    if (hex.length !== 6) {
      return false;
    }
    if (/[0-9a-f]{6}/i.test(hex) !== true) {
      // alert ('Invalid digits in the input hex value!')
      return false;
    }
    return true;
  }

  isValidNumber($event) {
    var charCode = $event.which ? $event.which : $event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    let val = $event.srcElement.value;
    let valR = Number(val + $event.key);
    if (valR > 255) {
      return false;
    }
    return true;
  }
  isCMYK(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    let val = event.srcElement.value;
    let valR = Number(val + event.key);
    if (valR > 100) {
      return false;
    }
    return true;
  }

  //conversion methods
  hexTorgb(hex: string) {
    hex = hex.charAt(0) === "#" ? hex.substring(1, 7) : hex;

    if (hex.length !== 6) {
      return;
    }
    if (/[0-9a-f]{6}/i.test(hex) !== true) {
      // alert ('Invalid digits in the input hex value!')
      return;
    }

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return { r: r, g: g, b: b };
  }
  //h in 360 s in 100 l in 100 format
  hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  rgbToHsl(ra, ga, ba) {
    var r = ra / 255,
      g = ga / 255,
      b = ba / 255,
      cMax = Math.max(r, g, b),
      cMin = Math.min(r, g, b),
      delta = cMax - cMin,
      l = (cMax + cMin) / 2,
      h = 0,
      s = 0;

    if (delta == 0) {
      h = 0;
    } else if (cMax == r) {
      h = 60 * (((g - b) / delta) % 6);
    } else if (cMax == g) {
      h = 60 * ((b - r) / delta + 2);
    } else {
      h = 60 * ((r - g) / delta + 4);
    }

    if (delta == 0) {
      s = 0;
    } else {
      s = delta / (1 - Math.abs(2 * l - 1));
    }

    if (h < 0) {
      h = h + 360;
    }
    // else if (h == 0) {
    //   h = 360;
    // }

    // return { hue: Math.round(h), saturation: Math.round(100 * s), lightness: Math.round(100 * l) };
    if (h == 0) {
      let hexValue = "#" + this.rgbToHex(ra, ga, ba);
      let layerColors = [
        "#FFFFFF",
        "#000000",
        "#090909",
        "#F7F7F7",
        "#EEEEEE",
        "#111111",
        "#1A1A1A",
        "#E6E6E6",
        "#DDDDDD",
        "#222222",
        "#2B2B2B",
        "#D5D5D5",
        "#CCCCCC",
        "#333333",
        "#3C3C3C",
        "#C4C4C4",
        "#BBBBBB",
        "#444444",
        "#4D4D4D",
        "#B3B3B3",
        "#AAAAAA",
        "#555555",
        "#5E5E5E",
        "#A2A2A2",
        "#999999",
        "#666666",
        "#6F6F6F",
        "#919191",
        "#888888",
        "#777777",
        "#808080",
      ];
      if (layerColors.includes(hexValue)) {
        h = 0;
      } else {
        h = 360;
      }
    }
    return {
      h: Math.round(h),
      s: Math.round(Math.round(100 * 100 * s) / 100),
      l: Math.round(Math.round(100 * 100 * l) / 100),
    };
  }

  rgbToHex(R, G, B): string {
    return this.toHex(R) + this.toHex(G) + this.toHex(B);
  }
  toHex(n): any {
    n = parseInt(n, 10);
    if (isNaN(n)) return "00";
    n = Math.max(0, Math.min(n, 255));
    return (
      "0123456789ABCDEF".charAt((n - (n % 16)) / 16) +
      "0123456789ABCDEF".charAt(n % 16)
    );
  }

  rgb2cmyk(r, g, b): any {
    var computedC = 0;
    var computedM = 0;
    var computedY = 0;
    var computedK = 0;

    //remove spaces from input RGB values, convert to int
    r = parseInt(("" + r).replace(/\s/g, ""), 10);
    g = parseInt(("" + g).replace(/\s/g, ""), 10);
    b = parseInt(("" + b).replace(/\s/g, ""), 10);

    if (
      r == null ||
      g == null ||
      b == null ||
      isNaN(r) ||
      isNaN(g) ||
      isNaN(b)
    ) {
      // alert ('Please enter numeric RGB values!');
      return;
    }
    if (r < 0 || g < 0 || b < 0 || r > 255 || g > 255 || b > 255) {
      // alert ('RGB values must be in the range 0 to 255.');
      return;
    }

    // BLACK
    if (r == 0 && g == 0 && b == 0) {
      return {
        c: 0,
        m: 0,
        y: 0,
        k: 100,
      };
    }

    computedC = 1 - r / 255;
    computedM = 1 - g / 255;
    computedY = 1 - b / 255;

    var minCMY = Math.min(computedC, Math.min(computedM, computedY));

    computedC = (computedC - minCMY) / (1 - minCMY);
    computedM = (computedM - minCMY) / (1 - minCMY);
    computedY = (computedY - minCMY) / (1 - minCMY);

    computedK = minCMY;

    return {
      c: Math.round(100 * (Math.round(computedC * 1000) / 1000)),
      m: Math.round(100 * (Math.round(computedM * 1000) / 1000)),
      y: Math.round(100 * (Math.round(computedY * 1000) / 1000)),
      k: Math.round(100 * (Math.round(computedK * 1000) / 1000)),
    };
  }

  cmyk2rgb(c, m, y, k): any {
    c = c / 100;
    m = m / 100;
    y = y / 100;
    k = k / 100;

    var r = 255 * (1 - c) * (1 - k);
    var g = 255 * (1 - m) * (1 - k);
    var b = 255 * (1 - y) * (1 - k);

    r = Math.round(r * 1) / 1;
    g = Math.round(g * 1) / 1;
    b = Math.round(b * 1) / 1;

    return {
      r: r,
      g: g,
      b: b,
    };
  }

  cmyk2hex(c, m, y, k): string {
    var o = this.cmyk2rgb(c, m, y, k);

    return this.rgbToHex(o.r, o.g, o.b);
  }

  //user action methods
  viewSwatch(item: any) {
    if (item) {
      let self = this
      this.previewModal = false;
      // let previousZCC = this.selectedSwatch.zcc
      this.setSelectedSwatch(item);
      
      this.inputSwatch = cloneDeep(this.selectedSwatch);

      if (
        self.selectedSwatch != undefined &&
        self.selectedSwatch.hex != undefined &&
        self.selectedSwatch.hex.length > 0 &&
        self.inputSwatch != undefined &&
        self.inputSwatch.hex != undefined &&
        self.inputSwatch.hex.length > 0
      ) {
        self
          .getDeltaEBetweenHex(self.selectedSwatch.hex, self.inputSwatch.hex)
          .then(function (results) {
            self.zccdeltaE = parseFloat(results as string).toFixed(2);
            self.ref.markForCheck();
          });
      }
      this.ref.markForCheck();
    }
  }

  fillBucketFor(zac) {
    //console.log("zac", zac);

    if (!zac) zac = this.inputSwatch.zac;

    let self = this;
    this.removeAllPallets();
    if (parseInt(this.zacType) == 33) {
      return;
    }
    this.getBucketsfor(zac).then(function (mainResult: any) {
      let buckets = mainResult;
      
      for (var i = 0; i < buckets.length; i++) {
        let zcc = buckets[i].zcc;
        var swatch = self.gm.palettesModel.selectedPalette.swatches[i];
        swatch.empty = false;
        swatch.rgb = zcc.hex;
        swatch.rgbfull = zcc.rgb.r + "," + zcc.rgb.g + "," + zcc.rgb.b;
        swatch.zcc = zcc.zcc;
        swatch.hslFull = zcc.hue + "," + zcc.saturation + "," + zcc.lightness;
        swatch.hue = zcc.hue;
        swatch.saturation = zcc.saturation;
        swatch.lightness = zcc.lightness;
        //find the actual zcc associated with the zac
        if (swatch.zcc == self.selectedSwatch.zcc) {
          self.gm.palettesModel.selectedSwatch.selected = false;
          self.gm.palettesModel.selectedSwatch = swatch;
          self.gm.palettesModel.selectedSwatch.selected = true
        }
        self.gm.palettesModel.writeDefaultFile();
      }
      self.ref.markForCheck();
    });
  }

  zoomInSwatch() {
    if (this.selectedSwatch.zcc.length > 0) {
      switch (this.zoomLevel) {
        case ZoomLevel.default:
          this.findSwatchesForZoomLevel(ZoomLevel.level3); //changes from level1
          break;
        case ZoomLevel.level1:
          this.findSwatchesForZoomLevel(ZoomLevel.level2);
          break;
        case ZoomLevel.level2:
          this.findSwatchesForZoomLevel(ZoomLevel.level3);
          break;
        // case ZoomLevel.level3:
        // 	this.zoomLevel = ZoomLevel.default
        // break;
        default:
          break;
      }
    }
    this.ref.markForCheck();
  }

  zoomOutSwatch() {
    if (this.selectedSwatch.zcc.length > 0) {
      switch (this.zoomLevel) {
        case ZoomLevel.level3:
          this.findSwatchesForZoomLevel(ZoomLevel.default); //changes from level2
          break;
        case ZoomLevel.level2:
          this.findSwatchesForZoomLevel(ZoomLevel.level1);
          break;
        case ZoomLevel.level1:
          this.findSwatchesForZoomLevel(ZoomLevel.default);
          break;
        default:
          break;
      }
    }
    this.ref.markForCheck();
  }

  findSelectedSwatchIndex() {
    let selectedFace = this.primaryColors.find((obj) => {
      return obj.colorName == this.selectedCubeFace.colorName;
    });
    let index = selectedFace.swatchesColor.findIndex(
      (item) => item.zcc == this.selectedSwatch.zcc
    );
    return index;
  }

  findSwatchesForZoomLevel(zoomLevel) {
    //need to change the logic if we changes the structure array on backend to fix the grid distortion issue in firefox and other browsers
    let selectedFace = this.primaryColors.find((obj) => {
      return obj.colorName == this.selectedCubeFace.colorName;
    });
    let index = selectedFace.swatchesColor.findIndex(
      (item) => item.zcc == this.selectedSwatch.zcc
    );
    if (index == -1) {
      return;
    }
    this.setZoomLevel(zoomLevel);
    let gridArray = [];
    switch (this.zoomLevel) {
      case ZoomLevel.default:
        gridArray = cloneDeep(selectedFace.swatchesColor);
        break;
      case ZoomLevel.level1:
        gridArray = this.swatchesArrayForZoomLevel(
          5,
          index,
          selectedFace.swatchesColor
        );
        break;
      case ZoomLevel.level2:
        gridArray = this.swatchesArrayForZoomLevel(
          3,
          index,
          selectedFace.swatchesColor
        );
        break;
      case ZoomLevel.level3:
        gridArray = this.swatchesArrayForZoomLevel(
          1,
          index,
          selectedFace.swatchesColor
        );
        break;

      default:
        //console.log("zoom level does not support");
        break;
    }
    this.selectedLayer.gridCount = Math.sqrt(gridArray.length);
    this.selectedCubeFace.swatchesColor = gridArray;
    this.ref.markForCheck();
  }

  swatchesArrayForZoomLevel(
    numberOfColumn: number,
    selectedSwatchIndex,
    totalSwatches
  ) {
    let colBatch = Math.floor(numberOfColumn / 2);
    let gridArray = [];
    let gridCount = this.selectedLayer.count;
    let lowerBound = -colBatch;
    let upperBound = colBatch;
    let isvalidateIndex = selectedSwatchIndex - colBatch * gridCount - colBatch;
    if (isvalidateIndex < 0) {
    } else if (isvalidateIndex > totalSwatches.length - 1) {
    }
    for (let col = lowerBound; col <= upperBound; col++) {
      let colTopIndex = selectedSwatchIndex + col * gridCount - colBatch;
      for (let i = 0; i < numberOfColumn; i++) {
        let index = colTopIndex + i;
        if (index >= 0) {
          gridArray.push(totalSwatches[index]);
        }
      }
    }
    return gridArray;
  }

  tagandSave() {
    this.router.navigateByUrl("/file-exchange");
  }

  navigateToPalleteBuilder() {
    this.router.navigateByUrl("/palette-builder");
  }
  movetosample() {
    this.router.navigateByUrl("/sample");
  }

  addSwatchToWorkspace() {
    if (this.selectedSwatch.hex.length > 0) {
      this.gm.deletedAllStack = [];
      if (this.gm.palettesModel.selectedSwatch.selected) {
        this.gm.palettesModel.selectedSwatch.empty = false;
        this.gm.palettesModel.selectedSwatch.rgb = this.selectedSwatch.hex;
        this.gm.palettesModel.selectedSwatch.rgbfull =
          this.selectedSwatch.rgb.r +
          "," +
          this.selectedSwatch.rgb.g +
          "," +
          this.selectedSwatch.rgb.b;
        this.gm.palettesModel.selectedSwatch.zcc = this.selectedSwatch.zcc;
        this.gm.palettesModel.selectedSwatch.hslFull =
          this.selectedSwatch.hue +
          "," +
          this.selectedSwatch.saturation +
          "," +
          this.selectedSwatch.lightness;
        this.gm.palettesModel.selectedSwatch.hue = this.selectedSwatch.hue;
        this.gm.palettesModel.selectedSwatch.saturation =
          this.selectedSwatch.saturation;
        this.gm.palettesModel.selectedSwatch.lightness =
          this.selectedSwatch.lightness;
        this.gm.palettesModel.writeDefaultFile();
      } else {
        for (
          var i = 0;
          i < this.gm.palettesModel.selectedPalette.swatches.length;
          i++
        ) {
          var swatch = this.gm.palettesModel.selectedPalette.swatches[i];
          if (swatch.empty) {
            this.gm.palettesModel.selectedPalette.swatches[i].empty = false;
            this.gm.palettesModel.selectedPalette.swatches[i].rgb =
              this.selectedSwatch.hex;
            this.gm.palettesModel.selectedPalette.swatches[i].rgbfull =
              this.selectedSwatch.rgb.r +
              "," +
              this.selectedSwatch.rgb.g +
              "," +
              this.selectedSwatch.rgb.b;
            this.gm.palettesModel.selectedPalette.swatches[i].zcc =
              this.selectedSwatch.zcc;
            this.gm.palettesModel.selectedSwatch.hslFull =
              this.selectedSwatch.hue +
              "," +
              this.selectedSwatch.saturation +
              "," +
              this.selectedSwatch.lightness;
            this.gm.palettesModel.selectedSwatch.hue = this.selectedSwatch.hue;
            this.gm.palettesModel.selectedSwatch.saturation =
              this.selectedSwatch.saturation;
            this.gm.palettesModel.selectedSwatch.lightness =
              this.selectedSwatch.lightness;
            this.gm.palettesModel.writeDefaultFile();
            break;
          }
        }
      }
    }
    this.ref.markForCheck();
  }

  toggleGrid() {
    this.showGrid = !this.showGrid;
    this.ref.markForCheck();
  }
  selectBucketFilter(selectedItem: any) {
    let prevFilter = this.idDeltaEFilter;
    if (selectedItem == "ecludean") {
      this.idDeltaEFilter = false;
    } else {
      this.idDeltaEFilter = true;
    }
    if (prevFilter != this.idDeltaEFilter) {
      this.fillBucketFor(this.selectedSwatch.zac);
    }
    this.ref.markForCheck();
  }

  selectZACType(selectedItem: any) {
    let prevFilter = this.zacType;
    this.zacType = selectedItem;
    if (prevFilter != this.zacType) {
      let indexOfCubeFace = this.primaryColors.findIndex((element) => {
        return element.sideInitials == this.selectedCubeFace.sideInitials;
      });

      switch (parseInt(this.zacType)) {
        case 33:
          this.layerColors = this.layerColors_33;
          this.primaryColors = this.primaryColors_33;
          break;
        case 17:
          this.layerColors = this.layerColors_17;
          this.primaryColors = this.primaryColors_17;
          break;
        default:
          this.layerColors = this.layerColors_15;
          this.primaryColors = this.primaryColors_15;
          break;
      }

      this.setActivelayer(this.layerColors[0]);
      this.selectedCubeFace = this.primaryColors[indexOfCubeFace];
      this.setLayerFace(this.selectedCubeFace);
      // this.fillBucketFor(this.selectedSwatch.zac)
    }
    this.ref.markForCheck();
  }

  getColorFromHSL(h, s, l) {
    if (h.length == 0 && s.length == 0 && l.length == 0) {
      return;
    }
    if (h.length == 0) {
      h = "0";
      this.inputSwatch.hue = "0";
    }
    if (s.length == 0) {
      s = "0";
      this.inputSwatch.saturation = "0";
    }
    if (l.length == 0) {
      l = "0";
      this.inputSwatch.lightness = "0";
    }
    let hex = this.hslToHex(h, s, l);
    this.inputSwatch.hex = hex;
    this.inputSwatch.hexString = hex.substring(1, 7);
    let rgb = this.hexTorgb(hex);
    this.inputSwatch.rgb.r = rgb.r.toString();
    this.inputSwatch.rgb.g = rgb.g.toString();
    this.inputSwatch.rgb.b = rgb.b.toString();
    let cmyk = this.rgb2cmyk(rgb.r, rgb.g, rgb.b);
    this.inputSwatch.cmyk = cmyk;
    this.ref.markForCheck();
  }

  getColorFromRGB(r, g, b) {
    if (r.length == 0 && g.length == 0 && b.length == 0) {
      return;
    }
    if (r.length == 0) {
      r = "0";
      this.inputSwatch.rgb.r = "0";
    }
    if (g.length == 0) {
      g = "0";
      this.inputSwatch.rgb.g = "0";
    }
    if (b.length == 0) {
      b = "0";
      this.inputSwatch.rgb.b = "0";
    }
    let hex = this.rgbToHex(r, g, b);
    let cmyk = this.rgb2cmyk(r, g, b);
    this.inputSwatch.cmyk = cmyk;
    this.inputSwatch.hexString = hex;
    this.inputSwatch.hex = "#" + hex;
    let hsl = this.rgbToHsl(r, g, b);
    this.inputSwatch.hue = hsl.h.toString();
    this.inputSwatch.saturation = hsl.s.toString();
    this.inputSwatch.lightness = hsl.l.toString();
    this.ref.markForCheck();
  }

  setLayerFace(item: any) {
    this.selectedLayer.gridCount = this.selectedLayer.count;
    this.setZoomLevel(ZoomLevel.default);
    var layerFace = this.primaryColors.find((obj) => {
      return obj.colorName == item.colorName;
    });
    if (layerFace != undefined) {
      this.selectedCubeFace = cloneDeep(layerFace);
    }
    let filteredElements = this.selectedCubeFace.swatchesColor.filter(
      (item) => {
        return item.hue == this.selectedSwatch.hue;
      }
    );
    if (
      this.selectedCubeFace.swatchesColor.length > 0 &&
      (!this.inputSwatch.hex || this.inputSwatch.hex.length == 0)
    ) {
      this.setSelectedSwatch(this.selectedCubeFace.swatchesColor[0]);
    }
    this.ref.markForCheck();
  }
  getColorFromCMYK(c, m, y, k) {
    if (c.length == 0 && m.length == 0 && y.length == 0 && k.length == 0) {
      return;
    }
    if (c.length == 0) {
      c = "0";
      this.inputSwatch.cmyk.c = c;
    }
    if (m.length == 0) {
      m = "0";
      this.inputSwatch.cmyk.m = m;
    }
    if (y.length == 0) {
      y = "0";
      this.inputSwatch.cmyk.y = y;
    }
    if (k.length == 0) {
      k = "0";
      this.inputSwatch.cmyk.k = k;
    }
    let rgb = this.cmyk2rgb(c, m, y, k);
    this.inputSwatch.rgb = rgb;
    let hex = this.cmyk2hex(
      this.inputSwatch.cmyk.c,
      this.inputSwatch.cmyk.m,
      this.inputSwatch.cmyk.y,
      this.inputSwatch.cmyk.k
    );
    this.inputSwatch.hexString = hex;
    this.inputSwatch.hex = "#" + hex;
    let hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
    this.inputSwatch.hue = hsl.h.toString();
    this.inputSwatch.saturation = hsl.s.toString();
    this.inputSwatch.lightness = hsl.l.toString();
    this.ref.markForCheck();
  }

  // commitEditZCCOnEnterPressed(zcc) {
  // 	 this.commitEditZCC(zcc)
  // }

  zacFieldBlur(type, value) {
    this.changedInputField = InputFields.ZAC;
    this.selectedZAC = value;
    this.ref.markForCheck();
    // if (zcc.length < 6) {
    // 	return
    // 	//alert("You have entered an invalid ZCC#.")
    // }
  }

  compareZac1FieldBlur(value) {
    this.compareZAC.zac1;
  }
  compareZac2FieldBlur(value) {
    this.compareZAC.zac2;
  }

  hexFieldBlur(type, value) {
    this.changedInputField = InputFields.HEX;
    this.selectedHex = value;
    this.ref.markForCheck();
    // if (zcc.length < 6) {
    // 	return
    // 	//alert("You have entered an invalid ZCC#.")
    // }
  }

  zccFieldBlur(type, value) {
    this.changedInputField = InputFields.ZCC;
    this.selectedZCC = value;

    this.ref.markForCheck();
    // if (zcc.length < 6) {
    // 	return
    // 	//alert("You have entered an invalid ZCC#.")
    // }
  }

  commitEditZCC(zcc) {
    if (zcc.length == 6) {
      let self = this;
      self.getZCCHEX(zcc).then(function (result: any) {
        let zccItem = result.item;
        self.getColorFromHex(zccItem.hex);
        self.startProcessToGetZCCStandardFromInput();
      });
    } else {
      alert("Invalid length of the input ZCC value!");
    }
    this.ref.markForCheck();
  }

  getColorFromHexOnEnterPressed(hex) {
    this.getColorFromHex(hex);
    this.ref.markForCheck();
  }

  getColorFromHex(hex) {
    if (hex.length > 6) {
      hex = hex.substring(1, 7);
    }
    //bool value to remove this method called twice
    if (this.isValidHex(hex) == false) {
      return;
      //alert ('Invalid length of the input hex value!')
      this.inputSwatch.hex = this.inputSwatch.hex;
      if (this.inputSwatch.hex.length > 0) {
        this.inputSwatch.hexString = this.inputSwatch.hex.substring(1, 7);
      }
      return;
    }
    this.inputSwatch.zcc = "";
    this.inputSwatch.hex = "#" + hex;
    this.inputSwatch.hexString = hex;
    let rgb = this.hexTorgb(hex);
    this.inputSwatch.rgb.r = rgb.r.toString();
    this.inputSwatch.rgb.g = rgb.g.toString();
    this.inputSwatch.rgb.b = rgb.b.toString();
    let hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
    this.inputSwatch.hue = hsl.h.toString();
    this.inputSwatch.saturation = hsl.s.toString();
    this.inputSwatch.lightness = hsl.l.toString();
    let cmyk = this.rgb2cmyk(rgb.r, rgb.g, rgb.b);
    this.inputSwatch.cmyk.c = cmyk.c.toString();
    this.inputSwatch.cmyk.m = cmyk.m.toString();
    this.inputSwatch.cmyk.y = cmyk.y.toString();
    this.inputSwatch.cmyk.k = cmyk.k.toString();
    this.ref.markForCheck();
  }

  selectActiveLayer(item: any) {
    this.setActivelayer(item);
  }
  setActivelayer(item: any) {
    let self = this;
    self.selectedLayer = cloneDeep(item);
    self.getLayerZCC(item.label).then(function (results) {
      self.activeColorsCount = item.count;
      self.activeColor = item.color;
      self.setLayerFace(self.selectedCubeFace);
      self.saturatedSelectedSwatch();
      // this.setSelectedSwatch(self.selectedCubeFace.swatchesColor[0])
    });
    this.ref.markForCheck();
  }

  getDeltaEBetween(zac1, zac2) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self.apiService.findDeltaEBetweenTwoZac(zac1, zac2).subscribe(
        (data: any) => {
          const { deltae } = data;
          resolve(deltae);
        },
        (error) => {
          alert(error.error.message);
        }
      );
    });
  }
  getDeltaEBetweenHex(hex1, hex2) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self.apiService.findDeltaEBetweenHex(hex1, hex2).subscribe(
        (data: any) => {
          const { deltae } = data;
          resolve(deltae);
        },
        (error) => {
          alert(error.error.message);
        }
      );
    });
  }

  processCompareSwatch() {
    let self = this;
    //ZAC1
    const zac1 = this.compareZAC.zac1;
    const zac2 = this.compareZAC.zac2;
    if (zac1 != null) {
      this.getBucketsfor(zac1).then(function (mainResult: any) {
        //console.log("mainResult", mainResult);
        let buckets = mainResult;
        const enteredZAC = zac1.toUpperCase();
        const cubeFace = enteredZAC.charAt(0);
        const zacLayer = enteredZAC.charAt(1);
        var layerFace = self.primaryColors.find((obj) => {
          return obj.sideInitials == cubeFace;
        });
        self.selectedCubeFace = layerFace;
        var item = self.layerColors.find((obj) => {
          return obj.label == zacLayer;
        });
        self.selectedLayer = cloneDeep(item);
        self.getLayerZCC(item.label).then(function (results) {
          let dataItem = results as Array<any>;
          let dstructuredArray = [];
          dataItem.forEach((item) => {
            const items = item.items;
            items.forEach((elem) => dstructuredArray.push(elem));
          });
          const swatchItem = dstructuredArray.find(
            (elem) => elem.zac == enteredZAC
          );
          self.activeColorsCount = item.count;
          self.activeColor = item.color;
          // self.setLayerFace(self.selectedCubeFace);
          // self.saturatedSelectedSwatch();
          self.selectedSwatch = swatchItem;
          let hexString = {
            hexString: self.selectedSwatch.hex.substring(1, 7),
          };

          Object.assign(self.selectedSwatch, hexString);
          self.removeAllPallets();
          for (var i = 0; i < buckets.length; i++) {
            let zcc = buckets[i].zcc;

            var swatch = self.gm.palettesModel.selectedPalette.swatches[i];
            swatch.empty = false;
            swatch.rgb = zcc.hex;
            swatch.rgbfull = zcc.rgb.r + "," + zcc.rgb.g + "," + zcc.rgb.b;
            swatch.zcc = zcc.zcc;
            swatch.hslFull =
              zcc.hue + "," + zcc.saturation + "," + zcc.lightness;
            swatch.hue = zcc.hue;
            swatch.saturation = zcc.saturation;
            swatch.lightness = zcc.lightness;
          }
          self.gm.palettesModel.writeDefaultFile();
        });
        self.ref.markForCheck();
      });
    }

    if (zac2 != null) {
      this.getBucketsfor(zac2).then(function (mainResult: any) {
        //console.log("mainResult", mainResult);
        let buckets = mainResult;
        const enteredZAC = zac2.toUpperCase();
        const cubeFace = enteredZAC.charAt(0);
        const zacLayer = enteredZAC.charAt(1);
        var layerFace = self.primaryColors.find((obj) => {
          return obj.sideInitials == cubeFace;
        });
        var item = self.layerColors.find((obj) => {
          return obj.label == zacLayer;
        });

        self.apiService.zacSwatchesForLayer(item.label, self.zacType).subscribe(
          (data: any) => {
            // self.setZoomLevel(ZoomLevel.default);
            let dataObj = data["data"];
            let dataItem = dataObj.items;

            let dstructuredArray = [];
            dataItem.forEach((item) => {
              const items = item.items;
              items.forEach((elem) => dstructuredArray.push(elem));
            });
            const swatchItem = dstructuredArray.find(
              (elem) => elem.zac == enteredZAC
            );
            self.inputSwatch.hex = swatchItem.hex;

            self.inputSwatch.hexString = swatchItem.hex.substring(1, 7);
            self.inputSwatch.rgb = swatchItem.rgb;
            self.inputSwatch.zac = swatchItem.zac;
            self.inputSwatch.cmyk = swatchItem.cmyk;
            self.inputSwatch.hue = swatchItem.hue;
            self.inputSwatch.zcc = swatchItem.zcc;
            self.inputSwatch.saturation = swatchItem.saturation;
            self.inputSwatch.lightness = swatchItem.lightness;

            self.spinner.hide();
            self.ref.markForCheck();
          },
          (error) => {
            self.spinner.hide();
            alert(error.error.message);
          }
        );
      });
    }
    if (zac1 && zac2) {
      // this.getDeltaEBetween(zac1, zac2).then(function (results) {
      //   self.zccdeltaE = parseFloat(results as string).toFixed(2)
      // })
      this.getDeltaEBetweenHex(
        self.selectedSwatch.hex,
        self.inputSwatch.hex
      ).then(function (results) {
        self.zccdeltaE = parseFloat(results as string).toFixed(2);
      });
    } else {
      this.zccdeltaE = "0.0";
    }
  }

  undoSelectedPickerSwatch() {
    if (this.gm.selectedSwatchPickerStack.length > 0) {
      let lastSwatch = this.gm.selectedSwatchPickerStack.pop();
      let layer = lastSwatch.layer;
      let cubeFace = lastSwatch.cubeFace;
      //this.zccdeltaE = 0.0;
      let self = this;
      if (this.selectedLayer.label == layer) {
        var layerFace = self.primaryColors.find((obj) => {
          return obj.colorName == cubeFace;
        });
        if (layerFace != undefined) {
          self.selectedCubeFace = cloneDeep(layerFace);
          self.selectedSwatch = lastSwatch;
          self.createBrightnessSliderColor();
          self.inputSwatch = cloneDeep(self.selectedSwatch);
          if (
            self.selectedSwatch != undefined &&
            self.selectedSwatch.hex != undefined &&
            self.selectedSwatch.hex.length > 0 &&
            self.inputSwatch != undefined &&
            self.inputSwatch.hex != undefined &&
            self.inputSwatch.hex.length > 0
          ) {
            self
              .getDeltaEBetweenHex(
                self.selectedSwatch.hex,
                self.inputSwatch.hex
              )
              .then(function (results) {
                self.zccdeltaE = parseFloat(results as string).toFixed(2);
                self.ref.markForCheck();
              });
          }
        }
      } else {
        var item = self.layerColors.find((obj) => {
          return obj.label == layer;
        });
        this.selectedLayer = cloneDeep(item);
        self.activeColorsCount = item.count;
        self.activeColor = item.color;

        this.getLayerZCC(layer).then(function (result) {
          var layerFace = self.primaryColors.find((obj) => {
            return obj.colorName == cubeFace;
          });
          if (layerFace != undefined) {
            self.selectedCubeFace = cloneDeep(layerFace);
            self.selectedSwatch = lastSwatch;
            self.createBrightnessSliderColor();
            self.inputSwatch = cloneDeep(self.selectedSwatch);
            if (
              self.selectedSwatch != undefined &&
              self.selectedSwatch.hex != undefined &&
              self.selectedSwatch.hex.length > 0 &&
              self.inputSwatch != undefined &&
              self.inputSwatch.hex != undefined &&
              self.inputSwatch.hex.length > 0
            ) {
              self
                .getDeltaEBetweenHex(
                  self.selectedSwatch.hex,
                  self.inputSwatch.hex
                )
                .then(function (results) {
                  self.zccdeltaE = parseFloat(results as string).toFixed(2);
                  self.ref.markForCheck();
                });
            }
          }
        });
      }
    }
    this.ref.markForCheck();
  }

  enterColorCode() {
    let self = this;
    this.removeAllPallets();
    if (this.changedInputField == InputFields.ZCC) {
      this.changedInputField = InputFields.NONE;
      // this.commitEditZCC(this.inputSwatch.zcc)
      this.getBucketsforzcc(false).then(function (mainResult: any) {
        //console.log("mainResult", mainResult);

        let { buckets, parent } = mainResult;
        self.inputSwatch.zac = parent.zac;
        //self.inputSwatch.zcc = parent.zcc;
        self.inputSwatch.hex = self.selectedHex;

        var layerFace = self.primaryColors.find((obj) => {
          return obj.colorName == parent.cubeFace;
        });
        self.selectedCubeFace = layerFace;
        var item = self.layerColors.find((obj) => {
          return obj.label == parent.zacLayer;
        });
        self.selectedLayer = cloneDeep(item);
        self.getLayerZCC(item.label).then(function (results) {
          self.activeColorsCount = item.count;
          self.activeColor = item.color;
          // self.setLayerFace(self.selectedCubeFace);
          // self.saturatedSelectedSwatch();
          self.selectedSwatch = parent;
          let hexString = {
            hexString: self.selectedSwatch.hex.substring(1, 7),
          };
          Object.assign(self.selectedSwatch, hexString);
          self.removeAllPallets();
          for (var i = 0; i < buckets.length; i++) {
            let zcc = buckets[i].zcc;
            var swatch = self.gm.palettesModel.selectedPalette.swatches[i];
            swatch.empty = false;
            swatch.rgb = zcc.hex;
            swatch.rgbfull = zcc.rgb.r + "," + zcc.rgb.g + "," + zcc.rgb.b;
            swatch.zcc = zcc.zcc;
            swatch.hslFull =
              zcc.hue + "," + zcc.saturation + "," + zcc.lightness;
            swatch.hue = zcc.hue;
            swatch.saturation = zcc.saturation;
            swatch.lightness = zcc.lightness;
            swatch.selected = false;
            if (zcc.zcc == self.inputSwatch.zcc.toUpperCase()) {
              self.gm.palettesModel.selectedSwatch = swatch;
              self.gm.palettesModel.selectedSwatch.selected = true;
              self.inputSwatch.hex = zcc.hex;
              self.inputSwatch.hexString = zcc.hex.substring(1, 7);
              self.inputSwatch.rgb = zcc.rgb;
              self.inputSwatch.cmyk = zcc.cmyk;
              self.inputSwatch.hue = zcc.hue;
              self.inputSwatch.zcc = zcc.zcc;
              self.inputSwatch.saturation = zcc.saturation;
              self.inputSwatch.lightness = zcc.lightness;
            }
          }
          self.gm.palettesModel.writeDefaultFile();
          self
            .getDeltaEBetweenHex(self.selectedSwatch.hex, self.inputSwatch.hex)
            .then(function (results) {
              self.zccdeltaE = parseFloat(results as string).toFixed(2);
              self.ref.markForCheck();
            });
        });
        //  self.selectedSwatch = parent
        // let hexString = {hexString: this.selectedSwatch.hex.substring(1,7)}
        // Object.assign(this.selectedSwatch, hexString)

        self.ref.markForCheck();
      });
    } else if (this.changedInputField == InputFields.ZAC) {
      this.changedInputField = InputFields.NONE;
      this.getBucketsfor(this.selectedZAC).then(function (mainResult: any) {
        //console.log("mainResult", mainResult);

        let buckets = mainResult;
        const enteredZAC = self.selectedZAC.toUpperCase();
        const cubeFace = enteredZAC.charAt(0);
        const zacLayer = enteredZAC.charAt(1);
        var layerFace = self.primaryColors.find((obj) => {
          return obj.sideInitials == cubeFace;
        });
        self.selectedCubeFace = layerFace;
        var item = self.layerColors.find((obj) => {
          return obj.label == zacLayer;
        });
        self.selectedLayer = cloneDeep(item);
        self.getLayerZCC(item.label).then(function (results) {
          let dataItem = results as Array<any>;
          let dstructuredArray = [];
          dataItem.forEach((item) => {
            const items = item.items;
            items.forEach((elem) => dstructuredArray.push(elem));
          });
          const swatchItem = dstructuredArray.find(
            (elem) => elem.zac == enteredZAC
          );
          self.activeColorsCount = item.count;
          self.activeColor = item.color;
          // self.setLayerFace(self.selectedCubeFace);
          // self.saturatedSelectedSwatch();
          self.selectedSwatch = swatchItem;
          let hexString = {
            hexString: self.selectedSwatch.hex.substring(1, 7),
          };
          Object.assign(self.selectedSwatch, hexString);
          self.removeAllPallets();
          for (var i = 0; i < buckets.length; i++) {
            let zcc = buckets[i].zcc;

            var swatch = self.gm.palettesModel.selectedPalette.swatches[i];
            swatch.empty = false;
            swatch.rgb = zcc.hex;
            swatch.rgbfull = zcc.rgb.r + "," + zcc.rgb.g + "," + zcc.rgb.b;
            swatch.zcc = zcc.zcc;
            swatch.hslFull =
              zcc.hue + "," + zcc.saturation + "," + zcc.lightness;
            swatch.hue = zcc.hue;
            swatch.saturation = zcc.saturation;
            swatch.lightness = zcc.lightness;
            swatch.selected = false;
            if (zcc.hex == swatchItem.hex) {
              self.gm.palettesModel.selectedSwatch = swatch;
              self.gm.palettesModel.selectedSwatch.selected = true;
              self.inputSwatch.hex = zcc.hex;
              self.inputSwatch.hexString = zcc.hex.substring(1, 7);
              self.inputSwatch.rgb = zcc.rgb;
              self.inputSwatch.cmyk = zcc.cmyk;
              self.inputSwatch.hue = zcc.hue;
              self.inputSwatch.zcc = zcc.zcc;
              self.inputSwatch.saturation = zcc.saturation;
              self.inputSwatch.lightness = zcc.lightness;
            }
          }
          self.gm.palettesModel.writeDefaultFile();
          self
            .getDeltaEBetweenHex(self.selectedSwatch.hex, self.inputSwatch.hex)
            .then(function (results) {
              self.zccdeltaE = parseFloat(results as string).toFixed(2);
              self.ref.markForCheck();
            });
        });
        self.ref.markForCheck();
      });
    } else if ((this.changedInputField = InputFields.HEX)) {
      this.changedInputField = InputFields.NONE;
      let hex = self.inputSwatch.hexString;
      if (this.isValidHex(hex) == false) {
        alert("Invalid digits in the input hex value!");
        return;
      }
      self.convertHexToZCC(hex).then(function (mainResult: any) {
        let zccItem = mainResult.zccItem;
        self.changedInputField = InputFields.ZCC;
        self.inputSwatch.zcc = zccItem.zcc;
        self.selectedHex = null;
        self.selectedZAC = null;
        self.selectedZCC = zccItem.zcc;
        self.getBucketsforzcc(false).then(function (mainResult: any) {
          //console.log("mainResult", mainResult);

          let { buckets, parent } = mainResult;
          self.inputSwatch.zac = parent.zac;
          var layerFace = self.primaryColors.find((obj) => {
            return obj.colorName == parent.cubeFace;
          });
          self.selectedCubeFace = layerFace;
          var item = self.layerColors.find((obj) => {
            return obj.label == parent.zacLayer;
          });
          self.selectedLayer = cloneDeep(item);
          self.getLayerZCC(item.label).then(function (results) {
            self.activeColorsCount = item.count;
            self.activeColor = item.color;
            // self.setLayerFace(self.selectedCubeFace);
            // self.saturatedSelectedSwatch();
            self.selectedSwatch = parent;

            for (var i = 0; i < buckets.length; i++) {
              let zcc = buckets[i].zcc;

              var swatch = self.gm.palettesModel.selectedPalette.swatches[i];
              swatch.empty = false;
              swatch.rgb = zcc.hex;
              swatch.rgbfull = zcc.rgb.r + "," + zcc.rgb.g + "," + zcc.rgb.b;
              swatch.zcc = zcc.zcc;
              swatch.hslFull =
                zcc.hue + "," + zcc.saturation + "," + zcc.lightness;
              swatch.hue = zcc.hue;
              swatch.saturation = zcc.saturation;
              swatch.lightness = zcc.lightness;
              swatch.selected = false;
              if (zcc.zcc == self.inputSwatch.zcc.toUpperCase()) {
                self.gm.palettesModel.selectedSwatch = swatch;
                self.gm.palettesModel.selectedSwatch.selected = true;
                self.inputSwatch.hex = "#" + hex;
                self.inputSwatch.hexString = hex;
                let rgb = self.hexTorgb(hex);
                self.inputSwatch.rgb = {
                  r: rgb.r.toString(),
                  g: rgb.g.toString(),
                  b: rgb.b.toString(),
                };
                self.inputSwatch.cmyk = zcc.cmyk;
                self.inputSwatch.hue = zcc.hue;
                self.inputSwatch.zcc = zcc.zcc;
                self.inputSwatch.saturation = zcc.saturation;
                self.inputSwatch.lightness = zcc.lightness;
              }
            }
            let hexString = {
              hexString: self.selectedSwatch.hex.substring(1, 7),
            };
            Object.assign(self.selectedSwatch, hexString);
            self.gm.palettesModel.writeDefaultFile();
            self
              .getDeltaEBetweenHex(
                self.selectedSwatch.hex,
                self.inputSwatch.hex
              )
              .then(function (results) {
                self.zccdeltaE = parseFloat(results as string).toFixed(2);
                self.ref.markForCheck();
              });
          });

          self.ref.markForCheck();
        });
      });
    }
  }

  startProcessToGetZCCStandardFromInput() {
    let self = this;
    let hex = self.inputSwatch.hexString;
    this.convertHexToZCC(hex).then(function (mainResult: any) {
      let zccItem = mainResult.zccItem;
      //self.zccdeltaE = mainResult.deltaE;
      let cubeFace = zccItem.cubeFace;
      let layer = zccItem.layer;
      var item = self.layerColors.find((obj) => {
        return obj.label == layer;
      });
      if (item != undefined) {
        self.selectedLayer = cloneDeep(item);
        self.getLayerZCC(layer).then(function (result) {
          self.activeColorsCount = item.count;
          self.activeColor = item.color;
          var layerFace = self.primaryColors.find((obj) => {
            return obj.colorName == cubeFace;
          });
          if (layerFace != undefined) {
            self.selectedCubeFace = cloneDeep(layerFace);
            self.setSelectedSwatch(zccItem);
            self.inputSwatch.zcc = zccItem.zcc;
          }
        });
      }
    });
    this.ref.markForCheck();
  }

  undoDelete() {
    var pallette = this.gm.palettesModel.selectedPalette;
    if (this.gm.deletedAllStack.length > 0) {
      //fill the stacks on clear all
      let selectedSwatchIndex = null;
      while (this.gm.deletedAllStack.length > 0) {
        let lastDeleted = this.gm.deletedAllStack.pop();
        this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] =
          lastDeleted.data;
        if (lastDeleted.data.selected) {
          selectedSwatchIndex = lastDeleted.index;
        }
        this.gm.palettesModel.updatePalette(
          this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index]
        );
        this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index];

        var o = new SwatchChangedEvent({
          oldSwatchId: this.gm.palettesModel.selectedSwatch.id,
          oldPaletteId: this.gm.palettesModel.selectedPalette.id,
          newSwatch: lastDeleted.data,
          newPalette: pallette,
        });
        this.gm.palettesModel.selectedPalette = pallette;
        this.gm.palettesModel.data.activePaletteId = pallette.id;
      }
      this.gm.palettesModel.writeDefaultFile();
      this.gm.palettesModel.selectedPalette.deselectAllSwatches();
      if (selectedSwatchIndex != null) {
        this.gm.palettesModel.selectedSwatch =
          this.gm.palettesModel.selectedPalette.swatches[selectedSwatchIndex];
        this.gm.palettesModel.selectedSwatch.selected = true;
      }

      this.gm.palettesModel.swatchChanged.emit(o);
      this.gm.deletedAllStack = [];
      return;
    }
    let lastDeleted = this.gm.deletedStack.pop();
    if (lastDeleted) {
      this.gm.palettesModel.selectedPalette.swatches[lastDeleted.index] =
        lastDeleted.data;
      this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedSwatch);
      this.gm.palettesModel.writeDefaultFile();
      this.gm.palettesModel.selectedPalette.deselectAllSwatches();
      this.gm.palettesModel.selectedPalette.swatches[
        lastDeleted.index
      ].selected = true;
      var o = new SwatchChangedEvent({
        oldSwatchId: this.gm.palettesModel.selectedSwatch.id,
        oldPaletteId: this.gm.palettesModel.selectedPalette.id,
        newSwatch: lastDeleted.data,
        newPalette: pallette,
      });
      this.gm.palettesModel.selectedSwatch = lastDeleted.data;
      this.gm.palettesModel.selectedPalette = pallette;
      this.gm.palettesModel.data.activePaletteId = pallette.id;
      this.gm.palettesModel.swatchChanged.emit(o);
    }
  }

  removeAllPallets() {
    this.gm.deletedAllStack = [];
    for (
      let i = 0;
      i < this.gm.palettesModel.selectedPalette.swatches.length;
      i++
    ) {
      let swatch = this.gm.palettesModel.selectedPalette.swatches[i];
      if (swatch.empty == false) {
        let deletedSwatch = Object.assign({}, swatch);
        let deletedArr = { index: i, data: deletedSwatch };
        this.gm.deletedAllStack.push(deletedArr);
      }
    }
    let palette = this.gm.palettesModel.selectedPalette;
    this.gm.palettesModel.removePalette(palette);
    this.gm.palettesModel.writeDefaultFile();
    this.gm.deletedStack = [];
  }

  deleteSelectedSwatch() {
    if (this.gm.palettesModel.selectedSwatch.selected) {
      let swatch = this.gm.palettesModel.selectedSwatch;
      let palette = this.gm.palettesModel.selectedPalette;
      if (swatch.empty) {
        alert("The swatch is already empty.");
        return;
      } else {
        for (var i = 0; i < palette.swatches.length; i++) {
          if (palette.swatches[i].id === swatch.id) {
            let deletedSwatch = Object.assign({}, palette.swatches[i]);
            let index =
              this.gm.palettesModel.selectedPalette.swatches.findIndex(
                (x) => x.id == palette.swatches[i].id
              );
            let deletedArr = { index: index, data: deletedSwatch };
            this.gm.deletedStack.push(deletedArr);
            palette.swatches[i].empty = true;
            palette.swatches[i].zcc = "";
            palette.swatches[i].contrastingFontColor = "white";
            // this.gm.palettesModel.selectedSwatch = new SwatchModel({});
            this.gm.palettesModel.updatePaletteWithoutSelectingSwatch(palette);
            this.gm.palettesModel.selectedSwatch.selected = false;
            this.gm.palettesModel.writeDefaultFile();
          }
        }
      }
    }
  }

  //api interaction methods
  convertHexToZCC(hex) {
    if (this.isValidHex(hex) == false) {
      return;
    }
    let self = this;
    self.spinner.show();
    return new Promise(function (resolve, reject) {
      self.apiService.hexToZccWithCF(hex, 1, 1, 1).subscribe(
        (data: any) => {
          self.spinner.hide();
          let zccItem = {
            zcc: "",
            hex: "",
            cmyk: { c: "", m: "", y: "", k: "" },
            rgb: { r: "", g: "", b: "" },
            hue: "",
            saturation: "",
            lightness: "",
            cubeFace: "",
            layer: "",
          };
          zccItem.zcc = data.zcc;
          zccItem.hex = "#" + data.hex;
          zccItem.rgb = data.rgb;
          zccItem.cmyk = data.cmyk;
          zccItem.hue = Math.round(data.hsl.h).toString();
          zccItem.saturation = Math.round(data.hsl.s * 100).toString();
          zccItem.lightness = Math.round(data.hsl.l * 100).toString();
          zccItem.cubeFace = data.cubeFace;
          zccItem.layer = data.layer;
          let result = { zccItem: zccItem, deltaE: data.deltaE.toFixed(1) };
          resolve(result);
          self.spinner.hide();
        },
        (error) => {
          self.spinner.hide();
          alert(error.error.message);
        }
      );
    });
  }

  indexOfSelectedSwatchAndCurrentLayer() {
    if (this.selectedCubeFace) {
      let index = this.selectedCubeFace.swatchesColor.findIndex(
        (item) => item.zcc == this.selectedSwatch.zcc
      );
      //console.log("index of selectedswatch is " + index);
      let selectedSwatchInfo = {
        selectedSwatchIndex: index,
        cubeFace: cloneDeep(this.selectedCubeFace),
      };
      return selectedSwatchInfo;
    }
    return null;
  }

  ////please please please refine this method by converting the prevSelectedSwatchIndex to the index of the selected layer
  saturatedSelectedSwatch() {
    //suppose we got the next layer data here
    if (this.selectedSwatchInfo) {
      let prevSelectedSwatchIndex = this.selectedSwatchInfo.selectedSwatchIndex;
      let prevLayerCount = Math.sqrt(
        this.selectedSwatchInfo.cubeFace.swatchesColor.length
      );

      let currentLayerCount = this.selectedLayer.count;
      let diff = (prevLayerCount - currentLayerCount) / 2;
      //case when moving from high density layer to low density layer
      // if (diff > 0)
      {
        //insert sudo items
        let row = prevLayerCount;
        let col = prevLayerCount;
        let swatchArray = [];
        for (let i = 0; i < col; i++) {
          for (let j = 0; j < row; j++) {
            //any index in 1 d is = (row*i)+j
            let sudoIndex = row * i + j;
            if (j >= diff && j < row - diff && i >= diff && i < col - diff) {
              let innerIndex = currentLayerCount * (i - diff) + (j - diff);
              let swatch = this.selectedCubeFace.swatchesColor[innerIndex];
              console.log(
                "sudo index " + sudoIndex + " and mainIndex " + innerIndex
              );
              let item = { swatch: swatch, sudoIndex: sudoIndex };
              swatchArray.push(item);
            }
          }
        }
        let doesIndexFound = false;
        let prevSelectedSwatchRow = prevSelectedSwatchIndex % prevLayerCount;
        let prevSelectedSwatchCol = Math.floor(
          prevSelectedSwatchIndex / prevLayerCount
        );
        if (prevSelectedSwatchCol < diff) {
          prevSelectedSwatchCol = diff;
        } else if (prevSelectedSwatchCol >= prevLayerCount - diff) {
          prevSelectedSwatchCol = prevLayerCount - 1 - diff;
        }
        if (prevSelectedSwatchRow < diff) {
          prevSelectedSwatchRow = diff;
        } else if (prevSelectedSwatchRow >= prevLayerCount - diff) {
          prevSelectedSwatchRow = prevLayerCount - 1 - diff;
        }
        let sudoPrevSelectedSwatchIndex =
          prevLayerCount * prevSelectedSwatchCol + prevSelectedSwatchRow;
        for (let i = 0; i < col; i++) {
          for (let j = 0; j < row; j++) {
            let item = swatchArray.find(
              (item) => item.sudoIndex == sudoPrevSelectedSwatchIndex
            );
            if (item) {
              let swatch = item.swatch;
              this.setSelectedSwatch(swatch);
              doesIndexFound = true;
              break;
            }
          }
          if (doesIndexFound) {
            break;
          }
        }
      }
    }
  }

  // saturatedSelectedSwatch(){
  // 	//suppose we got the next layer data here
  // 	if (this.selectedSwatchInfo) {
  // 		let prevSelectedSwatchIndex = this.selectedSwatchInfo.selectedSwatchIndex
  // 	let prevLayerCount = Math.sqrt(this.selectedSwatchInfo.cubeFace.swatchesColor.length)

  // 	let currentLayerCount = this.selectedLayer.count
  // 	let diff = (prevLayerCount - currentLayerCount)/2
  // 	if (diff > 0) {
  // 		//insert sudo items
  // 		let row = prevLayerCount
  // 		let col = prevLayerCount
  // 		let swatchArray = []
  // 		let doesIndexFound = false
  // 		for (let i = 0; i < col; i++) {
  // 			for (let j = 0; j < row; j++) {
  // 				//any index in 1 d is = (row*i)+j
  // 				let sudoIndex = (row*i)+j
  // 				if (j >= diff && j < row - diff && i >=diff && i < col - diff) {
  // 					if (sudoIndex == prevSelectedSwatchIndex) {
  // 						let innerIndex = currentLayerCount*(i-diff) + (j-diff)
  // 						let swatch = this.selectedCubeFace.swatchesColor[innerIndex]
  // 						this.setSelectedSwatch(swatch)
  // 						//console.log('sudo index ' + sudoIndex + ' and mainIndex ' + innerIndex)
  // 						doesIndexFound = true
  // 						break
  // 					}
  // 					// let item = {swatch: swatch, sudoIndex: sudoIndex}
  // 					// swatchArray.push(item)
  // 				}
  // 				else{
  // 					//case when the selected swatch index is outside of the new layer index
  // 					let adjustCol = i
  // 					let adjustRow = j
  // 					if (adjustCol < diff) {
  // 						adjustCol += diff
  // 					}
  // 					else if(adjustCol >= col - diff){
  // 						adjustCol -= diff
  // 					}
  // 					if (adjustRow < diff) {
  // 						adjustRow += diff
  // 					}
  // 					else if(adjustRow >= row - diff){
  // 						adjustRow -= diff
  // 					}
  // 					sudoIndex = (currentLayerCount*adjustCol) + adjustRow
  // 					if (sudoIndex == prevSelectedSwatchIndex) {
  // 						let innerIndex = currentLayerCount*(adjustCol-diff) + (adjustRow-diff)
  // 						let swatch = this.selectedCubeFace.swatchesColor[innerIndex]
  // 						this.setSelectedSwatch(swatch)
  // 						//console.log('sudo index ' + sudoIndex + ' and mainIndex ' + innerIndex)
  // 						doesIndexFound = true
  // 						break
  // 					}

  // 				}

  // 			}
  // 			if (doesIndexFound == true) {
  // 				break
  // 			}
  // 		}

  // 	}
  // 	}

  // }

  public selectedSwatchInfo;
  getLayerZCC(layer: String) {
    let self = this;
    this.selectedSwatchInfo = this.indexOfSelectedSwatchAndCurrentLayer();
    self.spinner.show();
    return new Promise(function (resolve, reject) {
      self.apiService.zacSwatchesForLayer(layer, self.zacType).subscribe(
        (data: any) => {
          self.setZoomLevel(ZoomLevel.default);
          let dataObj = data["data"];
          let dataItem = dataObj.items;
          //mapped response into primary colors
          self.primaryColors.map((obj) => {
            dataItem.map((item) => {
              if (obj.colorName == item.cubeFace) {
                obj.swatchesColor = item.items;
              }
            });
          });
          let result = dataItem;
          resolve(result);
          self.spinner.hide();
        },
        (error) => {
          self.spinner.hide();
          alert(error.error.message);
        }
      );
    });
  }
  setSelectedEven(event: string) {
    this.selectedEvent = event;
  }

  getBucketsforzcc(zac) {
    let self = this;
    let zcc = this.selectedZCC || false;
    let hex = this.selectedHex || false;
    let rgb = this.inputSwatch.rgb;

    // if (!zac) zac = this.selectedZAC;
    self.spinner.show();
    return new Promise(function (resolve, reject) {
      let isDeltaE = self.idDeltaEFilter;
      let zacType = self.zacType;
      self.apiService
        .bucketsForZAC(zac, isDeltaE, zacType, { zcc, hex, rgb })
        .subscribe(
          (data: any) => {
            self.spinner.hide();
            let { parent } = data;
            if (parent) {
              self.inputSwatch.zac = parent.zac;
              if (zac) {
                self.inputSwatch.zcc = parent.zcc;
                self.inputSwatch.hex = parent.hex;
              }

              // self.selectedLayer = cloneDeep(parent);
              // self.setSelectedSwatch(parent) //= parent;
            }
            let { buckets } = data;
            let result = buckets;
            resolve(data);
          },
          (error) => {
            self.spinner.hide();
            alert(error.error.message);
          }
        );
    });
  }

  getBucketsfor(zac) {
    let self = this;
    let zcc = this.selectedZCC || false;
    let hex = this.selectedHex || false;
    let rgb = this.inputSwatch.rgb;

    if (!zac) zac = this.selectedZAC;
    self.spinner.show();
    return new Promise(function (resolve, reject) {
      let isDeltaE = self.idDeltaEFilter;
      let zacType = self.zacType;
      self.apiService
        .bucketsForZAC(zac, isDeltaE, zacType, { zcc, hex, rgb })
        .subscribe(
          (data: any) => {
            self.spinner.hide();
            let { parent } = data;
            if (parent) {
              self.inputSwatch.zac = parent.zac;
              self.inputSwatch.zcc = parent.zcc;
              // self.selectedLayer = cloneDeep(parent);
              // self.setSelectedSwatch(parent) //= parent;
            }
            let { buckets } = data;
            let result = buckets;
            resolve(result);
          },
          (error) => {
            self.spinner.hide();
            alert(error.error.message);
          }
        );
    });
  }

  getZCCHEX(zcc) {
    let self = this;
    self.spinner.show();
    return new Promise(function (resolve, reject) {
      self.apiService.zccToHex(zcc).subscribe(
        (data: any) => {
          self.spinner.hide();
          let zccItem = {
            zcc: "",
            hex: "",
            cmyk: { c: "", m: "", y: "", k: "" },
            rgb: { r: "", g: "", b: "" },
            hue: "",
            saturation: "",
            lightness: "",
            cubeFace: "",
            layer: "",
          };
          zccItem.zcc = data.zcc;
          zccItem.hex = data.hex;
          zccItem.rgb = data.rgb;
          zccItem.cmyk = data.cmyk;
          zccItem.hue = Math.round(data.hsl.h).toString();
          zccItem.saturation = Math.round(data.hsl.s * 100).toString();
          zccItem.lightness = Math.round(data.hsl.l * 100).toString();
          zccItem.cubeFace = data.cubeFace;
          zccItem.layer = data.layer;
          let result = { item: zccItem };
          resolve(result);
        },
        (error) => {
          self.spinner.hide();

          if (error.status == 404) {
            alert("You have entered an invalid ZCC#.");
          } else {
            alert(error.error.message);
          }
        }
      );
    });
  }
  createScrollScript() {
    var s = document.createElement("script");
    s.setAttribute("src", "../../../assets/scrollbar.js");
    s.onload = () => {
      jQuery("body").find("#zcc-color-examples").customScrollbar();
    };
    document.body.appendChild(s);
  }

  layerBackgroundColor() {
    let index = 0;
    if (this.selectedLayer) {
      index = this.layerColors.findIndex(
        (item) => item.label == this.selectedLayer.label
      );
    }
    let backgroundColor = this.selectedCubeFace.layerColors[index];
    return backgroundColor;
  }

  public filesToUpload;
  public uploadformdata;
  public importFileName = "";
  public addedFileName = "";
  fileChangeEvent(fileInput) {
    if (fileInput.target.files.length == 0) {
      return;
    }
    var file = fileInput.target.files[0];
    var str = file.name;
    this.addedFileName = str;
    var ase = str.indexOf(".ase");
    var aco = str.indexOf(".aco");
    if (ase > -1 || aco > -1) {
      this.filesToUpload = <Array<File>>fileInput.target.files;
      const formData: any = new FormData();
      const files: Array<File> = this.filesToUpload;

      for (let i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i]["name"]);
      }
      this.uploadformdata = formData;
    } else {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.addedFileName = "";
        alert("Please select PNG or JPG file");
        return;
      }
      this.filesToUpload = <Array<File>>fileInput.target.files;
      const formData: any = new FormData();
      const files: Array<File> = this.filesToUpload;

      for (let i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i]["name"]);
      }
      this.uploadformdata = formData;
    }
    this.importFileData();
  }
  importFileData() {
    if (!this.uploadformdata) {
      this.addedFileName = "";
      alert("Select a PNG or JPEG file.");
      return;
    }
    var emptycolorbox = true;
    for (
      var sw = 0;
      sw < this.gm.palettesModel.selectedPalette.swatches.length;
      sw++
    ) {
      var color = this.gm.palettesModel.selectedPalette.swatches[sw];
      if (color.empty == false) {
        emptycolorbox = false;
      }
    }
    this.spinner.show();
    this.apiService.uploadFile(this.uploadformdata).subscribe(
      (data: any) => {
        this.spinner.hide();
        data = JSON.parse(data);
        if (!data) {
          this.addedFileName = "";
          alert("No data found");
          return;
        }
        if (emptycolorbox) {
          this.replaceSwatches(data);
        } else {
          let self = this;
          self.confirmationDialogService
            .fileConfirm(
              "SWATCH FILE LOAD OPTIONS",
              "We've noted a swatch file currently in use. Do you wish to CANCEL this process, MERGE the new file with the existing swatch file, or REPLACE the existing swatch file with the file being loaded?"
            )
            .then((confirmed) => {
              if (confirmed == 1) {
                data.swatches = data.swatches.reverse();
                for (
                  var j = 0;
                  j < self.gm.palettesModel.selectedPalette.swatches.length;
                  j++
                ) {
                  // var swatch = this.gm.palettesModel.selectedPalette.swatches[j];
                  if (
                    self.gm.palettesModel.selectedPalette.swatches[j].empty ==
                      true &&
                    data.swatches.length > 0
                  ) {
                    var respdata = data.swatches.pop();
                    self.gm.palettesModel.selectedPalette.swatches[j].empty =
                      false;
                    self.gm.palettesModel.selectedPalette.swatches[j].rgb =
                      respdata.hex;
                    self.gm.palettesModel.selectedPalette.swatches[j].rgbfull =
                      respdata.red + "," + respdata.green + "," + respdata.blue;
                    self.gm.palettesModel.selectedPalette.swatches[j].zcc =
                      respdata.zcc;
                    self.gm.palettesModel.selectedPalette.swatches[j].refname =
                      respdata.referenceName;
                    self.gm.palettesModel.selectedPalette.swatches[
                      j
                    ].refnumber = respdata.referenceNumber;
                  }
                }
                self.gm.palettesModel.writeDefaultFile();
                self.ref.markForCheck();
                //swatchGrid
              } else if (confirmed == 2) {
                this.replaceSwatches(data);
                self.ref.markForCheck();
              }
            })
            .catch(() => jQuery("#removeDuplicates").prop("checked", false));
        }
      },
      (error) => {
        this.spinner.hide();
        // this.swatchFIleTag.nativeElement.value = "";
        //alert(error.error.message);
        alert(error.error.message);
      }
    );
  }

  replaceSwatches(data) {
    this.importFileName = this.addedFileName;
    for (var i = 0; i < 64; i++) {
      this.gm.palettesModel.selectedPalette.swatches[i].empty = true;
    }
    this.gm.palettesModel.writeDefaultFile();
    for (var i = 0; i < data.swatches.length; i++) {
      var color = data.swatches[i];
      this.gm.palettesModel.selectedPalette.swatches[i].empty = false;
      this.gm.palettesModel.selectedPalette.swatches[i].rgb = color.hex;
      this.gm.palettesModel.selectedPalette.swatches[i].rgbfull =
        color.red + "," + color.green + "," + color.blue;
      this.gm.palettesModel.selectedPalette.swatches[i].zcc = color.zcc;
      this.gm.palettesModel.selectedPalette.swatches[i].refname =
        color.referenceName;
      this.gm.palettesModel.selectedPalette.swatches[i].refnumber =
        color.referenceNumber;
      if (this.gm.palettesModel.selectedPalette.shouldShowMissingTags == true) {
        if (
          this.gm.palettesModel.selectedPalette.swatches[i].refnumber == "" ||
          this.gm.palettesModel.selectedPalette.swatches[i].refname == ""
        ) {
          this.gm.palettesModel.selectedPalette.swatches[i].missingRefTag =
            true;
        } else {
          this.gm.palettesModel.selectedPalette.swatches[i].missingRefTag =
            false;
        }
      } else {
        this.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = false;
      }
      // this.gm.palettesModel.selectedPalette.swatches[i].hslFull = this.zccHsl;
      // this.gm.palettesModel.selectedPalette.swatches[i].hue = this.zccHsl.h;
      // this.gm.palettesModel.selectedPalette.swatches[i].saturation = this.zccHsl.s;
      // this.gm.palettesModel.selectedPalette.swatches[i].lightness = this.zccHsl.l;
    }
    this.gm.palettesModel.writeDefaultFile();
  }

  fudgeFactor(hex) {
    let textFill = "#ffffff";
    if (hex == undefined || hex.length == 0) {
      return textFill;
    }
    let rgb = this.hexTorgb(hex);
    var fudgeFactor = Math.round(
      (Math.round(rgb.r) * 299 +
        Math.round(rgb.g) * 587 +
        Math.round(rgb.b) * 114) /
        1000
    );

    if (fudgeFactor > 125) {
      textFill = "#333333";
    }
    return textFill;
  }
}
