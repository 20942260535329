/**
 * Model Class to initialise
 * swatch model with predefined values
 */
// declare var _: any;
import * as _ from 'underscore';

import { UUID } from 'angular2-uuid';

/**
 * NOTE: we are using _.deepExtend, so if we add new properties to our models, for later versions, when the older version file loads,
 * properties will be merged with the new ones, and it should make backward compatibility a non issue. Just add new properties to the models
 */
export class SwatchModel {

    public id: string = "";
    public refname: any = "";
    public defaultZcc: boolean;
    public refnumber: any = "";
    public hue: string = "";
    public saturation: string = "";
    public lightness: string = "";
    public missingRefTag: boolean = false;
    public empty: boolean = true;
    public zcc: string = "";
    public rgb: string = "";
    public rgbfull: string = "136, 68, 68";
    public hslfull: string = "0, 33, 40";
    public labfull: string = "37,29,13";
    public cmykfull: string = "0,50,50,47";
    public red1: string = "rgb(146,68,68)";
    public red2: string = "rgb(166,68,68)";
    public red3: string = "rgb(186,68,68)";
    public green1: string = "rgb(136,78,68)";
    public green2: string = "rgb(136,98,68)";
    public green3: string = "rgb(136,118,68)";
    public hue1: string = "#88446C";
    public hue2: string = "#884458";
    public hue3: string = "#884448";
    public hue4: string = "#884844";
    public hue5: string = "#885844";
    public hue6: string = "#886C44";
    public brightness1: string = "rgb(102,51,51)"
    public brightness2: string = "rgb(119,59,59)"
    public brightness3: string = "rgb(132,66,66)"
    public brightness4: string = "rgb(139,69,69)"
    public brightness5: string = "rgb(153,76,76)"
    public brightness6: string = "rgb(170,85,85)"
    public saturation1: string = "rgb(125,78,78)";
    public saturation2: string = "rgb(130,73,73)";
    public saturation3: string = "rgb(134,69,69)";
    public saturation4: string = "rgb(137,66,66)";
    public saturation5: string = "rgb(141,62,62)";
    public saturation6: string = "rgb(146,57,57)";
    public contrastingFontColor: string = "white";
    public contrastingShadowColor: string = "rgba(255,255,255,0.5)";

    public customFieldValue1: string = "";
    public customFieldValue2: string = "";
    public customFieldValue3: string = "";
    public customFieldValue4: string = "";
    public customFieldValue5: string = "";

    public swatchCoupleZcc: string = "";
    public swatchCouplergb: string = "#525253";
    public swatchCouplergbfull: string = "0, 0, 0";
    public deltaE : number = null

    public comparisonResult: string = 'none'
    public lock: boolean = false

    public oldHex: string = "";

    // public lumaColor: string = "#ffffff";

    get lumaColor():any{
      var lumaColor;
    var c = this.rgb.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 100) {
        lumaColor = '#ffffff';
    } else {
        lumaColor = '#333333';
    }
      return lumaColor;
    }

    get r():any{
      return this.rgbfull.split(',')[0];
    }
    
    get g():any{
      return this.rgbfull.split(',')[1];
    }
    get b():any{
      return this.rgbfull.split(',')[2];
    }

    get swatchCouplehexNoHash():any{
      return this.swatchCouplergb.split('#').join('').toUpperCase();
    }

    get swatchCoupler():any{
      return this.swatchCouplergbfull.split(',')[0];
    }
    
    get swatchCoupleg():any{
      return this.swatchCouplergbfull.split(',')[1];
    }
    get swatchCoupleb():any{
      return this.swatchCouplergbfull.split(',')[2];
    }

    get rgbNoHash(): any {
        return this.rgb.split('#').join('');
    }

    get rgbFull(): any {
        return this.rgbfull;
    }

    get cmykFull(): any {
      return this.cmykfull;
    }

    get hslFull(): any {
      return this.hslfull;
    }

    set hslFull(val){
      this.hslfull = val;
    }

    get labFull(): any {
      return this.labfull;
    }

    public selected: boolean = false;

    constructor(o: any = null) {
      this.empty = true;
        if (o) {
            _.extend(this, o);
            if (this.id === '' ) {
                this.id = UUID.UUID();
            }
            if (this.zcc === '') {
              this.zcc = 'ZCC-0001';
              this.swatchCoupleZcc = 'ZCC-0001';
            }
        }
          if(this.empty){
            this.zcc = '';
            this.swatchCoupleZcc = '';
          }
    }

    copy(o: any) : void {
      _.extend(this, o);
      if (this.id === '' ) {
          this.id = UUID.UUID();
      }
      if (this.zcc === '') {
        this.zcc = 'ZCC-0001';
        this.swatchCoupleZcc = 'ZCC-0001';
      }
    }

    // Returns the red component of this swatch
    // between 0 and range, inclusive
    redChannel(theRange: number): number {
      return this.extractComponent(0, theRange);
    }

    // Returns the green component of this swatch
    // between 0 and range, inclusive
    greenChannel(theRange: number): number {
      return this.extractComponent(1, theRange);
    }

    // Returns the blue component of this swatch
    // between 0 and range, inclusive
    blueChannel(theRange: number): number {
      return this.extractComponent(2, theRange);
    }

    private extractComponent(index: number, theRange: number): number {
      if (index >= 0 && index <= 2 && index === Math.floor(index)) {
        let val255 = parseInt(this.rgbNoHash.substr(index * 2, 2), 16);
        if (theRange === 255) {
          return val255;
        }
        return val255 * theRange / 255;
      }
      return 0;
    }
}
